<template>
  <el-row type="flex" justify="center" align="middle">
    <el-col :offset="8">
      <el-form :model="formData"  class="formStyle">
<!--        <el-form-item label="重量预警阈值" label-width="100px">
          <el-input v-model="formData.weightWarning" ></el-input>
        </el-form-item>-->
        <el-form-item label="重量报警阈值" label-width="100px">
          <el-input v-model="formData.weightAlarm" ></el-input>
        </el-form-item>
        <el-button type="primary" @click="submit" :loading="loading">发送</el-button>
      </el-form>
    </el-col>
  </el-row>

</template>

<script>
export default {
  name: "send",
  data(){
    return{
      loading:false,
      formData:{
        /*weightWarning:'',*/
        weightAlarm:''/*,
        heightWarning:'',
        heightAlarm:'',
        speedWarning:'',
        speedAlarm:'',
        inclinationXWarning:'',
        inclinationXAlarm:'',
        inclinationYWarning:'',
        InclinationYAlarm:'',
        deviceCode:'',
        sendFrequency:'',
        numberOfPeopleAlarm:'',
        hardwareVersion:'',
        softwareVersion:''*/
      }
    }
  },
  methods:{
    submit(){
      this.$axios.post("/test/send",this.formData).then((response)=>{
        console.log(response.data);
      })
    }
  }
}
</script>

<style scoped>
  .formStyle{
    width: 500px;
  }
</style>
