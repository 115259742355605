<template>
  <div>
    <div class="card-flex">
      <el-card class="layui-elevator">
        <div>
          <span>设备数量：</span>
          <span>{{deviceStatics.total}}台</span>
        </div>
      </el-card>
      <el-card class="layui-elevator">
        <div>
          <span>当前在线： </span>
          <span>{{deviceStatics.online}}台</span>
        </div>
      </el-card>
      <el-card class="layui-elevator">
        <div>
          <span>当前离线：</span>
          <span>{{deviceStatics.offline}}台</span>
        </div>
      </el-card>
    </div>

    <div>
      <lifting-hook-device
        v-for="(item,index) in deviceList"
        :device-id="item.code"
        :device-name="item.name"
        :is-remote="item.isRemote"
        :key="index"
        :device-type="item.type"
        :online-state="item.status" >
      </lifting-hook-device>
    </div>
  </div>
</template>

<script>
import liftingHookDevice from "@/views/projectDevice/liftingHook/liftingHookDevice";
export default {
  name: "liftingHookCrane",
  components: {liftingHookDevice},
  props:{
    projectId:String
  },
  data(){
    return{
      activeName:'1',
      deviceList:[],
      deviceStatics:{
        total:'--',
        online:'--',
        offline:'--',
      },
      cumulative:{
        cumulativeCount:'--',
        violateCount:'--'
      }
    }
  },
  mounted() {
    this.loadCumulative()
    this.loadDeviceStatics()
    this.loadDeviceList()
  },
  methods:{
    loadDeviceList(){
      this.$axios.post(`/sys/device/104/listAll/${this.projectId}`).then((response)=>{
        this.deviceList=response.data
      })
    },
    loadCumulative(){
      this.$axios.post(`/sys/project/ext/statics/${this.projectId}/cumulative/104`).then(response=> {
        this.cumulative=response.data;
      }).catch(function (err){
        console.log(err);
      })
    },
  loadDeviceStatics(){
    this.$axios.post(`/sys/project/ext/statics/${this.projectId}/device/104`).then(response=>{
      this.deviceStatics=response.data
    })
  }
  }
}
</script>
<style scoped>
  .card-flex{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 10px 0;
  }
  .card-flex >div{
    flex: 1;
    width: 200px;
    text-align: center;
    color: #008CFF;
  }

  .layui-elevator{
    height: 100%; background: rgba(0, 124, 249, 0.1);
    border: 1px solid #0056A1; border-radius: 4px;
    margin: 0px 5px 0px 5px;
  }
</style>
