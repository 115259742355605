<template>
<div>
  <el-row style="margin: 0 5px 0 5px;">
    <el-col>
      <el-form :inline="true" :model="pageQuery" ref="pageQuery" style="margin-top: 10px;">
        <el-form-item style="margin-left: 5px;" label="设备编号">
          <el-input v-model="pageQuery.devCode" placeholder="请输入设备编号"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 5px;" label="设备名称">
          <el-input v-model="pageQuery.devName" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  <el-row style="margin: 0 5px 0 5px;">
    <el-col>
      <el-table :data="page.records">
        <el-table-column type="index" width="80px" label="序号" fixed="left"></el-table-column>
        <el-table-column prop="code" label="设备编号" width="150px" fixed="left"></el-table-column>
        <el-table-column prop="name" label="设备名" width="200px" fixed="left"></el-table-column>
        <el-table-column prop="type" label="设备类型" width="120px">
          <template slot-scope="scope">
            {{macType(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column label="设备状态" width="120px">
          <template slot-scope="scope">
            <div v-if="scope.row.type===100" style="color:#409eff">
                {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===101" style="color:#409eff">
                {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===102" style="color:#409eff">
              {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===103" style="color:#409eff">
              {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===104" style="color:#409eff">
              {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===105" style="color:#409eff">
              {{macStatus(scope.row.status)}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="binderCard" label="通讯关联" width="200px"></el-table-column>
        <el-table-column prop="comName" label="公司名称" width="210px"></el-table-column>
        <el-table-column prop="proName" label="项目名称" width="250px"></el-table-column>
        <el-table-column label="操作" width="120px" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="sendPage(scope.row.code,scope.row.type)" v-if="scope.row.isMemory==1&&hasPerm('attribute:set')">属性设置</el-button>
            <el-button size="mini" type="primary" @click="sendPage(scope.row.code,scope.row.type)" v-else-if="scope.row.isMemory==0&&hasPerm('attribute:set')" disabled>属性设置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
  <el-pagination
      :current-page="this.page.current"
      :page-sizes="this.$pageSizes"
      :page-size="this.page.size"
      layout="total,sizes,prev,pager,next,jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      style="text-align: left;margin-top: 10px">
  </el-pagination>
</div>
</template>

<script>
import {EventBus} from "@/views/consoleDevice/event-bus";
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
export default {
  name: "msgTable",
  data(){
    return{
      consoleDeviceStore,
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        orders: [],
        hitCount:false,
        searchCount: true,
        pages:0
      },
      pageQuery:{
        devCode:'',
        devName:'',
        size:this.$pageSize,
        current: 1,
      }
    }
  },
  created() {
    EventBus.$on('loadTable',()=>{
        this.loadTable();
    });
    EventBus.$on('refresh',(msgStr)=>{
      console.log(msgStr);
      this.loadTable();
    });
    //this.loadTable();
  },
  computed:{
    macStatus(){
      return function (status) {
        switch (status) {
          case 0:
            return "停止"
          case 1:
            return "运行"
        }
      }
    },
    macType(){
      return function (type) {
        switch (type) {
          case 100:
            return "塔机"
          case 101:
            return "升降机"
          case 102:
            return "卸料平台"
          case 103:
            return "一体化"
          case 104:
            return "吊钩可视化"
          case 105:
            return "人脸识别"
        }
      }
    }
  },
  methods:{
    handleCurrentChange(value){
      //console.log("当前页："+value)
      this.pageQuery.current=value;
      this.loadTable();
    },
    handleSizeChange(value){
      //console.log("每页大小变更："+value)
      this.pageQuery.size=value;
      this.pageQuery.current=1
      this.loadTable()
    },
    loadTable(){
      this.pageQuery.areaId = consoleDeviceStore.state.org.areaId;
      this.$axios.post(`/sys/device/list/area`,this.pageQuery).then((response)=>{
        this.page=response.data
      });
    },
    sendPage(code,type){
      EventBus.$emit('msgDialog',code,type);
    },
    /**导出电工列表excel */
    exportFile() {
      let href = "";
      window.location.href=href;
    },
    /**刷新表格数据 */
    handleRefresh(){
      this.currPage = 1
      this.loadTable();
    },
    queryClick(){
      this.loadTable();
    }
  }
}
</script>

<style scoped>

</style>
