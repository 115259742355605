<template>
<div>
  <el-row style="margin: 0 5px 0 5px;">
    <el-col>
      <el-form :inline="true" :model="pageQuery" ref="pageQuery" style="margin-top: 10px;">
        <el-form-item style="margin-left: 5px;" label="设备编号">
          <el-input v-model="pageQuery.devCode" placeholder="请输入设备编号"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 5px;" label="设备名称">
          <el-input v-model="pageQuery.devName" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  <el-row style="margin: 0 5px 0 5px;">
    <el-col>
      <el-table :data="page.records" border>
        <el-table-column type="index" width="60px" label="序号"></el-table-column>
        <el-table-column prop="code" label="设备编号" width="120px"></el-table-column>
        <el-table-column prop="name" label="设备名"></el-table-column>
        <el-table-column prop="serialNumber" label="设备SN" width="150px">
        </el-table-column>
        <el-table-column prop="type" label="设备类型" width="100px">
          <template slot-scope="scope">
            {{macType(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column label="设备状态" width="100px">
          <template slot-scope="scope">
            <div v-if="scope.row.type===100">
              <router-link  :to="{path:'/towerState',query:{deviceId:scope.row.code}}" target="_blank" style="color:#409eff">
                {{macStatus(scope.row.status)}}
              </router-link>
            </div>
            <div v-if="scope.row.type===101">
              <router-link  :to="{path:'/elevatorState',query:{deviceId:scope.row.code}}" target="_blank" style="color:#409eff">
                {{macStatus(scope.row.status)}}
              </router-link>
            </div>
            <div v-if="scope.row.type===102" style="color:#409eff">
                {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===103" style="color:#409eff">
              {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===104" style="color:#409eff">
              {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===105" style="color:#409eff">
              {{macStatus(scope.row.status1)}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="在线时间" width="170px">
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button type="primary" @click="edit(scope.row)" icon="el-icon-user" v-if="hasPerm('face:list')" circle></el-button>
            <el-button type="success" @click.stop="faceRecordDialogVisible=true,deviceSn=scope.row.serialNumber,deviceId=scope.row.code"
                       v-if="hasPerm('face:record')" icon="el-icon-s-cooperation" circle></el-button>
            <el-button type="primary" @click.stop="faceConfigDialogVisible=true,deviceSn=scope.row.serialNumber,deviceId=scope.row.code"
                       v-if="hasPerm('face:config')" icon="el-icon-setting" circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
  <el-pagination
      :current-page="this.page.current"
      :page-sizes="this.$pageSizes"
      :page-size="this.page.size"
      layout="total,sizes,prev,pager,next,jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      style="text-align: left;margin-top: 10px">
  </el-pagination>
  <fy-face-record :dialog-visible.sync="faceRecordDialogVisible" :device-sn="deviceSn"></fy-face-record>
  <fy-face-config :dialog-visible.sync="faceConfigDialogVisible" :device-sn="deviceSn" :device-id="deviceId"></fy-face-config>
</div>
</template>

<script>
import {EventBus} from "@/views/consoleDevice/event-bus";
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
import FyFaceRecord from "./fyFaceRecord";
import FyFaceConfig from "./fyFaceConfig";

export default {
  name: "fyFaceTable",
  components: {FyFaceConfig, FyFaceRecord},
  data(){
    return{
      consoleDeviceStore,
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        orders: [],
        hitCount:false,
        searchCount: true,
        pages:0
      },
      pageQuery:{
        devCode:'',
        devName:'',
        size:this.$pageSize,
        current: 1,
      },
      deviceId:'',
      deviceSn:'',
      serialNumber:'',//设备编号
      faceRecordDialogVisible:false,
      faceOnoffDialogVisible:false,
      faceConfigDialogVisible:false
    }
  },
  created() {
    EventBus.$on('loadTable',()=>{
        this.loadTable();
    });
    EventBus.$on('refresh',()=>{
      this.loadTable();
    });
    this.loadTable();
  },
  computed:{
    macStatus(){
      return function (status) {
        switch (status) {
          case 0:
            return "停止"
          case 1:
            return "运行"
        }
      }
    },
    macType(){
      return function (type) {
        switch (type) {
          case 105:
            return "人脸识别"
        }
      }
    }
  },
  methods:{
    edit(row){
      EventBus.$emit('manFaceDriver',row)
    },
    handleCurrentChange(value){
      //console.log("当前页："+value)
      this.pageQuery.current=value;
      this.loadTable();
    },
    handleSizeChange(value){
      //console.log("每页大小变更："+value)
      this.pageQuery.size=value;
      this.pageQuery.current=1
      this.loadTable()
    },
    loadTable(){
      this.pageQuery.areaId = consoleDeviceStore.state.org.areaId;
      this.$axios.post(`/sys/device/list/area/105`,this.pageQuery).then((response)=>{
        this.page=response.data
      });
    },
    /**刷新表格数据 */
    handleRefresh(){
      this.currPage = 1
      this.loadTable();
    },
    queryClick(){
      this.loadTable();
    }
  }
}
</script>

<style scoped>

</style>
