<template>
  <div class="card">
  <el-card>
    <div slot="header">
      <span>xxxx项目</span><el-button type="primary" size="small" @click="$router.push('/elevatorDevice')"> 详情</el-button>
    </div>
    <div>
      <span>塔机数量：12</span>
      <span>在线数量：4</span>
      <span>离线数量：5</span>
    </div>
  </el-card>
    <el-card>
      <div>

      </div>
    </el-card>
    <el-card>
      <div>

      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "elevatorMonitor"
}
</script>

<style scoped>

  .card{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .card>div{
    flex: 1;
    margin:10px ;
  }
</style>
