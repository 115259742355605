<template>
  <el-card  style="width: 50%;height: 400px;margin-top: 20px">
    <div>
     电能监控开发中
    </div>

  </el-card>
</template>

<script>
export default {
  name: "electricDevice"
}
</script>

<style scoped>

</style>
