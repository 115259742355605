<template>
<div>
  <!-- 面包屑导航 -->
  <crumbs-bar @refresh="handleRefresh" :crumbsList="['公司管理']">
    <template slot="controls">
      <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline" v-if="hasPerm('company:add')">新增</el-button>
    </template>
  </crumbs-bar>
  <!-- <el-button style="margin-bottom: 20px" type="primary" size="small" @click="add">添加公司</el-button>-->
  <el-row style="margin: 0 20px 0 10px;">
    <el-col>
      <el-form :inline="true" :model="pageQuery" ref="pageQuery" style="margin-top: 10px;">
        <el-form-item style="margin-left: 5px;" label="公司名称">
          <el-input v-model="pageQuery.comName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  <el-row style="margin: 0 20px 0 10px;">
    <el-col>
      <el-table :data="page.records" border>
        <el-table-column type="index" label="序号" width="80px"></el-table-column>
        <el-table-column prop="name" label="公司名称"></el-table-column>
        <el-table-column prop="shortName" label="公司简称" width="300"></el-table-column>
        <el-table-column prop="address" label="地址" width="300"></el-table-column>
        <el-table-column prop="tel" label="电话" width="200"></el-table-column>
        <el-table-column label="操作" width="150px">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="edit(scope.row)" v-if="hasPerm('company:update')">修改</el-button>
            <el-button type="danger" size="mini" @click="del(scope.row.id)" v-if="hasPerm('company:delete')">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>

  <div style=" text-align: left;margin-top: 20px;">
    <el-pagination style="margin-top: 15px;"
      :current-page="page.current"
      :page-sizes="this.$pageSizes"
      :page-size="page.size"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total,sizes,prev,pager,next,jumper"
      :total="page.total"
    ></el-pagination>
  </div>

  <el-dialog @close="dialogClose" :show-close="false" class="status_change"
             :close-on-click-modal="false" :title="title"
             :visible.sync="dialogVisible" width="550px" left>
      <el-form ref="formData" label-suffix="：" :rules="formRule"
               :model="formData" label-width="120px">
        <el-form-item label="公司名称" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="公司简称" prop="shortName">
          <el-input v-model="formData.shortName"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="address">
          <el-input v-model="formData.address"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input v-model="formData.contact"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input v-model="formData.tel"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible=false">关闭</el-button>
        <el-button size="small" :loading="submitLoading" type="primary" @click="onSubmit">提交</el-button>
      </span>
  </el-dialog>
</div>
</template>

<script>
import {mustFill} from "@/validation/valid";

export default {
  name: "company",
  data(){
    return {
      title:"",
      dialogVisible:false,
      submitLoading:false,
      page:{
        records: [],
        total: 0,
        size: 0,
        current: 0,
        orders: [],
        hitCount: false,
        searchCount: true,
        pages: 0
      },
      pageQuery:{
        comName:'',
        current:1,
        size:this.$pageSize
      },
      formData:{
        id:"",
        name:"",
        shortName:"",
        address:"",
        tel:"",
        contact:""
      },
      formRule:{
        name:[mustFill],
        shortName:[mustFill],
        address:[mustFill],
        tel:[mustFill],
        contact:[mustFill]
      }
    }
  },
  mounted() {
    this.pageQuery.current=0;
    this.list();
  },
  created() {
    this.formData={
      id:"",
      name:"",
      shortName:"",
      address:"",
      tel:"",
      contact:""
    }
  },
  methods:{
    list(){
      let _this =this;
      this.$axios.post("/sys/company/list", this.pageQuery).then(function (response){
        _this.page=response.data;
        console.log(response.data)
      }).catch(function (error){
        console.log(error)
      })
    },
    add(){
      this.title="新增公司"
      this.submitLoading=false;
      this.dialogVisible=true;
    },
    edit(data){
      this.title="修改公司"
      this.formData=Object.assign(this.formData, data)
      this.dialogVisible=true;
    },
    del(id){
      let _this=this;
      this.$confirm('确定删除该公司吗？','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(function (){
        _this.$axios.post("/sys/company/del/"+id).then(function (response){
          if (response.data.result==="success"){
            _this.$message({
              type:'success',
              message:'删除成功'
            })
            _this.list()
          }else {
            _this.$message({
              type:'error',
              message:'删除失败'
            })
          }
        })
      }).catch(function (){
        _this.$message({
          type:'info',
          message:'取消删除'
        })
      })
    },
    onSubmit(){
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let _this = this
          this.submitLoading = true;
          this.$axios.post('/sys/company/save', this.formData).then(function (response) {
            if (response.data.result === "success") {
              _this.dialogVisible = false
              _this.submitLoading = false
              _this.list()
              _this.$message({message: '保存成功', type: 'success'})
              _this.$refs['formData'].resetFields()
            }else {
              _this.$message({
                message: '保存失败',
                type: 'error'
              });
            }
          }).catch(function (error) {
            console.log(error);
          })
        } else {
          this.$message({
            message: "请补全公司信息",
            type: "warning",
          });
        }
      });
    },
    handleCurrentChange(val){
      this.pageQuery.current=val
      this.list();
    },
    handleSizeChange(val) {
      this.pageQuery.size=val
      this.pageQuery.current=1
      this.list();
    },
    resetForm(){
      Object.assign(this.$data.formData,this.$options.data().formData)
    },
    dialogClose(){
      Object.assign(this.$data.formData,this.$options.data().formData)
    },
    handleRefresh(){

    },
    queryClick() {
      this.list()
    },
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
</style>
