<!--
卸料平台
-->
<template>
  <div class="visual_conBot_un">
    <img class="visual_conBot_l" alt="" src="../../views/dataV/static/images/ksh42.png">
    <img class="visual_conBot_2" alt="" src="../../views/dataV/static/images/ksh43.png">
    <img class="visual_conBot_3" alt="" src="../../views/dataV/static/images/ksh44.png">
    <img class="visual_conBot_4"  alt="" src="../../views/dataV/static/images/ksh45.png">
    <div class="mid-tower fl">
      <span v-if="this.macInfo.state==0">
        <div class="unloading-status-1 fl">
          <div class="left fl">
            <div class="title">NO1 载重</div>
            <div class="value">{{this.macInfo.weight}}KG</div>
          </div>
          <div class="right fl">
            <div class="imgalarm">
              <img src="../../assets/svg/green.svg" width="48" height="48" />
            </div>
            <div class="alarmtxt">
              正常
            </div>
          </div>
        </div>
        <div class="unloading-status-1 fl">
          <div class="middle fl">
            <div class="middle0 fl">
              <div class="title">NO2 当前倾角x</div>
              <div class="value">{{this.macInfo.incidenceX}}度</div>
            </div>
            <div class="middle1 fl">
              <div class="title">当前倾角y</div>
              <div class="value">{{this.macInfo.incidenceY}}度</div>
            </div>
          </div>
          <div class="right fl">
            <div class="imgalarm">
              <img src="../../assets/svg/green.svg" width="48" height="48" />
            </div>
            <div class="alarmtxt">
              正常
            </div>
          </div>
        </div>
      </span>
      <span v-if="this.macInfo.state==1">
        <div class="unloading-status-1 fl">
          <div class="left fl">
            <div class="title">NO1 载重</div>
            <div class="value">{{this.macInfo.weight}}KG</div>
          </div>
          <div class="right fl">
            <div class="imgalarm">
              <img src="../../assets/svg/red.svg" width="48" height="48" />
            </div>
            <div class="alarmtxt">
              <span style="color: red;">异常</span>
            </div>
          </div>
        </div>
        <div class="unloading-status-1 fl">
          <div class="middle fl">
            <div class="middle0 fl">
              <div class="title">NO2 当前倾角x</div>
              <div class="value">{{this.macInfo.incidenceX}}度</div>
            </div>
            <div class="middle1 fl">
              <div class="title">当前倾角y</div>
              <div class="value">{{this.macInfo.incidenceY}}度</div>
            </div>
          </div>
          <div class="right fl">
            <div class="imgalarm">
              <img src="../../assets/svg/green.svg" width="48" height="48" />
            </div>
            <div class="alarmtxt">
              <span>正常</span>
            </div>
          </div>
        </div>
      </span>
      <span v-if="this.macInfo.state==2">
        <div class="unloading-status-1 fl">
          <div class="left fl">
            <div class="title">NO1 载重</div>
            <div class="value">{{this.macInfo.weight}}KG</div>
          </div>
          <div class="right fl">
            <div class="imgalarm">
              <img src="../../assets/svg/green.svg" width="48" height="48" />
            </div>
            <div class="alarmtxt">
              正常
            </div>
          </div>
        </div>
        <div class="unloading-status-1 fl">
          <div class="middle fl">
            <div class="middle0 fl">
              <div class="title">NO2 当前倾角x</div>
              <div class="value">{{this.macInfo.incidenceX}}度</div>
            </div>
            <div class="middle1 fl">
              <div class="title">当前倾角y</div>
              <div class="value">{{this.macInfo.incidenceY}}度</div>
            </div>
          </div>
          <div class="right fl">
            <div class="imgalarm">
              <img src="../../assets/svg/red.svg" width="48" height="48" />
            </div>
            <div class="alarmtxt">
              <span style="color: red;">异常</span>
            </div>
          </div>
        </div>
      </span>
      <span v-if="this.macInfo.state==3">
        <div class="unloading-status-1 fl">
          <div class="left fl">
            <div class="title">NO1 载重</div>
            <div class="value">{{this.macInfo.weight}}</div>
          </div>
          <div class="right fl">
            <div class="imgalarm">
              <img src="../../assets/svg/red.svg" width="48" height="48" />
            </div>
            <div class="alarmtxt">
              <span style="color: red;">异常</span>
            </div>
          </div>
        </div>
        <div class="unloading-status-1 fl">
          <div class="middle fl">
            <div class="middle0 fl">
              <div class="title">NO2 当前倾角x</div>
              <div class="value">{{this.macInfo.incidenceX}}</div>
            </div>
            <div class="middle1 fl">
              <div class="title">当前倾角y</div>
              <div class="value">{{this.macInfo.incidenceY}}</div>
            </div>
          </div>
          <div class="right fl">
            <div class="imgalarm">
              <img src="../../assets/svg/red.svg" width="48" height="48" />
            </div>
            <div class="alarmtxt">
              <span style="color: red;">异常</span>
            </div>
          </div>
        </div>
      </span>
      <div class="unloading-status-1 fl">
        <div class="left fl">
          <div class="title">NO3 电压</div>
          <div class="value">{{this.macInfo.power}}V</div>
        </div>
        <div class="right fl">
          <div class="imgalarm"></div>
          <div class="alarmtxt"></div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="anti-collision">
      <img src="../../assets/images/unloading.png" height="519" width="591" />
    </div>
  </div>
</template>

<script>
export default {
  name: "leftOneState",
  props: ["macInfo"],
  data() {
    return {
      devInfo:{},
      windVal: 0
    }
  },
  mounted(){
  },
  methods:{

  }
}
</script>
<style scoped>
.mid-tower {
  width: calc(41.5% - 10px);
  height: calc(100% - 8px);
  position:absolute;
  top:4px;
  bottom:4px;
  left:4px;
  /*border:1px solid #345f92;*/
}
.fl {
  float: left;
}
.unloading-status-1{
  width: 100%;
  height: calc(33.3% - 1px);
  border:1px solid #345f92;
  margin-bottom: 2px;
  margin-left: 2px;
  text-align: center;
}
.unloading-status-1 .left{
  width: 60%;
}
.unloading-status-1 .left .title{
  color: #00d8ff;
  font-size: 18px;
  width: 100%;
  line-height: 40px;
  margin-top: 45px;
  text-align: left;
  padding-left: 50px;
}
.unloading-status-1 .left .value{
  color: #fff;
  font-size: 18px;
  width: 100%;
  text-align: left;
  padding-left: 95px;
}
.unloading-status-1 .right{
  width: 40%;
  /*border:1px solid #345f92;*/
}
.unloading-status-1 .right .imgalarm{
  margin-top: 40px;
  width: 100%;
}
.unloading-status-1 .right .alarmtxt{
  color: #fff;
  font-size: 14px;
  width: 100%;
}

.unloading-status-1 .middle{
  width: 60%;
  margin-top: 15px;
}
.unloading-status-1 .middle0{
  width: 60%;
}
.unloading-status-1 .middle0 .title{
  color: #00d8ff;
  font-size: 18px;
  width: 100%;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  padding-left: 50px;
}
.unloading-status-1 .middle0 .value{
  color: #fff;
  font-size: 18px;
  width: 100%;
  text-align: left;
  padding-left: 95px;
}

.unloading-status-1 .middle1{
  width: 60%;
}
.unloading-status-1 .middle1 .title{
  color: #00d8ff;
  font-size: 18px;
  width: 100%;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  padding-left: 95px;
}
.unloading-status-1 .middle1 .value{
  color: #fff;
  font-size: 18px;
  width: 100%;
  text-align: left;
  padding-left: 95px;
}

.anti-collision{
  width:52%;
  height:calc(100% - 8px);
  /*background:rgba(16,54,87,0.5);
  border:1px solid #345f92;*/
  position:absolute;
  top:4px;
  bottom:4px;
  right:4px;
  z-index:0;
  text-align: right;
  padding-right: 80px;
  padding-top: 8px;
}
</style>
