<template>
  <el-card>
    <div> 塔机开发中</div>
  </el-card>
</template>

<script>
export default {
  name: "towerMonitor"
}
</script>

<style scoped>

</style>
