<template>
    <div class="devVideo">
      <span id="devVideo1" class="container">
        <div id="videoPlayer"></div>
      </span>
      <!-- 海康威视
      <span id="devVideo2">
        <div id="video-container" style="width:600px;height:400px"></div>
      </span>-->
    </div>
</template>

<script>
//import EZUIKit0 from "../../utils/ezuikit"
import EZUIKit from 'ezuikit-js';

export default {
  name: "oppfDevVideo",
  components: {
  },
  data() {
    return {

    }
  },
  mounted() {
    this.getVideoList();
  },
  methods:{
    getVideoList(){
      var param = {
        devCode :this.$route.query.deviceId
      }
      this.$axios.post("/sys/video/oppfPlayHls",param).then((response) => {
        if (response.data.result === 'success') {
          var list = response.data.list1;
          //var list2 = response.data.list2;//海康威视
          var devVideo1= document.getElementById("devVideo1");
          var divHtml = '';
          for (var i = 0; i < list.length; i++) {
            divHtml += this.setDiv(list[i]);
          }
          devVideo1.innerHTML = divHtml;
          for (var j = 0; j < list.length; j++) {
            new EZUIKit.EZUIPlayer('myPlayer' + list[j].id);
          }
          /* //海康威视
          var devVideo2= document.getElementById("devVideo2");
          divHtml = '';
          if(list2.length>0){
            divHtml += this.setDiv0(list2);
          }
          devVideo2.innerHTML = divHtml;
          this.getEzvizVideoList(list2);*/
        } else {
          this.$message({
            message:'视频播放地址获取失败，请查看摄像机序列号是否存在！',
            type:'error'
          });
        }
      });
    },
    setDiv(item){
      var div = '<div id="videoPlayer"><video id="myPlayer'+item.id+'" autoplay muted src="' + item.hlsUrl + '" controls playsInline webkit-playsinline width="550" height="450">'+'</video></div>'
      return div
    },
    getEzvizVideoList(item){
      for(var i=0;i<item.length;i++){
        console.log(item[i].id+","+item[i].hlsUrl)
        console.group("mounted 组件挂载完毕状态===============》"+item[i].id);
        var player = new EZUIKit.EZUIKitPlayer({
          autoplay: true,
          id: "video-container"+item[i].id,
          accessToken:"at.83nce9mh2wsf4rq84z0wahlt96qs7cl7-8ac7rer3ha-1l9u97g-45qcsw9kh",
          url: item[i].hlsUrl,
          template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          // 视频上方头部控件
          //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
          //plugin: ['talk'],                       // 加载插件，talk-对讲
          // 视频下方底部控件
          // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
          // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
          // openSoundCallBack: data => console.log("开启声音回调", data),
          // closeSoundCallBack: data => console.log("关闭声音回调", data),
          // startSaveCallBack: data => console.log("开始录像回调", data),
          // stopSaveCallBack: data => console.log("录像回调", data),
          // capturePictureCallBack: data => console.log("截图成功回调", data),
          // fullScreenCallBack: data => console.log("全屏回调", data),
          // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
          width: 600,
          height: 400
        });
        console.log("player"+item[i].id,player);
      }
    },
    setDiv0(item){
      var div = '';
      for(var i=0;i<item.length;i++) {
        div += '<div id="video-container' + item[i].id + '" style="width:600px;height:400px"></div>'
      }
      return div
    },
  }
}
</script>

<style scoped>
.devVideo{
  width: 100%;
  height: 100%;
  background-color: #00094E;
}
.container{
  text-align:center;
  position: absolute;top: 50%;left: 50%;margin: -15% 0 0 -16%;
  display: flex;
  padding: 5px 5px 2px 5px;
  /*justify-content: space-around;*/
  align-items: center;
  flex-wrap: wrap;
  border:2px solid #9a6e3a;
}
</style>
