<template>
  <div>
    <div class="card-flex">
      <el-card class="layui-elevator">
        <div>
          <span>设备数量：</span>
          <span>{{ deviceStatics.total }}台</span>
        </div>
      </el-card>
      <el-card class="layui-elevator">
        <div >
          <span>当前在线：</span>
          <span>{{ deviceStatics.online }}台</span>
        </div>
      </el-card>
      <el-card class="layui-elevator">
        <div  >
          <span>当前离线：</span>
          <span>{{ deviceStatics.offline }}台</span>
        </div>
      </el-card>
      <el-card class="layui-elevator">
        <div>
          <span>今日累计：</span>
          <span>{{ cumulative.cumulativeCount }}次</span>
        </div>
      </el-card>
      <el-card class="layui-elevator">
        <div  >
          <span>违章：</span>
          <span>{{cumulative.violateCount}}次</span>
        </div>
      </el-card>
    </div>
    <div>
      <a-device v-for="(item,index) in deviceList"
                :device-id="item.code"
                :device-name="item.name"
                :device-type="item.type"
                :key="index"
                :device-title="item.name+'-历史数据'"
                :device-cycle-title="item.name+'-吊装循环'"
                :online-state="item.status"></a-device>
    </div>
  </div>
</template>

<script>
import aDevice from "@/views/projectDevice/elevator/aDevice";
export default {
  name: "elevator",
  components:{
    aDevice,
  },
  props:{
    projectId:String
  },
  data(){
    return{
      deviceStatics:{
        total:'--',
        online:'--',
        offline:'--',
      },
      cumulative:{
        cumulativeCount:'--',
        violateCount:'--'
      },
      deviceList:[]

    }
  },
  mounted() {
    this.loadCumulative()
    this.loadDeviceStatics()
    this.loadDevice()
  },
  methods:{
    loadCumulative(){
      this.$axios.post(`/sys/project/ext/statics/${this.projectId}/cumulative/101`).then(response=> {
        this.cumulative=response.data;
      }).catch(function (err){
        console.log(err);
      })
    },
    loadDevice(){
      this.$axios.post(`/sys/device/101/listAll/${this.projectId}`).then(response=>{
        this.deviceList=response.data;
      })
    },
    loadDeviceStatics(){
      this.$axios.post(`/sys/project/ext/statics/${this.projectId}/device/101`).then(response=>{
        this.deviceStatics=response.data
      })
    }
  }
}
</script>

<style scoped>
.card-flex >div{
  width: 200px;
  flex: 1;
  text-align: center;
  color: #ffffff;/*color: #008CFF;*/
}
.card-flex{
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  gap: 20px;
}

.layui-elevator{
  height: 100%; background: rgba(0, 124, 249, 0.1);
  border: 1px solid #0056A1; border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
</style>
