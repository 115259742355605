<template>
  <div  style="margin: 15px 0;">
<el-card class="layui-elevator">
    <div @click="chartsVisible=!chartsVisible" style="display: flex;justify-content: space-between;cursor:pointer">
      <div style="line-height: 32px;color:#fff;">
        <span>设备编号：</span>
        <span style="display: inline-block;width: 100px">{{deviceCode}}</span>
        <span>设备名称：</span>
        <span style="width: 250px;display: inline-block;">{{ deviceName }}</span>
        <span style="margin-left: 20px">在线状态：</span>
        <!-- <span>{{state==0?'离线':'在线'}}</span>-->
        <span v-if="state==0"><img src="../../../../assets/images/off.png" height="16" width="16"></span>
        <span v-if="state==1"><img src="../../../../assets/images/on.png" height="16" width="16"></span>
      </div>
      <div style="line-height: 32px;color:#fff;">{{chartsVisible?"点击收起":"点击展开"}}</div>
      <div>
        <el-button size="small" type="primary" plain @click.stop="analogMonitoring">模拟监控</el-button>
        <el-button size="small"  type="primary" plain @click.stop="historyVisible=true">历史数据</el-button>
      </div>
    </div>
    <el-collapse-transition>
    <div v-if="chartsVisible">
      <el-divider></el-divider>
      <div class="card_flex">
        <base-card title="重量" :device-id="deviceCode" url="/sys/msgLiveDataUnloading/deviceCard/chart/weight" ></base-card>
        <base-card title="载重百分比" :device-id="deviceCode" url="/sys/msgLiveDataUnloading/deviceCard/chart/weightPercent"></base-card>
        <base-card title="x倾角" :device-id="deviceCode" url="/sys/msgLiveDataUnloading/deviceCard/chart/incidenceX"></base-card>
        <base-card title="y倾角" :device-id="deviceCode" url="/sys/msgLiveDataUnloading/deviceCard/chart/incidenceY"></base-card>
      </div>
    </div>
    </el-collapse-transition>

</el-card>
  <history :visible.sync="historyVisible" :device-code="deviceCode" :device-title="deviceTitle"></history>
  </div>
</template>

<script>
import baseCard from "@/views/projectDevice/card/baseCard";
import History from "@/views/projectDevice/unloadingPlatform/subComponent/history";
export default {
  name: "aDevice",
  props:[
    "deviceCode","deviceName",
    "state","deviceTitle"
  ],
  data(){
    return{
      chartsVisible:false,
      historyVisible:false
    }
  },
  components:{
    History,
    baseCard
  },
  methods:{
    analogMonitoring(){
      let path='/unloadingPlatState'
      let routeData=this.$router.resolve({
        path:path,
        query:{
          deviceId:this.deviceCode
        }
      })
      window.open(routeData.href,'_blank')
    }
  }
}
</script>

<style scoped>
.card_flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card_flex>div{
  min-width: 800px;
  min-height: 200px;
  border: 1px solid #0056A1;
  margin-bottom: 10px;
}
.layui-elevator{
  height: 100%; background: rgba(0, 124, 249, 0.1);
  border: 1px solid #0056A1; border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
</style>
