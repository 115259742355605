<template>
  <el-card>
    <div> 卸料平台开发中</div>
  </el-card>
</template>

<script>
export default {
  name: "unloadingMonitor"
}
</script>

<style scoped>

</style>
