<!--
左三

-->

<template>
  <div class="visual_box">
    <div class="visual_title">
      <span>状态3</span>
      <img src="../../views/dataV/static/images/ksh33.png">
    </div>
    <div class="visual_open"  v-for="(item,i) in this.stateThree" :key="i">
      <h5>{{item.name}}</h5>
      <span style="float: right; font-size: 14px; color: #bd2c00">{{item.value}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "leftThreeState",
  props:["stateThree"],
}
</script>
