<template>
  <el-card class="project-card">
    <div slot="header" class="box-header">
      <div> <span>{{ projectData.name }}</span></div>
      <div><router-link :to="{path:`/someAProjectOverView/${this.projectData.id}`}"><el-button size="small" type="primary">详情</el-button></router-link></div>
    </div>
    <div class="box-body" >
      <div class="box-body-left">
        <div>
          <span>项目名称:</span>
          <span>{{ projectData.name }}</span>
        </div>
        <div>
          <span>项目负责人:</span>
          <span>{{ projectData.principal }}</span>
        </div>
        <div>
          <span>负责人电话:</span>
          <span>{{ projectData.tel }}</span>
        </div>
        <div>
          <span>项目进度:</span>
          <span>
            <el-progress style="display: inline-block;width: 150px" :text-inside="true" :stroke-width="15" :percentage="projectData.progress"></el-progress>
           </span>
        </div>
        <div>
          <span>项目部人数:</span>
          <span>{{ projectData.numberOfPeople }}</span>
        </div>
      </div>
      <div class="box-body-right" >
        <div>
          <div>
            <span >{{ statics.deviceNumber }}</span>
            <span>设备总数</span>
          </div>
          <div>
            <span>{{ statics.deviceType }}</span>
            <span>设备种类</span>
          </div>
        </div>
        <div>
          <div>
            <span>{{ statics.online }}</span>
            <span>在线数</span>
          </div>
          <div>
            <span>{{ statics.offline }}</span>
            <span>离线数</span>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "projectCard",
  props:['projectData'],
  data(){
    return {
      statics:{
        deviceNumber:'--',
        deviceType:'--',
        online:'--',
        offline:'--'
      }
    }
  },
  mounted() {
    this.loadStatics();
  },
  methods:{
    loadStatics(){
      this.$axios.post(`/sys/project/staticDevice/${this.projectData.id}`).then((response)=>{
        this.statics=response.data;
      })
    }
  }
}
</script>

<style scoped>

.project-card{
  width: 600px;
  height: 400px;
  flex: none;
  margin-top: 10px;
}
.box-header{
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: space-between;
}
.box-body{
  display: flex;
  align-items: center;
}
.box-body>div{
    height: 300px;
    flex: auto;
}
.box-body-left{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  padding: 10px;
  box-sizing: border-box;
}

.box-body-left>div>span:first-child{
  width: 100px;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
}


.box-body-right{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box-body-right>div {
  display: flex;
  margin: 30px;
}
.box-body-right>div>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.box-body-right span{
  margin: 5px;
}





</style>
