<template>
  <el-dialog :visible.sync="visible"
             :title="dialogTitle"
             :close-on-click-modal="false"
             :show-close="false"
             class="status_change1"
             width="60%" left>
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="添加司机" name="first">
        <el-form ref="formData" :model="formData" label-suffix="：" label-width="150px" :rules="formRule">
          <el-form-item label="司机姓名" prop="driverName">
            <el-input v-model="formData.driverName"></el-input>
          </el-form-item>
          <el-form-item label="卡号" prop="card" >
            <el-input v-model="formData.card" placeholder="最多为10位数字"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="userNo">
            <el-input v-model="formData.userNo"></el-input>
          </el-form-item>
          <el-form-item label="识别模式">
            <el-select v-model="formData.mode" :disabled="true">
              <el-option v-for="item in modes"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="司机照片">
            <el-upload
                class="avatar-uploader"
                :action="this.COMMON.houseApiUrl+'/dev/driver/imgupload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload" style="display: inline-block;">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload> <span style="font-size: 14px; color: red">图片大小不能超过300k</span>
          </el-form-item>
          <el-form-item label="备注" prop="note" >
            <el-input v-model="formData.note"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="司机列表" name="second">
        <el-form :inline="true" :model="pageQuery" ref="pageQuery" style="margin-top: 10px;">
          <el-form-item style="margin-left: 5px;" label="司机姓名">
            <el-input v-model="pageQuery.driverName" placeholder="请输入司机姓名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button v-loading.body="faceListLoading" type="primary" @click="queryClick()">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="page.records" height="400" border>
          <el-table-column type="index" width="80px" label="序号"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名" width="130px"></el-table-column>
          <el-table-column label="下发状态" width="100px">
            <template slot-scope="scope">
              <div v-if="scope.row.regFlag===1">已下发</div>
              <div v-if="scope.row.regFlag===0">未下发</div>
            </template>
          </el-table-column>
          <el-table-column label="远程下发操作" width="120px">
            <template slot-scope="scope">
              <el-button type="success" @click="registerPersonInfo(scope.row)" v-if="hasPerm('face:remoteDown')" icon="el-icon-check" circle></el-button>
              <el-button type="danger" @click="remoteDel(scope.row)" v-if="hasPerm('face:remoteDel')" icon="el-icon-delete" circle></el-button>
            </template>
          </el-table-column>
          <el-table-column label="司机照片" width="120px">
            <template slot-scope="scope">
              <img :src="picInitUrl+scope.row.picUrl" width="60" height="60"/>
            </template>
          </el-table-column>
          <el-table-column prop="card" label="卡号" width="200px"></el-table-column>
          <el-table-column prop="userNo" label="身份证号" width="200px"></el-table-column>
          <el-table-column label="识别模式" width="150px">
            <template slot-scope="scope">
              <span v-if="scope.row.mode===1">人脸识别</span>
              <span v-if="scope.row.mode===2">卡或人脸</span>
              <span v-if="scope.row.mode===3">-卡+人脸</span>
            </template>
          </el-table-column>
          <el-table-column prop="note" label="备注" width="200px"></el-table-column>
          <el-table-column label="操作" width="130px">
            <template slot-scope="scope">
              <el-button type="primary" @click="editInit(scope.row)" v-if="hasPerm('face:add')" icon="el-icon-edit" circle></el-button>
              <el-button type="danger" @click="del(scope.row)" v-if="hasPerm('face:del')" icon="el-icon-delete" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            :current-page="this.page.current"
            :page-sizes="this.$pageSizes"
            :page-size="this.page.size"
            layout="total,sizes,prev,pager,next,jumper"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            style="text-align: left;margin-top: 10px">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="编辑司机" name="third">
        <el-form ref="formData1" :model="formData1" label-suffix="：" label-width="150px" :rules="formRule1">
          <el-form-item label="司机姓名" prop="driverName">
            <el-input v-model="formData1.driverName"></el-input>
          </el-form-item>
          <el-form-item label="卡号" prop="card">
            <el-input v-model="formData1.card"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="userNo">
            <el-input v-model="formData1.userNo"></el-input>
          </el-form-item>
          <el-form-item label="识别模式">
            <el-select v-model="formData1.mode" :disabled="true">
              <el-option v-for="item in modes"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="司机照片">
            <!--:action="'http://'+this.COMMON.initUrl+':9097/api/face/imgupload'"-->
            <el-upload
                class="avatar-uploader"
                :action="this.COMMON.houseApiUrl+'/dev/face/imgupload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload" style="display: inline-block;">
              <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload> <span style="font-size: 14px; color: red">图片大小不能超过300k</span>
          </el-form-item>
          <el-form-item label="备注" prop="note" >
            <el-input v-model="formData1.note"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="save" :loading="saveLoading" type="primary" v-if="isAddShow">保存</el-button>
      <el-button size="small" @click="update" :loading="saveLoading" type="primary" v-if="isEditShow">保存</el-button>
      <el-button size="small" @click="visible=false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {EventBus} from "@/views/consoleDevice/event-bus";
import {mustFill} from "@/validation/valid";

export default {
  name: "fyFaceDriver",
  data(){
    return{
      visible:false,
      saveLoading:false,
      dialogTitle:'',
      deviceTypes:[
        {value: 105,label: '人脸识别'},
      ],
      modes: [{
        value: 1,
        label: '人脸识别'
      }, {
        value: 2,
        label: '卡或人脸'
      }, {
        value: 3,
        label: '卡+人脸'
      }],
      formData:{
        id:'',
        deviceCode:'',
        deviceSn:'',
        driverName:'',
        card:'',
        userNo:'',
        mode:1,
        picUrl:'',
        note:''
      },
      formData1:{
        id:'',
        deviceCode:'',
        deviceSn:'',
        driverName:'',
        card:'',
        userNo:'',
        mode:'',
        picUrl:'',
        note:''
      },
      formDevData:{
        companyId:'',
        projectId:'',
        areaId:'',
        code:'',
        serialNumber:''
      },
      formRule:{
        driverName:[mustFill],
        card:[mustFill],
        userNo:[mustFill],
      },
      formRule1:{
        driverName:[mustFill],
        card:[mustFill],
        userNo:[mustFill],
      },
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        orders: [],
        hitCount:false,
        searchCount: true,
        pages:0
      },
      pageQuery:{
        driverName: '',
        size:this.$pageSize,
        current: 1,
      },
      activeName: 'first',
      isAddShow:true,
      isEditShow:false,
      imageUrl: '',
      imageUrl1: '',
      picInitUrl:'http://'+this.COMMON.initUrl+':9098/face/',
      faceListLoading: false,
    }
  },
  created() {
    EventBus.$on('manFaceDriver',(row)=>{
      this.formDevData=Object.assign({},row)
      this.visible=true
      this.dialogTitle="司机信息远程下发"
      this.loadTable();
    })
  },
  methods:{
    save(){
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if(this.imageUrl===""){
            this.$message({message: "请上传司机照片！", type: "warning",});
            return;
          }
          this.formData.deviceCode = this.formDevData.code;
          this.formData.deviceSn = this.formDevData.serialNumber;
          this.$axios.post("/dev/driver/save", this.formData).then((response) => {
            if (response.data.result === 'success') {
              this.$message({message: response.data.msg, type: 'success'});
              this.formData = {
                id:'',deviceCode:'', deviceSn:'', driverName:'',
                card:'', userNo:'', mode:1, picUrl:'',note:''
              };
              this.imageUrl = '';
            }
            if (response.data.result === 'fail') {
              this.$message({message: response.data.msg, type: 'error'});
            }
          }).catch(function (err) {
            console.log(err)
          })
        }else {
          this.$message({
            message: "请补全司机信息！",
            type: "warning",
          });
        }
      });
    },
    update(){
      this.$refs["formData1"].validate((valid) => {
        if (valid) {
          if(this.imageUrl1===""){
            this.$message({message: "请上传司机照片！", type: "warning",});
            return;
          }
          this.formData1.deviceCode = this.formDevData.code;
          this.formData1.deviceSn = this.formDevData.serialNumber;
          this.$axios.post("/dev/driver/save", this.formData1).then((response) => {
            if (response.data.result === 'success') {
              this.$message({message: response.data.msg, type: 'success'});
              this.formData = {
                id:'',deviceCode:'', deviceSn:'', driverName:'',
                card:'', userNo:'', mode:1, picUrl:'',note:''
              };
              this.imageUrl1 = '';
              this.activeName = "second";
              this.isEditShow = false;
              this.loadTable();
            }
            if (response.data.result === 'fail') {
              this.$message({message: response.data.msg, type: 'error'});
            }
          }).catch(function (err) {
            console.log(err)
          })
        }else {
          this.$message({
            message: "请补全司机信息！",
            type: "warning",
          });
        }
      });
    },
    editInit(row){
      this.formData1 = row;
      this.imageUrl1 = this.picInitUrl+row.picUrl;
      if(this.activeName==="second"){
        this.activeName = "third";
        this.isAddShow = false;
        this.isEditShow = true;
      }
    },
    del(row){
      if(row.regFlag===1){
        this.$message.error('请先清除司机远程下发信息！')
        return;
      }
      this.$confirm('确定删除该司机信息','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=>{
        this.$axios.post(`/dev/driver/del/${row.id}`).then((response)=>{
          if(response.data.result==='success'){
            this.$message.success('删除成功')
            this.loadTable()
          }else {
            this.$message.error('删除失败')
          }
        })
      }).catch(()=>{
        this.$message.info('取消删除')
      })
    },
    handleTabClick(){
      if(this.activeName==="first"){
        this.isAddShow = true;
        this.isEditShow = false;
        this.formData = {
          id:'',
          deviceCode:'',
          deviceSn:'',
          driverName:'',
          card:'',
          userNo:'',
          mode:1,
          picUrl:''
        };
        this.imageUrl = '';
        this.imageUrl1 = '';
      } else if(this.activeName==="third") {
        this.isAddShow = false;
        this.isEditShow = false;
        this.formData1 = {
          id:'',
          deviceCode:'',
          deviceSn:'',
          driverName:'',
          card:'',
          userNo:'',
          mode:1,
          picUrl:''
        };
        this.imageUrl = '';
        this.imageUrl1 = '';
      } else {
        this.isAddShow = false;
      }
    },
    handleAvatarSuccess(res) {
      this.formData.picUrl = res.imgUrl;
      this.imageUrl = this.picInitUrl+res.imgUrl;//URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt1M = file.size / 1024 / 1024 < 0.29;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt1M) {
        this.$message.error('上传头像图片大小不能超过 300k!');
      }
      return isJPG && isLt1M;
    },
    handleCurrentChange(value){
      //console.log("当前页："+value)
      this.pageQuery.current=value;
      this.loadTable(this.pageQuery.driverName);
    },
    handleSizeChange(value){
      //console.log("每页大小变更："+value)
      this.pageQuery.size=value;
      this.pageQuery.current=1
      this.loadTable(this.pageQuery.driverName)
    },
    loadTable(driverName){
      this.pageQuery.driverName = driverName;
      this.pageQuery.deviceCode = this.formDevData.code;
      this.$axios.post(`/dev/driver/list`,this.pageQuery).then((response)=>{
        this.page=response.data
      });
    },
    registerPersonInfo(row){
      let data = {
        id:row.id,
        deviceCode:row.deviceCode,
        deviceSn:row.deviceSn,
        driverName:row.driverName,
        mode:row.mode,
        picUrl:this.picInitUrl+row.picUrl,
        card:row.card,
        userNo:row.userNo
      }
      this.$axios.post(`/fy/dev/registerPersonInfo`,data).then((response)=>{
        if(response.data.result==='success'){
          this.$message.success('人员信息远程下发成功，请确认。')
          this.loadTable(this.pageQuery.driverName)
        }else {
          this.$message.error('人员信息远程下发失败')
        }
      });
    },
    remoteDel(row){
      this.$confirm('确定远程删除该人员信息','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=> {
        let data = {
          id: row.id,
          deviceCode: row.deviceCode,
          deviceSn:row.deviceSn,
          driverName:row.driverName,
          card:row.card,
          userNo:row.userNo
        }
        this.$axios.post(`/fy/dev/del`, data).then((response) => {
          if (response.data.result === 'success') {
            this.$message.success('人员信息远程删除成功。')
            this.loadTable(this.pageQuery.driverName)
          } else {
            this.$message.error('人员信息远程删除失败')
          }
        });
      });
    },
    queryClick() {
      this.loadTable(this.pageQuery.driverName)
    },
  }
}
</script>

<style>
  .status_change1 .el-dialog__header{
      border-bottom: 1px solid #ebebeb;
  }
  .status_change1 .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
  .status_change1 .el-dialog__body {
    padding: 5px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #345f92;
  }
</style>
