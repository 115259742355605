<template>
<div>
<router-link to="/send">send data</router-link>
  <el-form ref="form" :model="form" label-width="80px">
    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="设备编号">
        <el-input v-model="form.deviceNo"></el-input>
      </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="设备类型">
          <el-input v-model="form.deviceType"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="cmd">
          <el-input v-model="form.cmd"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="驾驶员姓名">
          <el-input v-model="form.driverName"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="驾驶员身份证号">
          <el-input v-model="form.driverIDCard"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="设备状态">
          <el-input v-model="form.deviceState"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="重量">
          <el-input v-model="form.weight"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="重量百分比">
          <el-input v-model="form.weightPercent"></el-input>
        </el-form-item>
      </el-col>
    </el-row>


    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="人数">
          <el-input v-model="form.numberOfPeople"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="高度">
          <el-input v-model="form.height"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="高度百分比">
          <el-input v-model="form.heightPercent"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="楼层">
          <el-input v-model="form.floor"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="速度">
          <el-input v-model="form.speed"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="运行方向">
          <el-input v-model="form.direction"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="x倾角">
          <el-input v-model="form.inclinationX"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="x倾角百分比">
          <el-input v-model="form.inclinationXPercent"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="y倾角">
          <el-input v-model="form.inclinationY"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="y倾角百分比">
          <el-input v-model="form.inclinationYPercent"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="前门状态">
          <el-input v-model="form.frontDoor"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="后门状态">
          <el-input v-model="form.backDoor"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="重量预警报警">
          <el-input v-model="form.weightWarningAlarm"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="高度预警报警">
          <el-input v-model="form.heightWarningAlarm"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="速度预警报警">
          <el-input v-model="form.speedWarningAlarm"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="人数报警">
          <el-input v-model="form.numberOfPeopleAlarm"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="倾斜预警报警">
          <el-input v-model="form.inclinationWarningAlarm"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="身份报警">
          <el-input v-model="form.driverAuthenticationAlarm"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="前门状态报警">
          <el-input v-model="form.frontDoorAlarm"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="后门状态报警">
          <el-input v-model="form.backDoorAlarm"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8" :offset="8">
        <el-form-item label="门锁异常">
          <el-input v-model="form.doorLockException"></el-input>
        </el-form-item>
      </el-col>
    </el-row>



  </el-form>
  <el-button @click="getMyData">获取数据</el-button>
</div>


</template>

<script>

export default {
  name: 'test',
  data() {
    return {
      form: {
        deviceNo:'',
        deviceType:'',
        cmd:'',
        driverName:'',
        driverIDCard:'',
        deviceState:'',
        weight:'',
        weightPercent:'',
        numberOfPeople:'',
        height:'',
        heightPercent:'',
        floor:'',
        speed:'',
        direction:'',
        inclinationX:'',
        inclinationXPercent:'',
        inclinationY:'',
        inclinationYPercent:'',
        frontDoor:'',
        backDoor:'',
        weightWarningAlarm:'',
        heightWarningAlarm:'',
        speedWarningAlarm:'',
        numberOfPeopleAlarm:'',
        inclinationWarningAlarm:'',
        driverAuthenticationAlarm:'',
        frontDoorAlarm:'',
        backDoorAlarm:'',
        doorLockException:''

      }
    }
  },
  methods:{
    getMyData(){
      const that=this;
      this.$axios.get("/test/data").then(function (response){
        that.form=response.data;
      })
    }
  }

};
</script>
<style >

</style>
