<template>
  <el-dialog width="500px" :title="title" :visible.sync="dialogVisible" :close-on-click-modal="false">
    <el-form :model="myData" label-width="80px">
      <el-form-item label="权限代码">
        <el-input v-model="myData.permissionCode"></el-input>
      </el-form-item>
      <el-form-item label="权限名称">
        <el-input v-model="myData.permissionName" ></el-input>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button size="small" @click="dialogVisible=false">取消</el-button>
      <el-button size="small" @click="save" type="primary">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "formDialog",
  props:['visible','formData','title'],
  computed:{
    myData:function (){
      console.log(this.formData)
      return this.formData
    },
    dialogVisible:{
      get:function (){
        return this.visible
      },
      set:function (value){
        this.$emit('update:visible',value)
      }
    }
  },
  methods:{
    save(){
      this.$axios.post('/sys/permission/save',this.myData).then((response)=>{
        if (response.data.result==="success"){
          this.$message.success('保存成功')
          this.dialogVisible=false
          this.$emit("saved")
        }else {
          this.$message.error('保存失败')
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
