<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['项目管理']">
      <template slot="controls">
        <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline" v-if="hasPerm('project:add')">新增</el-button>
      </template>
    </crumbs-bar>
    <!-- <el-header >
        <el-button style="float: left" size="small" type="primary" @click="add">添加项目</el-button>
    </el-header>-->
    <el-container>
      <el-aside class="elaside">
        <el-tree :data="treeData" :props="{label:'name'}" :highlight-current="true" @node-click="handleNodeClick"></el-tree>
      </el-aside>
      <el-main style="padding: 0 0 0 5px;">
        <div>
          <el-table :data="page.records" >
            <el-table-column type="index" label="序号" width="80px"></el-table-column>
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column label="操作" width="220px">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="$router.push(`/elevatorDevice/${scope.row.id}`)">详情</el-button>
                <el-button type="primary" size="mini" @click="edit(scope.row)" v-if="hasPerm('project:update')">修改</el-button>
                <el-button type="danger" size="mini" @click="del(scope.row.id)" v-if="hasPerm('project:delete')">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style=" text-align: left;margin-top: 20px;">
          <el-pagination
              layout="total,sizes,prev,pager,next,jumper"
              :total="page.total"
          :current-page="page.current"
          :page-size="page.size"
          :page-sizes="this.$pageSizes"
          @current-change="handleCurrentChange"
          @size-change="handleCurrentChange">
          </el-pagination>
        </div>
      </el-main>
    </el-container>

    <el-dialog :title="dialogTitle" @open="dialogOpen" class="status_change"
               @close="dialogClosed" :show-close="false" width="550px"
               :visible.sync="dialogVisible" left>
      <el-form ref="formData" :model="formData" :rules="formRule"
               label-width="120px" label-suffix="：">
        <el-form-item label="所属公司">
          <!-- <el-select v-model="formData.companyId"  placeholder="请选择">
            <el-option
                v-for="(company,index) in selectList"
                :key="index"
                :label="company.name"
                :value="company.id">
            </el-option>
          </el-select>-->
          <el-input v-model="formData.companyId" style="display: none"></el-input>
          <el-input :value="formData.companyName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="formData.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="项目地址" prop="address">
          <el-input v-model="formData.address"></el-input>
        </el-form-item>
        <el-form-item label="项目负责人" prop="principal">
          <el-input v-model="formData.principal"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input v-model="formData.tel"></el-input>
        </el-form-item>
        <el-form-item label="项目人数" prop="numberOfPeople">
          <el-input v-model="formData.numberOfPeople"></el-input>
        </el-form-item>
        <el-form-item label="项目进度" prop="progress">
          <el-input v-model="formData.progress"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible=false">关闭</el-button>
        <el-button size="small" type="primary" :loading="saveLoading" @click="save">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import {EventBus} from "@/views/consoleDevice/event-bus";
import {mustFill,mustNumber} from "@/validation/valid";

export default {
  name: "project",
  props:["rowData"],
  data(){
    return {
      comId: JSON.parse(window.localStorage.getItem('userInfo')).comId,
      dialogVisible:false,
      dialogTitle:"",
      saveLoading:false,
      treeSelectId:'',
      treeSelectName:'',
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        orders:[],
        hitCount:false,
        searchCount:true,
        pages:0
      },
      query:{
        size:this.$pageSize,
        current: 1,
        companyId:0
      },
      formData:{
        id:"",
        companyId:'',
        name:'',
        address:'',
        tel:'',
        principal:'',
        numberOfPeople: '',
        progress:''
      },
      selectList:[{name:'',id:''}],
      treeData:[],
      formRule:{
        name:[mustFill],
        address:[mustFill],
        principal:[mustFill],
        tel:[mustFill],
        numberOfPeople:[mustFill,mustNumber],
        progress:[mustFill,mustNumber]
      }
    }
  },
  mounted() {
    this.listTree()
  },
  created(){
    this.formData={
      id:"",
      companyId:'',
      name:'',
      address:'',
      tel:'',
      principal:'',
      numberOfPeople: '',
      progress:''
    }
  },
  methods:{
    add(){
      //console.log("add")
      if(this.treeSelectId===""){
        this.$alert('请先选择公司','提示',{
            confirmButtonText:'确定'
        })
      }else {
        this.dialogVisible=true
        this.saveLoading = false;
        this.dialogTitle='新增项目'
        this.formData.companyId=this.treeSelectId
        this.formData.companyName=this.treeSelectName;
      }
    },
    edit(data){
      //console.log("edit")
      this.formData=Object.assign({},data);
      /*this.formData.companyId=this.treeSelectId*/
      this.formData.companyName=this.treeSelectName;
      this.dialogVisible=true
      this.dialogTitle="修改项目"
    },
    del(id){
      //console.log("del")
      let _this=this;
      this.$confirm("确定删除该项目么？","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消'
      }).then(function (){
        _this.$axios.post("/sys/project/del/"+id).then(function (response){
          if(response.data.result==="success"){
            _this.$message({message:'删除成功',type:"success"})
            _this.list()
          }
        })
      }).catch(function (){
        _this.$message({message:'取消删除',type:"info"})
      })
    },
    save(){
      //console.log("save");
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let _this=this;
          this.saveLoading=true;
          this.$axios.post("/sys/project/save", this.formData).then(function (response) {
            if (response.data.result === "success") {
              _this.dialogVisible = false;
              _this.saveLoading = false;
              _this.list()
              _this.$message({ message: '保存成功',type: 'success'});
              _this.$refs['formData'].resetFields();
            } else {
              _this.$message({
                message: '保存失败',
                type: 'error'
              });
            }
          }).catch(function (err) {
            console.log(err);
          })
        } else {
          this.$message({
            message: "请补全项目信息",
            type: "warning",
          });
        }
      });
    },
    listTree(){
      let _this=this;
      this.$axios.post('/sys/company/listTree').then(function (response){
        _this.treeData=response.data;
      }).catch(function (err){
        console.log(err);
      })
    },
    list(){
      let _this=this;
      this.$axios.post('/sys/project/list',this.query).then(function (response){
        _this.page=response.data;
      }).catch(function (err){
        console.log(err);
      })
    },
    handleNodeClick(data){
      this.query.companyId=data.id;
      this.treeSelectId=data.id;
      this.treeSelectName=data.name;
      this.list();
    },
    handleCurrentChange(value){
      console.log(value);
    },
    handleSizeChange(value){
      console.log(value);
    },
    dialogOpen(){
      let _this=this;
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        comId: userInfo.comId
      }
      this.$axios.post("/sys/company/listIdName",data).then(function (response){
        _this.selectList=response.data;
      }).catch(function (err){
        console.log(err);
      })
    },
    dialogClosed() {
      this.saveLoading=false;
      Object.assign(this.$data.formData,this.$options.data().formData);
    },
    handleRefresh(){
      this.list();
    }
  }
}
</script>

<style scoped>
.elaside{
  padding-left: 0px;
  padding-top: 10px;
  border-right:1px dashed #b6b6b6;
  height: 88vh;
}
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
</style>
