<!--
升降机动画
-->
<template>
  <div class="visual_conBot">
    <img class="visual_conBot_l" src="../../views/dataV/static/images/ksh42.png">
    <img class="visual_conBot_2" src="../../views/dataV/static/images/ksh43.png">
    <img class="visual_conBot_3" src="../../views/dataV/static/images/ksh44.png">
    <img class="visual_conBot_4" src="../../views/dataV/static/images/ksh45.png">

    <div :style="display1">
      <div class="visual_chart_text" style="width: 100%">
        <div style="float: left;"><h1>{{dataTime}}</h1></div>
        <div style="float: right; padding-right: 30px;">
          <div style="float: left; margin-right: 10px;">
            <el-form :model="formData" label-position="left" label-width="120px">
              <el-select v-model="formData.vd" style="width: 193px;" @change="videoDataChange()">
                <el-option v-for="item in videoData" :key="item.id" :label="item.spName" :value="item.id">
                </el-option>
              </el-select>
            </el-form>
          </div>
          <div style="float: left;">
            <img src="../../assets/svg/close.svg" @click="showVideo(1)" height="32" width="32" v-show="isShowPic"/>
          </div>
        </div>
      </div>
      <show-video :video-data="oneVideoData"></show-video>
    </div>

    <div :style="display2">
      <div class="visual_chart_text" style="width: 97%">
        <div style="float: left;"><h1>{{dataTime}}</h1></div>
        <div style="float: right; padding-right: 30px; height: 32px; width: 32px;">
          <img src="../../assets/svg/video.svg" @click="showVideo(2)" height="32" width="32" v-show="isShowPic"/>
        </div>
      </div>
      <div id="elevatorBg">
        <div id="l-elevator"></div>
        <!-- <div id="r-elevator"></div>-->
      </div>
    </div>

  </div>
</template>

<script>
import ShowVideo from "../showVideo";

import $ from "jquery";

export default {
  name: "cartoon",
  components:{
    ShowVideo
  },
  props: ["heightPercent","dataTime","macInfo"],
  data(){
    return {
      display1:"display:none",
      display2:"",
      isShowPic:false,
      videoData:{},
      oneVideoData:{},
      formData: {
        vd:'',
      },
      height: 0
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    heightPercent: function(newVal,oldVal){
      this.height = newVal;
      this.changeElevatorData();
    }
  },
  mounted() {
    this.getVideoList();
  },
  methods: {
    changeElevatorData(){
      let lHeight = $("#l-elevator")
      let l = Math.ceil(270 - 2.7*this.height)
      if(l<0){
        l = 0
      }else if(l>270){
        l=270
      }
      lHeight.css({top: `${l}px`})
    },
    getVideoList: function (){
      let _this = this;
      console.log(this.macInfo)
      this.$axios.post("/machine/video/list",{ID:this.macInfo.ID}).then(data => {
        if(data.data.code==="100") {
          var list = data.data.info.list;
          if(list.length>0){
            _this.videoData = list;//下拉框
            _this.oneVideoData = list[0];
            _this.formData.vd = list[0].id;
            _this.isShowPic = true;
          }
        } else {
          _this.isShowPic = false;
        }
      })
    },
    showVideo(val){
      if(val===1){
        this.display1 = "display:none";
        this.display2 = "";
      }
      if(val===2){
        this.display1 = "";
        this.display2 = "display:none";
      }
    },
    videoDataChange(){
      for (var i = 0; i < this.videoData.length; i++) {
        if(this.videoData[i].id===this.formData.vd){
          this.oneVideoData = this.videoData[i];
        }
      }
    }
  }
}
</script>
<style scoped>
#elevatorBg{
  float: left;
  width: 168px;
  height: 420px;
  background-image: url("../../assets/images/elevator.png");
  background-repeat: no-repeat;
  /*margin-top: 20px;*/
  margin-left: 350px;
  background-size: 100% 100%;
  position: relative;
}
#l-elevator{
  width: 72px;
  height: 90px;
  background-image: url("../../assets/images/left-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 1px;
  top: 270px;
  transition: 1s;
}
#r-elevator{
  width: 72px;
  height: 90px;
  background-image: url("../../assets/images/right-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 1px;
  top: 270px;
  transition: 1s;
}
</style>
