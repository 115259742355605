<!--
  名称：查询设备警报数据
  描述：按日期和设备号查询，可以导出excel报表 ,动态加载列
-->
<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['警报管理']">
    </crumbs-bar>
    <el-container>
      <!--部门数据-->
      <el-aside class="elaside">
        <el-tree highlight-current lazy
            :load="loadNode"
            :props="defaultProps"
            @node-click="handleNodeClick"
        />
      </el-aside>
      <el-main style="padding: 0 0 0 5px;">
        <el-form :inline="true" :model="pageQuery" :rules="rules" ref="pageQuery" style="margin-top: 10px;">
          <el-form-item style="margin-left: 5px;" required label="设备编号" prop="deviceId">
            <el-input v-model="pageQuery.deviceId" readonly placeholder="设备编号"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="demonstration">日期</span>
            <el-date-picker
              style="margin-right: 30px;"
              value-format="yyyy-MM-dd HH:mm:ss"
              label="时间段"
              v-model="pageQuery.datetime"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button v-loading.body="historyListLoading" type="primary" @click="queryClick($event)">查询</el-button>
          </el-form-item>
<!--          <el-form-item>
            <el-button v-loading.body="listLoading" type="primary" @click="excleExport($event)">
              <span v-show="export_percentage===-1">导出Excel</span>
              <span v-show="export_percentage!==-1">导出中:{{ export_percentage }}%</span>
            </el-button>
          </el-form-item>-->
        </el-form>
        <el-table :data="page.records" v-loading.body="listLoading" element-loading-text="拼命加载中" border fit
                  highlight-current-row>
          <el-table-column align="center" label="序号" width="100px">
            <template slot-scope="scope">
              <span v-text="getIndex(scope.$index)"> </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="alarmValue" label="设备编号" width="200px">
            {{this.pageQuery.deviceId}}
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="开始时间" width="250px"></el-table-column>
          <el-table-column align="center" prop="endTime" label="结束时间" width="250px"></el-table-column>
          <el-table-column align="center" prop="alarmValue" label="警报类型">
            <template slot-scope="scope">
                {{alarmTypeName(scope.row.alarmType)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="alarmVal" label="警报值" width="200px"></el-table-column>
        </el-table>
        <el-pagination
            :total="page.total"
          :current-page="page.current"
          :page-size="page.size"
          :page-sizes="this.$pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";

export default {
  name: "alarmData",
  data() {
    return {
      consoleDeviceStore,
      export_percentage: -1,
      rules: {
        code: [
          {required: true, message: '请选择一个设备', trigger: 'blur'}
        ]
      },//查询表单验证
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },//日期控件
      readonly: true,
      historyListLoading: false,
      listLoading: false,//数据加载等待动画
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        orders: [],
        hitCount:false,
        searchCount: true,
        pages:0
      },
      pageQuery:{
        datetime: [],
        deviceId: undefined,
        size:this.$pageSize,
        current: 1,
      },
      defaultProps: {
        id:'id',
        label:'name',
        isLeaf:'leaf',
        type:"type"
      },
      url1:'/sys/company/listIdName',
      url2:'/sys/project/listByCompany',
      url3:'/sys/area/listByProject',
      url4:'/sys/device/listByAreaId',
      devType: 0
    }
  },
  computed:{
    alarmTypeName(){
      return function (type) {
        if(this.devType==100||this.devType==103) {
          switch (type) {
            case 1001:
              return "高度预警"//高度报警
            case 1002:
              return "高度报警"//高度报警
            case 1101:
              return "近端限位预警"//幅度报警
            case 1102:
              return "近端限位报警"//幅度报警
            case 1103:
              return "远端限位预警"//幅度报警
            case 1104:
              return "远端限位报警"//幅度报警
            case 1201:
              return "左限位预警"//回转报警
            case 1202:
              return "左限位报警"//回转报警
            case 1203:
              return "右限位预警"//回转报警
            case 1204:
              return "右限位报警"//回转报警
            case 1301:
              return "载重预警"//载重报警
            case 1302:
              return "载重报警"//载重报警
            case 1303:
              return "违章"//载重报警
            case 1401:
              return "风速预警"//风速报警
            case 1402:
              return "风速报警"//风速报警
            case 1501:
              return "倾斜预警"//倾斜报警
            case 1502:
              return "倾斜报警"//倾斜报警
            case 1601:
              return "前限位预警"//干涉上
            case 1602:
              return "前限位报警"//干涉上
            case 1701:
              return "后限位预警"//干涉下
            case 1702:
              return "后限位报警"//干涉下
            case 1801:
              return "左限位预警"//干涉左
            case 1802:
              return "左限位报警"//干涉左
            case 1901:
              return "右限位预警"//干涉右
            case 1902:
              return "右限位报警"//干涉右
            case 2001:
              return "前限位预警"//碰撞上
            case 2002:
              return "前限位报警"//碰撞上
            case 2101:
              return "后限位预警"//碰撞下
            case 2102:
              return "后限位报警"//碰撞下
            case 2201:
              return "左限位预警" //碰撞左
            case 2202:
              return "左限位报警" //碰撞左
            case 2301:
              return "右限位预警" //碰撞右
            case 2302:
              return "右限位报警" //碰撞右
          }
        }
        if(this.devType==101) {
          switch (type) {
            case 1001:
              return "重量预警"//重量预警报警
            case 1002:
              return "重量报警"//重量预警报警
            case 1101:
              return "高度预警"//高度报警
            case 1102:
              return "高度报警"//高度报警
            case 1201:
              return "速度预警"//速度报警
            case 1202:
              return "速度报警"//速度报警
            case 1301:
              return "人数报警"//升降机警报类型
            case 1401:
              return "倾斜预警"//倾斜报警
            case 1402:
              return "倾斜报警"//倾斜报警
            case 1501:
              return "未认证"//身份认证
            case 1601:
              return "门未关"//前门报警
            case 1701:
              return "门未关"//后门报警
            case 1801:
              return "门锁异常"//门锁异常报警
          }
        }
        if(this.devType==102) {
          switch (type) {
            case 1001:
              return "超载报警"//超载
            case 1002:
              return "倾斜报警"//倾斜
            case 1003:
              return "超载倾斜报警"//超载倾斜
          }
        }
      }
    }
  },
  methods: {
    /*异步加载节点*/
    loadNode(node,resolve){
      switch (node.level){
        case 0:
          var userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
          var data= {
            comId: userInfo.comId
          }
          this.$axios.post(this.url1,data).then((response)=>{
            resolve(response.data.length==0?[]:response.data)
          });
          break
        case 1:
          this.resolveChildren(this.url2+'/'+node.data.id,resolve,false)
          break
        case 2:
          this.resolveChildren(this.url3+'/'+node.data.id,resolve,false)
          break
        case 3:
          this.resolveChildren(this.url4+'/'+node.data.id,resolve,true)
          break
        default:
          break
      }
    },
    resolveChildren(url,resolve,isLeaf){
      this.$axios.post(url).then((response)=>{
        if (isLeaf){
          response.data.forEach((item)=>{
            item.leaf=true
          })
        }
        resolve(response.data.length==0?[]:response.data)
      })
    },
    // 节点单击事件
    handleNodeClick(data, node) {
      let level = node.level;
      //1级为公司，2为项目，3为区域
      if (level === 4) {
        //更新store中的数据
        let org={
          companyId:node.parent.parent.parent.data.id,
          projectId:node.parent.parent.data.id,
          areaId:node.parent.data.id,
          companyName:node.parent.parent.parent.data.name,
          projectName:node.parent.parent.data.name,
          areaName:node.parent.data.name
        }
        consoleDeviceStore.setOrg(org)

        this.devType = data.type;//设备类型赋值
        this.getList(data.id)
      }
    },
    queryClick(evt) {
      let target = evt.target
      if (target.nodeName === "SPAN") {
        target = evt.target.parentNode
      }
      target.blur()
      this.pageQuery.size=13;
      this.pageQuery.current=1;
      this.getList(this.pageQuery.deviceId)
    },
    excleExport() {
    },
    getList(deviceId) {
      this.pageQuery.deviceId = deviceId;
      this.$refs['pageQuery'].validate((valid) => {
        if (valid) {
          this.historyListLoading = true;
          this.pageQuery.comId = consoleDeviceStore.state.org.companyId;
          this.pageQuery.proId = consoleDeviceStore.state.org.projectId;
          this.pageQuery.type = this.devType
          this.$axios.post("/sys/alarm/getAlarmListByCode",this.pageQuery).then((response) => {
            this.historyListLoading = false;
            this.page=response.data
          })
          this.historyListLoading = false;
        } else {
          return false;
        }
      })
    },
    handleSizeChange(size) {
      //改变每页数量
      this.pageQuery.size=size;
      this.getList(this.pageQuery.deviceId);
    },
    handleCurrentChange(current) {
      //改变页码
      this.pageQuery.current=current;
      this.getList(this.pageQuery.deviceId);
    },
    getIndex(index) {
      index = (index + 1) + (this.page.current - 1) * this.page.size
      return index
    },
    handleRefresh(){
      this.getList(this.pageQuery.deviceId);
    }
  },
}
</script>

<style scoped>
.elaside{
  padding-left: 0px;
  padding-top: 10px;
  border-right:1px dashed #b6b6b6;
  height: 88vh;
}
.demonstration {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
