/**
 * 表单验证公共字段
 */
// 输入框必填
var mustFill = { required: true, message: '该字段为必填项', trigger: 'blur' };
// 请输入正确的姓名，公司名称
var musName = {
  validator:(rule,value,callback)=>{
    var reg = /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/;
    if(!reg.test(value)){
      return callback(new Error('只能输入汉字'))
    }else{
      callback()
    }
  },
  trigger:'blur'
}
var  musZimu= {
  validator:(rule,value,callback)=>{
    var reg = /[^\d.]/g;
    if(!reg.test(value)){
      return callback(new Error('只能字符和数字'))
    }else{
      callback()
    }
  },
  trigger:'blur'
}
//正浮点数
var  musDouble= {
  validator:(rule,value,callback)=>{
    var reg = /^[1-9]\d*\.\d*|0\.\d*[1-9]\d*|0?\.0+|0$/g;
    if(!reg.test(value)){
      return callback(new Error('只能录入浮点数！'))
    }else{
      callback()
    }
  },
  trigger:'blur'
}
//请输入正确的身份证号码
var musCart = {
  validator:(rule,value,callback)=>{
    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if(!reg.test(value)){
      return callback(new Error('请输入正确的身份证号'))
    }else{
      callback()
    }
  },
  trigger: 'blur'
}
//请输入正确的邮箱
var musEmail ={
  validator:(rule,value,callback)=>{
    var reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    if(!reg.test(value)){
      return callback(new Error('请输入正确的邮箱格式'))
    }else{
      callback()
    }
  },
  trigger: 'blur'
}
//请输入正确的手机号码
var musPhone ={
  validator:(rule,value,callback)=>{
    var reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
    if(!reg.test(value)){
      return callback(new Error('请输入正确的手机号'))
    }else{
      callback()
    }
  },
  trigger: 'blur'
}
//请输入正确的身份证号长度
var mustCartlength = {
  required:true,
  message:'身份证长度在18位',
  trigger:'blur'
}

// 输入的值必须时整形
var mustNumber = {
  validator:(rule, value, callback) => {
    var reg = /^[0-9]*$/;
    if (!reg.test(value)) {
      return callback(new Error('输入内容只能为数值'));
    } else {
      callback();
    }
  },
  trigger: 'blur'
}

// 输入的值必须时整形范围
var mustNumberRange = {
  validator:(rule, value, callback) => {
    console.log(rule.field);
    var reg = "",msg = "";
    if(rule.field=="deviceNo1"){
      reg = /^[1-9]\d{0,3}$/;
      msg = "取值范围1到9999";
    }
    if(rule.field=="towerBodyHeight1"){
      reg = /^[1-9]\d{0,4}$/;
      msg = "取值范围1到99999";
    }
    if (!reg.test(value)) {
      return callback(new Error(msg));
    } else {
      callback();
    }
  },
  trigger: 'blur'
}

//正两位浮点数
var  musTwoDouble= {
  validator:(rule,value,callback)=>{
    var reg = /^[1-9]\d*\.\d*|0\.\d*[1-9]\d*|0?\.0+|0$/g;
    if(!reg.test(value)){
      return callback(new Error('只能录入浮点数！'))
    }else{
      callback()
    }
  },
  trigger:'blur'
}

function addImgURlArr(arr){
  arr= arr.map((item)=>{
    if(item.imgUrl){
      if(item.imgUrl.indexOf('http')== -1){
        item.imgUrl = baseURL + item.imgUrl
      }
    }
    return item
  })
  return arr
}
let baseURL = ""
function addImgUrl(str){
	if(str.indexOf('http') == -1){
		str = baseURL + str
	}
	return str
}
export {
  mustFill,
  mustNumber,
  musCart,
  mustCartlength,
  musName,
  musEmail,
  musPhone,
  addImgURlArr,
  addImgUrl,
  musZimu,
  musDouble,
  mustNumberRange,
  musTwoDouble
}
