<template>
  <el-dialog
      title="数据"
      :visible.sync="myVisible"
      width="70%"
      center>
      <span>
        吊钩可视化数据
      </span>
    <span slot="footer" class="dialog-footer">
    <el-button @click="myVisible = false">取 消</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "liftingHookViewDialog",
  props:{
    dialogVisible:{
      type:Boolean,
      default:false
    },
    deviceId:String
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit("update:dialogVisible",val)
      }
    }
  }
}
</script>

<style scoped>

</style>
