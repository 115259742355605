<template>
  <div class="centerT layui-col-xs12">
    <div class="lItem">
      <div class="layui-col-xs3 html" v-for="(item,index) in statisticsItem"
           :key="index">
        <p class="xmVal xmVal1">{{ statisticsValue[index] }}</p>
        <img class="itemBg" src="../../../assets/pro/img/itemBg.png">
        <p class="xmLabel">{{item}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "proStatics",
  data(){
    return{
      statisticsItem:['项目人数','在建项目','已完成项目','监测设备总数','在线设备总数','离线设备总数'],
      statisticsValue:['--','--','--','--','--','--'],
    }
  },
  created() {
    this.loadStatics();
  },
  methods:{
    loadStatics(){
      this.$axios.post('/sys/project/projectStatics').then((response)=>{
        this.statisticsValue=response.data.info
      })
    }
  }
}
</script>
<style scoped>
.layui-col-xs12{
  height: 100%;  border: 0px solid #0056A1; border-radius: 4px;
}
.centerT .lItem{
  background: transparent; border: 0;
}
.centerT .lItem .layui-col-xs3{
  width: 16.5%; height: 100%; text-align: center;
  float: left;
}
.centerT .itemBg{
  width: calc( 100% - 24px ); bottom: 15px; left: 12px; height: 25px;
}
.centerT .xmVal{
  font-size: 30px; font-family: myFont;
  background: linear-gradient(0deg, #59C8FF 0%, #FAFEFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.centerT .xmLabel{
  font-size: 16px;
}
.html{
  cursor: pointer;
}
</style>
