<template>
  <div>
    <div class="header clearfix">
      <span>{{title}}</span>
      <div class="my-radio-group">
        <el-radio-group @change="onCountChange" size="small" v-model="count">
          <el-radio-button label="7">近7日</el-radio-button>
          <el-radio-button label="15">近15日</el-radio-button>
          <el-radio-button label="30">近30日</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div ref="chart" class="body" >

    </div>
  </div>
</template>

<script>
export default {
  name: "baseDayCard",
  props:{
    title:String,
    deviceId:String,
    deviceType:String,
    url:String
  },
  data(){
    return{
      count:7,
      xAxisData:[],
      seriesData:[]
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    drawChart() {
      let myChart=new this.$echarts.init(this.$refs.chart);
      myChart.setOption({
        tooltip:{},
        grid: {
          top: '8%',
          left: '5%',
          right: '0%',
          bottom: '1%',
          containLabel: true
        },
        xAxis:{
          data:this.xAxisData,
          //  改变x轴字体颜色和大小
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize:'14'
            },
          }
        },
        yAxis:{
          //  改变y轴字体颜色和大小
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize:'14'
            },
          }
        },
        series:[{
          name:this.title,
          type:'bar',
          data:this.seriesData
        }]
      })
    },
    loadData(){
      this.$axios.post(`${this.url}/${this.deviceId}/${this.count}/${this.deviceType}`).then(response=>{
        this.xAxisData=response.data.xData
        this.seriesData=response.data.yData
        //console.log(this.xAxisData)
        //console.log(this.seriesData)
        this.drawChart()
      }).catch(err=>{
        console.log(err)
      })
    },
    onCountChange() {
      this.loadData()
    }
  }
}
</script>

<style scoped>
.header{
  background-color: #e5f5f5;
  padding-left:10px;
  line-height: 30px;
}
.body{
  width:98%;
  height:100%;
}
.my-radio-group{
  float: right;
}
</style>
