<template>
<div>
  <el-row style="margin: 0 5px 0 5px;">
    <el-col>
      <el-form :inline="true" :model="pageQuery" ref="pageQuery" style="margin-top: 10px;">
        <el-form-item style="margin-left: 5px;" label="设备编号">
          <el-input v-model="pageQuery.devCode" placeholder="请输入设备编号"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 5px;" label="设备名称">
          <el-input v-model="pageQuery.devName" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  <el-row style="margin: 0 5px 0 5px;">
    <el-col>
      <el-table :data="page.records">
        <el-table-column type="index" width="80px" label="序号" fixed="left"></el-table-column>
        <el-table-column prop="code" label="设备编号" width="150px" fixed="left"></el-table-column>
        <el-table-column prop="name" label="设备名" width="200px" fixed="left"></el-table-column>
        <el-table-column label="设备类型" width="120px">
          <template slot-scope="scope">
            {{macType(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column label="设备状态" width="120px">
          <template slot-scope="scope">
            <div v-if="scope.row.type===100">
              <router-link  :to="{path:'/towerState',query:{deviceId:scope.row.code}}" target="_blank" style="color:#409eff">
                {{macStatus(scope.row.status)}}
              </router-link>
            </div>
            <div v-if="scope.row.type===101">
              <router-link  :to="{path:'/elevatorState',query:{deviceId:scope.row.code}}" target="_blank" style="color:#409eff">
                {{macStatus(scope.row.status)}}
              </router-link>
            </div>
            <div v-if="scope.row.type===102" style="color:#409eff">
              <router-link  :to="{path:'/unloadingPlatState',query:{deviceId:scope.row.code}}" target="_blank" style="color:#409eff">
                {{macStatus(scope.row.status)}}
              </router-link>
            </div>
            <div v-if="scope.row.type===103" style="color:#409eff">
              <router-link  :to="{path:'/allInOneState',query:{deviceId:scope.row.code}}" target="_blank" style="color:#409eff">
                {{macStatus(scope.row.status)}}
              </router-link>
            </div>
            <div v-if="scope.row.type===104" style="color:#409eff">
              {{macStatus(scope.row.status)}}
            </div>
            <div v-if="scope.row.type===105">
              {{macStatus105(scope.row.status)}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="binderCard" label="通讯关联" width="200px"></el-table-column>
        <el-table-column prop="comName" label="公司名称" width="210px"></el-table-column>
        <el-table-column prop="proName" label="项目名称" width="250px"></el-table-column>
        <el-table-column label="报警推送频率" width="120px">
          <template slot-scope="scope">
            {{convertStr(scope.row.frequency)}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220px"  fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="edit(scope.row)" v-if="hasPerm('device:update')">修改</el-button>
            <el-button size="mini" @click="init(scope.row)" v-if="scope.row.isMemory==1&&hasPerm('device:init')">初始化</el-button>
            <el-button size="mini" @click="initApi(scope.row)" v-else-if="scope.row.isMemory==0&&hasPerm('device:init')">初始化</el-button>
            <el-button size="mini" @click="updateSoftWare(scope.row)" v-if="hasPerm('device:soft')">升级</el-button>
            <!--<el-button size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
  <el-pagination
      :current-page="this.page.current"
      :page-sizes="this.$pageSizes"
      :page-size="this.page.size"
      layout="total,sizes,prev,pager,next,jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      style="text-align: left;margin-top: 10px">
  </el-pagination>
</div>
</template>

<script>
import {EventBus} from "@/views/consoleDevice/event-bus";
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
export default {
  name: "myTable",
  data(){
    return{
      consoleDeviceStore,
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        orders: [],
        hitCount:false,
        searchCount: true,
        pages:0
      },
      pageQuery:{
        devCode:'',
        devName:'',
        size:this.$pageSize,
        current: 1,
      }
    }
  },
  created() {
    EventBus.$on('loadTable',()=>{
        this.loadTable();
    });
    EventBus.$on('refresh',()=>{
      this.loadTable();
    });
    //this.loadTable();
  },
  computed:{
    macStatus(){
      return function (status) {
        switch (status) {
          case 0:
            return "停止"
          case 1:
            return "运行"
        }
      }
    },
    macStatus105(){
      return function (status) {
        switch (status) {
          case 0:
            return "离线"
          case 1:
            return "在线"
        }
      }
    },
    macType(){
      return function (type) {
        switch (type) {
          case 100:
            return "塔机"
          case 101:
            return "升降机"
          case 102:
            return "卸料平台"
          case 103:
            return "一体化"
          case 104:
            return "吊钩可视化"
          case 105:
            return "人脸识别"
        }
      }
    },
    convertStr(){
      return function (type){
        switch (type) {
          case 0:
            return "实时"
          case 1:
            return "1分钟"
          case 5:
            return "5分钟"
          case 10:
            return "10分钟"
          case 30:
            return "30分钟"
          case 60:
            return "1小时"
          case 120:
            return "2小时"
          case 360:
            return "6小时"
          case 720:
            return "12小时"
        }
      }
    }
  },
  methods:{
    edit(row){
      EventBus.$emit('edit',row)
    },
    init(row){
      let data={
        devId:row.code
      }
      this.$axios.post(`${this.COMMON.houseCollUrl}/dev/initDevByCode`,data).then((response)=>{
        this.$message({
          type:'success',
          message:response.data.msg
        })
      });
    },
    initApi(row){
      let data={
        devId:row.code
      }
      this.$axios.post(`/sys/device/initOneApiDevice`,data).then((response)=>{
        this.$message({
          type:'success',
          message:response.data.msg
        })
      });
    },
    updateSoftWare(row){
      let data={
        devCode:row.code,
        devType:row.type
      }
      this.$axios.post(`${this.COMMON.houseCollUrl}/dev/softwareUpgrading`,data).then((response)=>{
        this.$message({
          type:'success',
          message:response.data.msg
        })
      });
    },
    del(id){
      this.$confirm('确定要删除此设备么？','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消'
      }).then(()=>{
        this.$axios.post("/sys/device/del/"+id).then((response)=>{
          if (response.data.result==="success"){
            this.loadTable()
            this.$message({
              type:'success',
              message:'删除成功'
            })
            this.loadTable();
          }else {
            this.$message({
              type:'error',
              message:'删除失败'
            })
          }
        })
      }).catch(()=>{
        this.$message({
          type:'info',
          message:'取消删除'
        })
      })
    },
    handleCurrentChange(value){
      //console.log("当前页："+value)
      this.pageQuery.current=value;
      this.loadTable();
    },
    handleSizeChange(value){
      //console.log("每页大小变更："+value)
      this.pageQuery.size=value;
      this.pageQuery.current=1
      this.loadTable()
    },
    loadTable(){
      this.pageQuery.areaId = consoleDeviceStore.state.org.areaId;
      this.$axios.post(`/sys/device/list/area`,this.pageQuery).then((response)=>{
          this.page=response.data
      });
    },
    /**导出电工列表excel */
    exportFile() {
      let href = "";
      window.location.href=href;
    },
    /**刷新表格数据 */
    handleRefresh(){
      this.currPage = 1
      this.loadTable();
    },
    queryClick(){
      this.loadTable();
    }
  }
}
</script>

<style scoped>

</style>
