<!--
右一
-->
<template>
  <div class="visual_box">
    <div class="visual_title">
      <span>状态4</span>
      <img src="../../views/dataV/static/images/ksh33.png">
    </div>
    <div class="visual_open"  v-for="(item,i) in this.rightOneState" :key="i">
      <h5>{{item.name}}</h5>
      <span v-if="item.value=='正常'"><img src="../../assets/images/alarm_0.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='近端限位预警'"><img src="../../assets/images/alarm_1.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='近端限位报警'"><img src="../../assets/images/alarm_2.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='远端限位预警'"><img src="../../assets/images/alarm_1.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='远端限位报警'"><img src="../../assets/images/alarm_2.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='左限位预警'"><img src="../../assets/images/alarm_1.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='左限位报警'"><img src="../../assets/images/alarm_2.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='右限位预警'"><img src="../../assets/images/alarm_1.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='右限位报警'"><img src="../../assets/images/alarm_2.png" width="25" height="25" class="svg"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "rightOneState",
  props:["rightOneState"],
}
</script>
