<template>
  <el-dialog :visible.sync="visible"
             :title="dialogTitle"
             :close-on-click-modal="false"
             :show-close="false"
             class="status_change"
             @closed="handleClose"
             width="600px" left>
    <el-form ref="formData" :model="formData" label-suffix="：" label-width="120px" :rules="formRule">
      <el-form-item label="设备编号" prop="code">
        <el-input v-model="formData.code" disabled></el-input>
      </el-form-item>
      <el-form-item label="设备类型" prop="type">
        <el-select v-model="formData.type" style="width: 440px;" :disabled="this.dialogTitle=='修改设备'?true:false">
          <el-option v-for="(item,index) in deviceTypes"
              :key="index" :label="item.label" :value="item.value" >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="是否支持远程">
        <el-radio v-model="formData.isRemote" label="1">支持远程</el-radio>
        <el-radio v-model="formData.isRemote" label="0">不支持远程</el-radio>
      </el-form-item>
      <el-form-item label="设备序列号">
        <el-input v-model="formData.serialNumber" placeholder="设备SN序列号"></el-input>
      </el-form-item>
      <el-form-item label="通讯关联">
        <el-input v-model="formData.binderCard" placeholder="通讯卡号或自研DTU MEI"></el-input>
      </el-form-item>
      <el-form-item label="报警推送频率" prop="frequency">
        <el-select v-model="formData.frequency" style="width: 380px">
          <el-option
              v-for="item in frequencys"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="经度" prop="ing" v-show="false">
        <el-input v-model="formData.ing" placeholder="默认平台所在经度"></el-input>
      </el-form-item>
      <el-form-item label="纬度" prop="iat" v-show="false">
        <el-input v-model="formData.iat" placeholder="默认平台所在续度"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible=false">关闭</el-button>
      <el-button size="small" @click="save" :loading="saveLoading" type="primary">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {EventBus} from "@/views/consoleDevice/event-bus";
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
import {mustFill} from "@/validation/valid";

export default {
  name: "dataDialog",
  data(){
    return{
      consoleDeviceStore,
      visible:false,
      saveLoading:false,
      dialogTitle:'',
      deviceTypes:[
        {value: 100,label: '塔机'},
        {value: 101,label: '升降机'} ,
        {value: 102,label: '卸料平台'},
        {value: 103,label: '一体机'},
        {value: 104,label: '吊钩可视化'},
        {value: 105,label: '人脸识别'},
      ],
      frequencys:[{id:0,name:"实时"},{id:1,name:"1分钟"},{id:5,name:"5分钟"},{id:10,name:"10分钟"},{id:30,name:"30分钟"},
        {id:60,name:"1小时"},{id:120,name:"2小时"},{id:360,name:"6小时"},{id:720,name:"12小时"}],
      formData:{
        id:'',
        name:'',
        type:'',
        companyId:consoleDeviceStore.state.org.companyId,
        projectId:consoleDeviceStore.state.org.projectId,
        areaId:consoleDeviceStore.state.org.areaId,
        code:'',
        frequencys:10,
        ing:'113.5997298359871',
        iat:'34.79686393218624',
        serialNumber:'',
        binderCard:'',
        isRemote:'1',
        isMemory:''
      },
      formRule:{
        type:[mustFill],
        name:[mustFill],
      }
    }
  },
  created() {
    EventBus.$on('add',(title)=>{
      this.formData.companyId=consoleDeviceStore.state.org.companyId
      this.formData.projectId=consoleDeviceStore.state.org.projectId
      this.formData.areaId=consoleDeviceStore.state.org.areaId
      this.visible=true
      this.dialogTitle=title
      //初始化设备编号
      this.$axios.post("/sys/device/createCode").then((response)=>{
        this.formData.code = response.data.devCode;
      }).catch(function (err){
        console.log(err)
      })
    })
    EventBus.$on('edit',(row)=>{
      this.formData=Object.assign({},row)
      this.visible=true
      this.dialogTitle="修改设备"
    })
  },
  methods:{
    save(){
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          this.formData.serialNumber=this.formData.serialNumber.trim();
          this.$axios.post("/sys/device/save", this.formData).then((response) => {
            if (response.data.result === 'success') {
              //初始设备信息到采集系统内存 后端解决创建iotdb 时序序列

              //初始设备信息到采集系统内存 后端解决创建iotdb 时序序列
              this.visible = false
              this.$message({message: '保存成功', type: 'success'})
              EventBus.$emit('loadTable')
            }
          }).catch(function (err) {
            console.log(err)
          })
        }else {
          this.$message({
            message: "请补全设备信息！",
            type: "warning",
          });
        }
      });
    },
    handleClose(){
      Object.assign(this.$data.formData,this.$options.data().formData)
      //画面关闭时，清空区域ID
      consoleDeviceStore.state.org.companyId = '';
      consoleDeviceStore.state.org.projectId = '';
      consoleDeviceStore.state.org.areaId = '';
    }
  }
}
</script>

<style>
  .status_change .el-dialog__header{
      border-bottom: 1px solid #ebebeb;
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
</style>
