<template>
  <div style="margin: 15px 0">
  <el-card class="layui-elevator">
    <div @click="chartsVisible=!chartsVisible" style="display: flex;justify-content: space-between;cursor:pointer">
      <div style="line-height: 32px; color: #ffffff;">
        <span>设备编号：</span>
        <span style="display: inline-block;width: 100px">{{ deviceId }}</span>
        <span>设备名称：</span>
        <span style="width: 250px;display: inline-block;">{{ deviceName }}</span>
        <span style="margin-left: 20px">在线状态：</span>
        <!-- <span>{{ onlineState==0?"离线":"在线" }}</span>-->
        <span v-if="onlineState==0"><img src="../../../assets/images/off.png" height="16" width="16"></span>
        <span v-if="onlineState==1"><img src="../../../assets/images/on.png" height="16" width="16"></span>
      </div>
      <div style="line-height: 32px; color: #ffffff">{{chartsVisible?"点击收起":"点击展开"}}</div>
      <div>
        <!-- <el-button size="small" type="primary" plain @click.stop="liftingHookViewVisible=true">吊钩可视化</el-button>-->
        <el-button size="small" type="primary" plain @click.stop="analogMonitoring">模拟监控</el-button>
        <el-button size="small" type="primary" plain @click.stop="workingCycleDialogVisible=true">吊装循环</el-button>
        <el-button size="small" type="primary" plain @click.stop="historyDataDialogVisible=true">历史数据</el-button>
        <el-button size="small" type="primary" plain @click.stop="identityAuthentication=true">身份认证历史查询</el-button>
      </div>
    </div>
    <el-collapse-transition>
      <div v-if="chartsVisible">
        <el-divider></el-divider>
        <div  class="flex-layout" >
          <base-card title="吊装重量" url="/sys/device/ext/elevator/charts1" :device-id="deviceId"></base-card>
          <base-day-card title="工作循环（次数）" url="/sys/device/ext/elevator/charts2" :device-id="deviceId"></base-day-card>
          <base-card title="载重百分比" url="/sys/device/ext/elevator/charts3" :device-id="deviceId"></base-card>
          <base-day-card title="违章（次数）" url="/sys/device/ext/elevator/charts4" :device-id="deviceId"></base-day-card>
        </div>
      </div>
    </el-collapse-transition>
  </el-card>
    <working-cycle-dialog  :dialog-visible.sync="workingCycleDialogVisible" :device-id="deviceId" :device-cycle-title="deviceCycleTitle"></working-cycle-dialog>
    <history-data-dialog :dialog-visible.sync="historyDataDialogVisible" :device-id="deviceId" :device-title="deviceTitle"></history-data-dialog>
    <identity-authentication :dialog-visible.sync="identityAuthentication" :device-id="deviceId"></identity-authentication>
    <lifting-hook-view-dialog :dialog-visible.sync="liftingHookViewVisible" :device-id="deviceId"></lifting-hook-view-dialog>
  </div>
</template>

<script>
import workingCycleDialog from "@/views/projectDevice/elevator/dialog/workingCycleDialog";
import historyDataDialog from "@/views/projectDevice/elevator/dialog/historyDataDialog";
import identityAuthentication from "@/views/projectDevice/elevator/dialog/identityAuthentication";
import LiftingHookViewDialog from "@/views/projectDevice/elevator/dialog/liftingHookViewDialog";
import baseCard from "@/views/projectDevice/card/baseCard";
import BaseDayCard from "@/views/projectDevice/card/baseDayCard";
export default {
  name: "aDevice",
  props:["deviceId","deviceName","deviceType","onlineState","deviceTitle","deviceCycleTitle"],
  components:{
    BaseDayCard,
    LiftingHookViewDialog,
    workingCycleDialog,
    historyDataDialog,
    identityAuthentication,
    baseCard
  },
  data(){
    return{
      chartsVisible:false,
      workingCycleDialogVisible:false,
      historyDataDialogVisible:false,
      identityAuthentication:false,
      liftingHookViewVisible:false,
    }
  },
  methods:{
    analogMonitoring(){
      let path='/elevatorState'
      let routeData=this.$router.resolve({
        path:path,
        query:{
          deviceId:this.deviceId
        }
      })
      window.open(routeData.href,'_blank')
    }
  }
}
</script>

<style scoped>

.flex-layout{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

}
.flex-layout >div{
  min-width: 800px;
  min-height: 200px;
  border: 1px solid #0056A1;
  margin-bottom: 10px;
}
.layui-elevator{
  height: 100%; background: rgba(0, 124, 249, 0.1);
  border: 1px solid #0056A1; border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
</style>
