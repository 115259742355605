<template>
  <el-dialog title="身份认证历史" :show-close="false" width="60%" :close-on-click-modal="false"
      :visible.sync="myVisible" @open="loadData" class="status_change" left>
    <span>
      <el-table height="400" border :data="tableData">
        <el-table-column type="index" label="序号" width="80"></el-table-column>
        <el-table-column label="设备编号" prop="deviceCode" width="200"></el-table-column>
        <el-table-column label="驾驶员姓名" prop="driverName"></el-table-column>
        <el-table-column label="驾驶员身份证" prop="driverIdcard" width="250"></el-table-column>
        <el-table-column label="认证时间" prop="authenticationTime" width="200"></el-table-column>
      </el-table>
    </span>
    <span slot="footer">
      <el-button @click="refresh()" size="small">刷新</el-button>
      <el-button @click="myVisible=false" size="small">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "identityAuthentication",
  props: {
    dialogVisible: {
      type:Boolean,
      default:false
    },
    deviceId:String,
    tableData:[]
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit('update:dialogVisible',val)
      }
    }
  },
  methods:{
    loadData(){
      this.$axios.post(`/sys/msgIdentityAuthentication/list/${this.deviceId}`).then((response)=>{
        this.tableData=response.data
      })
    },
    refresh(){
      this.loadTable();
    }
  }
  }

</script>

<style scoped>

</style>
