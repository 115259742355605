<template>
  <el-dialog
      title="视频播放" :show-close="false" width="31.5%"
      :visible.sync="myVisible" class="status_change" left>
    <div class="vid" id="videoSpan">

    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="getVideoList()" size="small">播放</el-button>
      <el-button @click="myVisible=false" size="small">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
//import EZUIKit from "../../utils/ezuikit"
import EZUIKit from 'ezuikit-js';

export default {
  name: "oppfDevVideoDialog",
  props:{
    deviceId:String,
    dialogVisible:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {}
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible;
      },
      set:function (val){
        this.$emit("update:dialogVisible",val);
      }
    }
  },
  methods:{
    getVideoList(){
      var param = {
        devCode :this.deviceId
      }
      this.$axios.post("/sys/video/oppfPlayHls",param).then((response) => {
        if (response.data.result === 'success') {
          var list = response.data.list1;
          var videoSpan = document.getElementById("videoSpan");
          var divHtml = '';
          for (var i = 0; i < list.length; i++) {
            divHtml += this.setDiv(list[i])
            videoSpan.innerHTML = divHtml;
          }
          for (var j = 0; j < list.length; j++) {
            new EZUIKit.EZUIPlayer('myPlayer' + list[j].id);
          }
        } else {
          this.$message({
            message:'视频播放地址获取失败，请查看摄像机序列号是否存在！',
            type:'error'
          });
        }
      });
    },
    setDiv(item){
      var div = '<video id="myPlayer'+item.id+'" autoplay muted src="' + item.hlsUrl + '"  controls playsInline webkit-playsinline width="550px" height="450px">'+'</video>'
      return div
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
.vid {
  position: relative;
  padding: 0px 0px 0px 0px;
  /*height: 50%;*/
}
#videoSpan{
  display: flex;
  /*justify-content: space-around;*/
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 5px 2px 5px;
  border:2px solid #9a6e3a;
}
</style>
