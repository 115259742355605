<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['用户管理']">
      <template slot="controls">
        <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline">新增</el-button>
      </template>
    </crumbs-bar>

    <el-row style="margin: 0 20px 0 10px;">
      <el-col>
        <el-form :inline="true" :model="pageQuery" ref="pageQuery" style="margin-top: 10px;">
          <el-form-item style="margin-left: 5px;" label="登录名称">
            <el-input v-model="pageQuery.userName" placeholder="请输入登录名称"></el-input>
          </el-form-item>
          <el-form-item style="margin-left: 5px;" label="昵称">
            <el-input v-model="pageQuery.nickname" placeholder="请输入昵称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="queryClick()">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row style="margin: 0 20px 0 10px;">
      <el-col>
        <el-table :data="page.records" stripe highlight-current-row>
          <el-table-column type="index" width="80" label="序号"></el-table-column>
          <el-table-column prop="username" label="登录名" width="150"></el-table-column>
          <el-table-column prop="comName" label="企业" width="200"></el-table-column>
          <el-table-column prop="nickname" label="昵称" width="150"></el-table-column>
          <el-table-column prop="roles.name"  label="角色">
            <template v-slot="scope">
              <div v-for="(item,index) in scope.row.roles" v-bind:key="index">
                {{item.name}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="登录审核">
            <template v-slot="scope">
              <div v-if="scope.row.isCheck===1" style="color:#0e6200">
                已审核
              </div>
              <div v-if="scope.row.isCheck===0" style="color:#fc0707">
                未审核
              </div>
            </template>
          </el-table-column>
          <el-table-column label="微信绑定">
            <template v-slot="scope">
              <div v-if="scope.row.openIdFlag===1">
                已绑定
              </div>
              <div v-if="scope.row.openIdFlag===0">
                未绑定
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="note" label="备注" width="300"></el-table-column>
          <el-table-column label="操作" width="210">
            <template   slot-scope="scope">
              <el-button type="success" @click="check(scope.row.id,0)" size="mini" v-if="scope.row.isCheck===1">审核</el-button>
              <el-button type="info" @click="check(scope.row.id,1)" size="mini" v-if="scope.row.isCheck===0">审核</el-button>
              <el-button type="primary" @click="edit(scope.row)" size="mini">修改</el-button>
              <el-button type="danger" @click="del(scope.row.id)" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-pagination style="margin: 20px 0 0 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page=page.current
        :page-size=page.size
        :page-sizes=this.$pageSizes
        layout="total,sizes,prev,pager,next,jumper"
        :total=page.total >
    </el-pagination>

    <form-dialog @saveSuccess="loadTable" :my-data="formData" :title="dialogTitle" :visible.sync="visible"></form-dialog>
  </div>
</template>

<script>
import formDialog from "./formDialog";

export default {
  name: "user",
  components:{
    formDialog
  },
  data(){
    return {
      dialogTitle:'',
      visible:false,
      formData:{},
      page:{
        records:[],
        current:0,
        size:0,
        total:0,
        pages:0
      },
      pageQuery:{
        userName:'',
        nickname:'',
        current:1,
        size:this.$pageSize
      }
    }
  },
  created() {
    this.loadTable()
  },
  methods:{
    handleSizeChange(val){
      this.pageQuery.size=val
      this.loadTable()
    },
    handleCurrentChange(val){
      this.pageQuery.current=val
      this.loadTable()
    },
    loadTable(){
      this.$axios.post('/sys/user/list',this.pageQuery).then((response)=>{
        this.page=response.data
      })
    },
    add(){
      this.dialogTitle='新增用户'
      this.formData={}
      this.visible=true
    },
    edit(data){
      //console.log(data)
      this.dialogTitle='编辑用户'
      this.formData.id = data.id
      this.formData.username = data.username
      this.formData.nickname = data.nickname
      let arr = new Array(3)
      arr[0] = data.cmpId
      arr[1] = data.proId
      arr[2] = data.posId
      this.formData.owner = arr;
      let arrRole = data.roles;//数组
      this.formData.roleId = arrRole[0].id
      //console.log(this.formData);
      this.visible=true
    },
    del(id){
      this.$confirm('确定删除该用户吗','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=>{
        this.$axios.post(`/sys/user/del/${id}`).then((response)=>{
          if (response.data.result==='success'){
            this.$message({message:'删除成功',type:'success'})
            this.loadTable()
          }else {
            this.$message({message:'删除失败',type:'error'})
          }
        })
      }).catch(()=>{
        this.$message({message:'取消删除'})
      })
    },
    check(id,status){
      this.$confirm('确定审核该用户吗','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=>{
        this.$axios.post(`/sys/user/check/${id}/${status}`).then((response)=>{
          if (response.data.result==='success'){
            this.$message({message:'审核成功',type:'success'})
            this.loadTable()
          }else {
            this.$message({message:'审核失败',type:'error'})
          }
        })
      }).catch(()=>{
        this.$message({message:'取消审核'})
      })
    },
    handleRefresh(){
      this.loadTable()
    },
    queryClick() {
      this.loadTable()
    }
  }
}
</script>

<style scoped>

</style>
