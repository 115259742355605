<template>
  <div class="mainBar">
    <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard' }"><i class="el-icon-s-home"></i> 控制台</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'mainBar',
  props:{
    crumbsList:{
      type:Array,
      default(){
        return []
      }
    },
  },
  data() {
    return {
      isRefresh:false,
      timer:null
    }
  },
  mounted(){
    //console.log(this.crumbsList)
  },
  components: {

  },
  methods:{
  }
}
</script>

<style scoped>
.mainBar{
  display: inline-block;
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 0.5px solid #dcdcdc;
}
.breadcrumb{
  position: absolute;
  left: 20px;
  top: 13px;
}
</style>
