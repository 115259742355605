<template>
    <div>
      <div class="left">
<!--          <el-menu
              class="el-menu-vertical-demo"
              background-color="#304156"
              text-color="#fff"
              active-text-color="#ffd04b" style="height: 93vh; width: 200px;">
            <el-menu-item @click="$router.push('/dashboard')" index="0">
              <template v-slot:title>
                <i class="el-icon-s-data"></i>
                <span>控制台</span>
              </template>
            </el-menu-item>
            <el-menu-item index="1" @click="$router.push('/company')">
              <i class="el-icon-menu"></i>
              <span slot="title">公司管理</span>
            </el-menu-item>
            <el-menu-item index="2" @click="$router.push('/project')">
              <i class="el-icon-document"></i>
              <span slot="title">项目管理</span>
            </el-menu-item>
            <el-menu-item index="3" @click="$router.push('/region')">
              <i class="el-icon-setting"></i>
              <span slot="title">区域管理</span>
            </el-menu-item>
            <el-menu-item index="4" @click="$router.push('/device')">
              <i class="el-icon-setting"></i>
              <span slot="title">设备管理</span>
            </el-menu-item>
  &lt;!&ndash;          <el-submenu index="12">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>历史数据管理</span>
              </template>
              <el-menu-item index="12-1">实况数据</el-menu-item>
              <el-menu-item index="12-2">工作循环</el-menu-item>
              <el-menu-item index="12-3">报警数据</el-menu-item>
            </el-submenu>&ndash;&gt;
            <el-submenu index="5">
              <template slot="title">
                <i class="el-icon-setting"></i>
                <span>系统管理</span>
              </template>
              <el-menu-item @click="$router.push('/user')" index="5-1">用户管理</el-menu-item>
              <el-menu-item @click="$router.push('/role')" index="5-2">角色管理</el-menu-item>
              &lt;!&ndash;  <el-menu-item @click="$router.push('/menu')" index="5-3">权限管理</el-menu-item>&ndash;&gt;
            </el-submenu>
          </el-menu>-->
        <el-menu
            class="el-menu-vertical-demo"
            background-color="#304156"
            text-color="#fff"
            active-text-color="#ffd04b" style="height: 93vh; width: 200px;">
          <div v-for="(menu, index) in navList" :key="index">
            <el-submenu v-if="menu.type==0" :key="index" :index="index + ''">
              <template slot="title">
                <i :class="menu.icon"></i>
                <span>{{ menu.name }}</span>
              </template>
              <el-menu-item v-for="(child, index1) in menu.childs" @click="$router.push(child.url)" :key="index1" :index="index + '_'+index1">
                <i :class="child.icon"></i>
                <span slot="title">{{ child.name }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else-if="menu.type==1" @click="$router.push(menu.url)" :key="index" :index="index + ''">
              <i :class="menu.icon"></i>
              <span slot="title">{{ menu.name }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
      <div class="clear"></div>
    </div>
</template>

<script>
export default {
  name: "console",
  data(){
    return{
      /**导航菜单数据 */
      navList: []
    }
  },
  created() {
    this.loadMenuList();
  },
  methods:{
    loadMenuList(){
      this.$axios.post('/sys/menu/menuList').then((response) => {
        this.navList = response.data.data;
      }).catch((err) => {
        window.console.log(err);
      });
    }
  }
}
</script>

<style scoped>
.left{
  float: left;
}
.right{
  position: absolute;
  left: 200px;
  right: 0;
}
.clear{
  clear: both;
}
</style>
