<template>
  <div class="card_flex">
    <el-card v-for="(item,index) in statisticsItem"
      :key="index">
      <div>{{ statisticsValue[index] }}</div>
      <div style="margin-top: 10px">{{item}}</div>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "projectStatics",
  data(){
    return{
      statisticsItem:['项目人数','在建项目','已完成项目','监测设备总数','在线设备总数','离线设备总数'],
      statisticsValue:['--','--','--','--','--','--'],
    }
  },
  created() {
    this.loadStatics();
  },
  methods:{
    loadStatics(){
      this.$axios.post('/sys/project/projectStatics').then((response)=>{
        this.statisticsValue=response.data.info
      })
    }
  }
}
</script>
<style scoped>
.card_flex{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  text-align: center;
  font-size: 20px;
}
.card_flex>div{
  flex: 1;
}
</style>
