<!--
右一

-->

<template>
  <div class="visual_box">
    <div class="visual_title">
      <span>状态4</span>
      <img src="../../views/dataV/static/images/ksh33.png">
    </div>
    <div class="visual_open"  v-for="(item,i) in this.rightOneState" :key="i">
      <h5>{{item.name}}</h5>
      <span v-if="item.value=='打开'"><img src="../../assets/images/e_open.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='关闭'"><img src="../../assets/images/e_close.png" width="25" height="25" class="svg"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "rightOneState",
  props:["rightOneState"],
}
</script>
