<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['设备基础信息']">
      <template slot="controls">
        <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline" v-if="hasPerm('device:add')">新增</el-button>
      </template>
    </crumbs-bar>

    <el-container>
      <!-- <el-header><el-button size="small" type="primary" style="float: left" @click="add">新增设备</el-button></el-header>-->
      <el-container>
        <el-aside class="elaside">
          <my-tree></my-tree>
        </el-aside>
        <el-main style="padding: 0 0 0 5px;">
          <my-table></my-table>
        </el-main>
      </el-container>
      <my-dialog></my-dialog>
    </el-container>
  </div>
</template>

<script>
import myTree from "@/views/consoleDevice/myTree";
import myDialog from "@/views/consoleDevice/myDialog";
import myTable from "@/views/consoleDevice/myTable";
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
import {EventBus} from "@/views/consoleDevice/event-bus";
export default {
  name: "device",
  components:{
    myTree,myDialog,myTable
  },
  data(){
    return{
      comId: JSON.parse(window.localStorage.getItem('userInfo')).comId,
      consoleDeviceStore
    }
  },
  mounted(){

  },
  methods:{
    add(){
      if (consoleDeviceStore.state.org.areaId===''){
        this.$alert('请选择一个区域','提示')
        return
      }
      EventBus.$emit('add','新增设备')
    },
    handleRefresh(){
      EventBus.$emit('refresh','刷新')
    }
  }
}
</script>

<style scoped>
.elaside{
  padding-left: 5px;
  padding-top: 10px;
  border-right:1px dashed #b6b6b6;
  height: 88vh;
}
</style>
