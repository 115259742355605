<template>
  <div>
    <statistics-card :project-id="projectId"></statistics-card>
    <div >
      <a-device
          v-for="item in devices"
          :key="item.id"
          :device-name="item.name"
          :device-title="item.name+'-历史数据'"
          :device-code="item.code"
          :state="item.status"></a-device>
    </div>
  </div>
</template>

<script>
import StatisticsCard from "@/views/projectDevice/unloadingPlatform/subComponent/statisticsCard";
import aDevice from "@/views/projectDevice/unloadingPlatform/subComponent/aDevice";
export default {
  name: "unloadingMonitor",
  components: {StatisticsCard,aDevice},
  props:{
    projectId:String
  },
  data(){
    return{
      devices:[]
    }
  },
  created() {
    this.loadDevices()
  },
  methods:{
    loadDevices(){
      this.$axios.post(`/sys/msgLiveDataUnloading/deviceCard/${this.projectId}`).then((response)=>{
        this.devices=response.data
      })
    }
  }
}
</script>

<style scoped>

</style>
