<template>
  <el-dialog :visible.sync="visible" :title="'设备-'+this.deviceCode+'-属性设置'" @closed="configDialogClosed"
             class="status_change"
             :close-on-click-modal="false" :show-close="false" width="950px" left>
    <div style="height:500px;overflow:auto">
      <el-form ref="configFormData" :model="configFormData" label-width="160px" :inline="true"
               style="margin-right: 2px; margin-top: 10px;">
        <span v-if="this.deviceType==100||this.deviceType==103">
          <el-row style="line-height: 0px;">
            <el-col :span="24">基本信息</el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item label="塔吊编号" size="small" prop="deviceNo1">
                <el-input v-model="configFormData.deviceNo" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.deviceNo1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('deviceNo1')" :disabled="btnDeviceNo">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="通信号" size="small" prop="communicationNo1">
                <el-input v-model="configFormData.communicationNo" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.communicationNo1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small">
                <el-button type="primary" @click="config('communicationNo1')" :disabled="btnCommunicationNo">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="坐标X" size="small" prop="coordinateX1">
                <el-input v-model="configFormData.coordinateX" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.coordinateX1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('coordinateX1')" :disabled="btnCoordinateX">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="坐标Y" size="small" prop="coordinateY1">
                <el-input v-model="configFormData.coordinateY" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.coordinateY1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small">
                <el-button type="primary" @click="config('coordinateY1')" :disabled="btnCoordinateY">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="起重臂长" size="small" prop="liftAramLong1">
                <el-input v-model="configFormData.liftAramLong" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.liftAramLong1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('liftAramLong1')" :disabled="btnIftAramLong">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="平衡臂长" size="small" prop="balanceArmLong1">
                <el-input v-model="configFormData.balanceArmLong" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.balanceArmLong1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small">
                <el-button type="primary" @click="config('balanceArmLong1')" :disabled="btnBalanceArmLong">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="塔帽高" size="small" prop="towerCapHeight1">
                <el-input v-model="configFormData.towerCapHeight" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.towerCapHeight1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('towerCapHeight1')" :disabled="btnTowerCapHeight">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="塔身高" size="small" prop="towerBodyHeight1">
                <el-input v-model="configFormData.towerBodyHeight" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.towerBodyHeight1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small">
                <el-button type="primary" @click="config('towerBodyHeight1')" :disabled="btnTowerBodyHeight">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="最大载重" size="small" prop="maxWeight1">
                <el-input v-model="configFormData.maxWeight" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.maxWeight1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('maxWeight1')" :disabled="btnMaxWeight">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="绳索倍率" size="small" prop="repoPloidy1">
                <el-input v-model="configFormData.repoPloidy" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.repoPloidy1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small">
                <el-button type="primary" @click="config('repoPloidy1')" :disabled="btnRepoPloidy">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="频道号" size="small" prop="channelNumber1">
                <el-input v-model="configFormData.channelNumber" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.channelNumber1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('channelNumber1')" :disabled="btnChannelNumber">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="群塔防碰撞功能" size="small" prop="towersFpzgn1">
                <el-input v-model="configFormData.towersFpzgn" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.towersFpzgn1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('towersFpzgn1')" :disabled="btnTowersFpzgn">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="型号索引" size="small" prop="modelIndex1">
                <el-input v-model="configFormData.modelIndex" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.modelIndex1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('modelIndex1')" :disabled="btnModelIndex">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上报空钩算法" size="small" prop="dataSwitch1">
                <el-input v-model="configFormData.dataSwitch" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.dataSwitch1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('dataSwitch1')" :disabled="btnDataSwitch">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="运行空钩算法" size="small" prop="isEmptyHook1">
                <el-input v-model="configFormData.isEmptyHook" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.isEmptyHook1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('isEmptyHook1')" :disabled="btnIsEmptyHook">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="空钩判定值" size="small" prop="emptyHookVal1">
                <el-input v-model="configFormData.emptyHookVal" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.emptyHookVal1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('emptyHookVal1')" :disabled="btnEmptyHookVal">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="实时数据推送频率" size="small" prop="dataFrequency1">
                <el-input v-model="configFormData.dataFrequency" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.dataFrequency1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('dataFrequency1')" :disabled="btnDataFrequency">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="line-height: 0px;">
            <el-col :span="24">校准信息</el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item label="高度标定AD1" size="small">
                <el-input v-model="configFormData.highlyCalibratedAd1" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.highlyCalibratedAd11" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('highlyCalibratedAd11')" :disabled="btnHighlyCalibratedAd1">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="高度标定X1" size="small" >
                <el-input v-model="configFormData.highlyCalibratedX1" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.highlyCalibratedX11" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('highlyCalibratedX11')" :disabled="btnHighlyCalibratedX11">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="高度标定AD2" size="small">
                <el-input v-model="configFormData.highlyCalibratedAd2" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.highlyCalibratedAd21" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('highlyCalibratedAd21')" :disabled="btnHighlyCalibratedAd21">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="高度标定X2" size="small" >
                <el-input v-model="configFormData.highlyCalibratedX2" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.highlyCalibratedX21" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('highlyCalibratedX21')" :disabled="btnHighlyCalibratedX21">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="幅度标定AD1" size="small">
                <el-input v-model="configFormData.amplitudeCalibrateAd1" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.amplitudeCalibrateAd11" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('amplitudeCalibrateAd11')" :disabled="btnAmplitudeCalibrateAd11">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="幅度标定X1" size="small" >
                <el-input v-model="configFormData.amplitudeCalibrateX1" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.amplitudeCalibrateX11" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('amplitudeCalibrateX11')" :disabled="btnAmplitudeCalibrateX11">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="幅度标定AD2" size="small">
                <el-input v-model="configFormData.amplitudeCalibreateAd2" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.amplitudeCalibreateAd21" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('amplitudeCalibreateAd21')" :disabled="btnAmplitudeCalibreateAd21">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="幅度标定X2" size="small" >
                <el-input v-model="configFormData.amplitudeCalibrateX2" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.amplitudeCalibrateX21" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('amplitudeCalibrateX21')" :disabled="btnAmplitudeCalibrateX21">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="回转标定AD1" size="small">
                <el-input v-model="configFormData.turnCalibrateAd1" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.turnCalibrateAd11" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('turnCalibrateAd11')" :disabled="btnTurnCalibrateAd11">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="回转标定X1" size="small" >
                <el-input v-model="configFormData.turnCalibrateX1" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.turnCalibrateX11" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('turnCalibrateX11')" :disabled="btnTurnCalibrateX11">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="回转标定AD2" size="small">
                <el-input v-model="configFormData.turnCalibrateAd2" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.turnCalibrateAd21" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('turnCalibrateAd21')" :disabled="btnTurnCalibrateAd21">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="回转标定X2" size="small" >
                <el-input v-model="configFormData.turnCalibrateX2" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.turnCalibrateX21" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('turnCalibrateX21')" :disabled="btnTurnCalibrateX21">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="重量标定AD1" size="small">
                <el-input v-model="configFormData.weightCalibrateAd1" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.weightCalibrateAd11" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('weightCalibrateAd11')" :disabled="btnWeightCalibrateAd11">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量标定X1" size="small" >
                <el-input v-model="configFormData.weightCalibrateX1" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.weightCalibrateX11" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('weightCalibrateX11')" :disabled="btnWeightCalibrateX11">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="重量标定AD2" size="small">
                <el-input v-model="configFormData.weightCalibrateAd2" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.weightCalibrateAd21" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('weightCalibrateAd21')" :disabled="btnWeightCalibrateAd21">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量标定X2" size="small" >
                <el-input v-model="configFormData.weightCalibrateX2" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.weightCalibrateX21" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('weightCalibrateX21')" :disabled="btnWeightCalibrateX21">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="line-height: 0px;">
            <el-col :span="24">阈值信息</el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="24">
              <el-form-item label="高度冲顶限位" size="small">
                <el-input v-model="configFormData.thresholdHeight" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.thresholdHeight1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('thresholdHeight1')" :disabled="btnThresholdHeight1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="幅度近端限位" size="small">
                <el-input v-model="configFormData.thresholdNearAmplitude" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.thresholdNearAmplitude1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('thresholdNearAmplitude1')" :disabled="btnThresholdNearAmplitude1">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="幅度远端限位" size="small" >
                <el-input v-model="configFormData.thresholdFarAmplitude" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.thresholdFarAmplitude1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('thresholdFarAmplitude1')" :disabled="btnThresholdFarAmplitude1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="回转左限位" size="small">
                <el-input v-model="configFormData.thresholdTurnLeft" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.thresholdTurnLeft1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('thresholdTurnLeft1')" :disabled="btnThresholdTurnLeft1">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="回转右限位" size="small" >
                <el-input v-model="configFormData.thresholdTurnRight" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.thresholdTurnRight1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('thresholdTurnRight1')" :disabled="btnThresholdTurnRight1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="碰撞报警距离" size="small">
                <el-input v-model="configFormData.collisionAlarmDistance" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.collisionAlarmDistance1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('collisionAlarmDistance1')" :disabled="btnCollisionAlarmDistance1">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="碰撞预警距离" size="small" >
                <el-input v-model="configFormData.collisionWarnDistance" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.collisionWarnDistance1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('collisionWarnDistance1')" :disabled="btnCollisionWarnDistance1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="干涉报警距离" size="small">
                <el-input v-model="configFormData.interferenceAlarmDistance" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.interferenceAlarmDistance1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('interferenceAlarmDistance1')" :disabled="btnInterferenceAlarmDistance1">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="干涉预警距离" size="small" >
                <el-input v-model="configFormData.interferenceWarnDistance" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.interferenceWarnDistance1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('interferenceWarnDistance1')" :disabled="btnInterferenceWarnDistance1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="重量报警百分比" size="small">
                <el-input v-model="configFormData.weightAlarmPercent" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.weightAlarmPercent1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('weightAlarmPercent1')" :disabled="btnWeightAlarmPercent1">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量预警百分比" size="small" >
                <el-input v-model="configFormData.weightWarnPercent" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.weightWarnPercent1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('weightWarnPercent1')" :disabled="btnWeightWarnPercent1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="风速报警值" size="small">
                <el-input v-model="configFormData.windAlarmValue" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.windAlarmValue1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('windAlarmValue1')" :disabled="btnWindAlarmValue1">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="风速预警值" size="small" >
                <el-input v-model="configFormData.windWarnValue" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.windWarnValue1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('windWarnValue1')" :disabled="btnWindWarnValue1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="倾斜报警值" size="small">
                <el-input v-model="configFormData.incidenceAlarmValue" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.incidenceAlarmValue1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('incidenceAlarmValue1')" :disabled="btnIncidenceAlarmValue1">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="倾斜预警值" size="small" >
                <el-input v-model="configFormData.incidenceWarnDistance" style="width: 100px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.incidenceWarnDistance1" class="inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('incidenceWarnDistance1')" :disabled="btnIncidenceWarnDistance1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="line-height: 0px;">
            <el-col :span="24">网络信息</el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="24">
              <el-form-item label="IP地址" size="small">
                <el-input v-model="configFormData.netIpAddress" style="width: 200px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.netIpAddress1" class="net_inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('netIpAddress1')" :disabled="btnNetIpAddress1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="网关" size="small" >
                <el-input v-model="configFormData.netGateway" style="width: 200px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.netGateway1" class="net_inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('netGateway1')" :disabled="btnNetGateway1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="子网掩码" size="small">
                <el-input v-model="configFormData.netSbunetMask" style="width: 200px;" :disabled="true"></el-input>
                <el-input v-model="configFormData.netSbunetMask1" class="net_inputxx"></el-input>
              </el-form-item>
              <el-form-item size="small" >
                <el-button type="primary" @click="config('netSbunetMask1')" :disabled="btnNetSbunetMask1">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="line-height: 0px;">
            <el-col :span="24">实时采集值</el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item label="高度" size="small">
                <el-input v-model="configFormData.heightAnalogQuantity" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="幅度" size="small" >
                <el-input v-model="configFormData.rangeAnalogQuantity" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="回转" size="small">
                <el-input v-model="configFormData.turnAnalogQuantity" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="风速" size="small" >
                <el-input v-model="configFormData.windSpeedAnalogQuantity" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="倾角1" size="small">
                <el-input v-model="configFormData.dipAngleAnalogQuantity1" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="倾角2" size="small" >
                <el-input v-model="configFormData.dipAngleAnalogQuantity2" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="预留" size="small">
                <el-input v-model="configFormData.reserveChannelValue" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量" size="small" >
                <el-input v-model="configFormData.weightAnalogQuantity" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </span>
        <span v-else-if="this.deviceType==101">
          <el-row style="line-height: 0px;">
            <el-col :span="24">基本信息</el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item label="重量预警阈值(%)" size="small">
                <el-input v-model="configFormData.weightWarn" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.weightWarn1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item size="small">
                <el-button type="primary" @click="config('weightWarn1')">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量报警阈值(kg)" size="small" >
                <el-input v-model="configFormData.weightAlarm" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.weightAlarm1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item size="small">
                <el-button type="primary" @click="config('weightAlarm1')">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="高度预警阈值(%)" size="small">
                <el-input v-model="configFormData.heightWarn" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.heightWarn1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary"  @click="config('heightWarn1',this)">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="高度报警阈值(m)" size="small">
                <el-input v-model="configFormData.heightAlarm" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.heightAlarm1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" @click="config('heightAlarm1')">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="速度预警阈值(%)" size="small" >
                <el-input v-model="configFormData.speedWarn" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.speedWarn1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary"  @click="config('speedWarn1')">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="速度报警阈值(m/s)" size="small" >
                <el-input v-model="configFormData.speedAlarm" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.speedAlarm1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary" @click="config('speedAlarm1')">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="x倾角预警阈值(%)" size="small">
                <el-input v-model="configFormData.incidenceXWarn" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.incidenceXWarn1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary"  @click="config('incidenceXWarn1')">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="x倾角报警阈值(°)" size="small">
                <el-input v-model="configFormData.incidenceXAlarm" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.incidenceXAlarm1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary" @click="config('incidenceXAlarm1')">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="y倾角预警阈值(%)" size="small">
                <el-input v-model="configFormData.incidenceYWarn" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.incidenceYWarn1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary"  @click="config('incidenceYWarn1')">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="y倾角报警阈值(°)" size="small">
                <el-input v-model="configFormData.incidenceYAlarm" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.incidenceYAlarm1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary" @click="config('incidenceYAlarm1')">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="实况频率" size="small">
                <el-input v-model="configFormData.liveDataFrequency" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.liveDataFrequency1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary" @click="config('liveDataFrequency1')">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="心跳频率" size="small">
                <el-input v-model="configFormData.keepAliveFrequency" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.keepAliveFrequency1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary"  @click="config('keepAliveFrequency1')">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="人数报警阈值" size="small" >
                <el-input v-model="configFormData.numberPeopleAlarm" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.numberPeopleAlarm1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary" @click="config('numberPeopleAlarm1')">设置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备联网IP" size="small">
                <el-input v-model="configFormData.ipAddress" class="sjjinput" :disabled="true"></el-input>
                <el-input v-model="configFormData.ipAddress1" class="sjjinputxx"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button size="small" type="primary"  @click="config('ipAddress1')">设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </span>
        <span v-else-if="this.deviceType==102">
          <el-row style="line-height: 0px;">
            <el-col :span="24">卸料平台基本信息</el-col>
          </el-row>
          <el-divider></el-divider>
        </span>
        <el-row style="line-height: 0px;">
          <el-col :span="24">版本信息</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="软件版本" size="small">
              <el-input v-model="configFormData.softVersion" :disabled="true" style="width: 120px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="硬件版本" size="small" >
              <el-input v-model="configFormData.hardVersion" :disabled="true" style="width: 120px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible=false">关闭</el-button>
      <el-button type="primary" size="small" @click="deviceRestart('deviceRestart')">
        设备重启</el-button>
      <el-button type="primary" size="small" :loading="loadingQuery" @click="queryConfig">
        {{ loadingQuery?'查询中':'查询' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {EventBus} from "@/views/consoleDevice/event-bus";

export default {
  name: "msgDialog",
  data(){
    return{
      visible:false,
      loadingQuery:false,
      deviceCode:'',
      deviceType:'',
      configFormData:{
        deviceNo:'',
        deviceNo1:'',
        communicationNo:'',
        communicationNo1:'',
        coordinateX:'',
        coordinateX1:'',
        coordinateY:'',
        coordinateY1:'',
        liftAramLong:'',
        liftAramLong1:'',
        balanceArmLong:'',
        balanceArmLong1:'',
        towerCapHeight:'',
        towerCapHeight1:'',
        towerBodyHeight:'',
        towerBodyHeight1:'',
        maxWeight:'',
        maxWeight1:'',
        repoPloidy:'',
        repoPloidy1:'',
        channelNumber:'',
        channelNumber1:'',
        towersFpzgn:'',
        towersFpzgn1:'',
        modelIndex:'',
        modelIndex1:'',
        dataSwitch:'',
        dataSwitch1:'',
        isEmptyHook:'',
        isEmptyHook1:'',
        emptyHookVal:'',
        emptyHookVal1:'',
        dataFrequency:'',
        dataFrequency1:'',
        softVersion:'',
        hardVersion:'',
        highlyCalibratedAd1:'',
        highlyCalibratedAd11:'',
        highlyCalibratedX1:'',
        highlyCalibratedX11:'',
        highlyCalibratedAd2:'',
        highlyCalibratedAd21:'',
        highlyCalibratedX2:'',
        highlyCalibratedX21:'',
        amplitudeCalibrateAd1:'',
        amplitudeCalibrateAd11:'',
        amplitudeCalibrateX1:'',
        amplitudeCalibrateX11:'',
        amplitudeCalibreateAd2:'',
        amplitudeCalibreateAd21:'',
        amplitudeCalibrateX2:'',
        amplitudeCalibrateX21:'',
        turnCalibrateAd1:'',
        turnCalibrateAd11:'',
        turnCalibrateX1:'',
        turnCalibrateX11:'',
        turnCalibrateAd2:'',
        turnCalibrateAd21:'',
        turnCalibrateX2:'',
        turnCalibrateX21:'',
        weightCalibrateAd1:'',
        weightCalibrateAd11:'',
        weightCalibrateX1:'',
        weightCalibrateX11:'',
        weightCalibrateAd2:'',
        weightCalibrateAd21:'',
        weightCalibrateX2:'',
        weightCalibrateX21:'',
        thresholdHeight:'',
        thresholdHeight1:'',
        thresholdNearAmplitude:'',
        thresholdNearAmplitude1:'',
        thresholdFarAmplitude:'',
        thresholdFarAmplitude1:'',
        thresholdTurnLeft:'',
        thresholdTurnLeft1:'',
        thresholdTurnRight:'',
        thresholdTurnRight1:'',
        collisionAlarmDistance:'',
        collisionAlarmDistance1:'',
        collisionWarnDistance:'',
        collisionWarnDistance1:'',
        interferenceAlarmDistance:'',
        interferenceAlarmDistance1:'',
        interferenceWarnDistance:'',
        interferenceWarnDistance1:'',
        weightAlarmPercent:'',
        weightAlarmPercent1:'',
        weightWarnPercent:'',
        weightWarnPercent1:'',
        windAlarmValue:'',
        windAlarmValue1:'',
        windWarnValue:'',
        windWarnValue1:'',
        incidenceAlarmValue:'',
        incidenceAlarmValue1:'',
        incidenceWarnDistance:'',
        incidenceWarnDistance1:'',
        netIpAddress:'',
        netIpAddress1:'',
        netGateway:'',
        netGateway1:'',
        netSbunetMask:'',
        netSbunetMask1:'',
        heightAnalogQuantity:'',
        rangeAnalogQuantity:'',
        turnAnalogQuantity:'',
        dipAngleAnalogQuantity1:'',
        dipAngleAnalogQuantity2:'',
        windSpeedAnalogQuantity:'',
        reserveChannelValue:'',
        weightAnalogQuantity:'',

        weightWarn:'',
        weightAlarm:'',
        heightWarn:'',
        heightAlarm:'',
        speedWarn:'',
        speedAlarm:'',
        incidenceXWarn:'',
        incidenceXAlarm:'',
        incidenceYWarn:'',
        incidenceYAlarm:'',
        deviceSetCode:'',
        liveDataFrequency:'',
        ipAddress:'',
        numberPeopleAlarm:'',
        softwareVersion:'',
        hardwareVersion:'',
        keepAliveFrequency:'',
        alarmFrequency:'',
        weightWarn1:'',
        weightAlarm1:'',
        heightWarn1:'',
        heightAlarm1:'',
        speedWarn1:'',
        speedAlarm1:'',
        incidenceXWarn1:'',
        incidenceXAlarm1:'',
        incidenceYWarn1:'',
        incidenceYAlarm1:'',
        deviceSetCode1:'',
        liveDataFrequency1:'',
        ipAddress1:'',
        numberPeopleAlarm1:'',
        softwareVersion1:'',
        hardwareVersion1:'',
        keepAliveFrequency1:'',
        alarmFrequency1:''
      },

      btnDeviceNo:false,
      btnCommunicationNo:false,
      btnCoordinateX:false,
      btnCoordinateY:false,
      btnIftAramLong:false,
      btnBalanceArmLong:false,
      btnTowerCapHeight:false,
      btnTowerBodyHeight:false,
      btnMaxWeight:false,
      btnRepoPloidy:false,
      btnChannelNumber:false,
      btnTowersFpzgn:false,
      btnModelIndex:false,
      btnDataSwitch:false,
      btnIsEmptyHook:false,
      btnEmptyHookVal:false,
      btnDataFrequency:false,
      btnHighlyCalibratedAd1:false,
      btnHighlyCalibratedX11:false,
      btnHighlyCalibratedAd21:false,
      btnHighlyCalibratedX21:false,
      btnAmplitudeCalibrateAd11:false,
      btnAmplitudeCalibrateX11:false,
      btnAmplitudeCalibreateAd21:false,
      btnAmplitudeCalibrateX21:false,
      btnTurnCalibrateAd11:false,
      btnTurnCalibrateX11:false,
      btnTurnCalibrateAd21:false,
      btnTurnCalibrateX21:false,
      btnWeightCalibrateAd11:false,
      btnWeightCalibrateX11:false,
      btnWeightCalibrateAd21:false,
      btnWeightCalibrateX21:false,
      btnThresholdHeight1:false,
      btnThresholdNearAmplitude1:false,
      btnThresholdFarAmplitude1:false,
      btnThresholdTurnLeft1:false,
      btnThresholdTurnRight1:false,
      btnCollisionAlarmDistance1:false,
      btnCollisionWarnDistance1:false,
      btnInterferenceAlarmDistance1:false,
      btnInterferenceWarnDistance1:false,
      btnWeightAlarmPercent1:false,
      btnWeightWarnPercent1:false,
      btnWindAlarmValue1:false,
      btnWindWarnValue1:false,
      btnIncidenceAlarmValue1:false,
      btnIncidenceWarnDistance1:false,
      btnNetIpAddress1:false,
      btnNetGateway1:false,
      btnNetSbunetMask1:false
    }
  },
  created() {
    EventBus.$on('msgDialog',(code,type)=>{
      this.visible = true;
      this.deviceCode = code;
      this.deviceType = type;
      this.initControls();//画面控件初始化
      this.getConfigData();
    });
  },
  methods:{
    getConfigData(){
      let url = '';
      if(this.deviceType==100){
        url = "/sys/msg-config-tower/info";
      }else if(this.deviceType==101){
        url = "/sys/msg-config-elevator/info";
      }else if(this.deviceType==103){
        url = "/sys/msg-config-tower/info";
      }
      let param={
        devCode:this.deviceCode
      }
      this.$axios.post(url,param).then((response)=>{
        if(response.data.result==="success"){
          let info = response.data.info;
          if(info!=null){
            this.configFormData = response.data.info;
            this.configFormData.softVersion = info.softVersionA+"."+info.softVersionB+"."+info.softVersionC;
            this.configFormData.hardVersion = info.hardVersionA+"."+info.hardVersionB+"."+info.hardVersionC;
          }
        }
      }).catch((err)=>{
        console.log(err);
      });
    },
    config(field){
      var val = this.configFormData[field];
      if((val==='')|| val===undefined){
        this.$message({message: "属性设置不能为空！", type: 'error'});
        return;
      }
      var flag = this.changeText(field);//范围正则判断
      if(!flag){
        return;
      }
      let data = {
        devCode: this.deviceCode,
        devType: this.deviceType,
        name: field.substring(0, field.length - 1),
        value: val
      }
      this.$axios.post(`${this.COMMON.houseCollUrl}/dev/config/send`, data).then((response)=> {
        console.log(response.data);
        if(response.data.result==="success") {
          this.$message({message: response.data.msg, type: 'success'});
        } else {
          this.$message({message: response.data.msg, type: 'error'});
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    deviceRestart(field){
      var val = "0";//重启
      let data = {
        devCode: this.deviceCode,
        devType: this.deviceType,
        name: field,
        value: val
      }
      this.$axios.post(`${this.COMMON.houseCollUrl}/dev/config/send`, data).then((response)=> {
        console.log(response.data);
        if(response.data.result==="success") {
          this.$message({message: response.data.msg, type: 'success'});
        } else {
          this.$message({message: response.data.msg, type: 'error'});
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    queryConfig(){
      this.loadingQuery=true
      let data={
        devCode:this.deviceCode,
        devType:this.deviceType
      }
      this.$axios.post(`${this.COMMON.houseCollUrl}/dev/config/query`,data).then((response)=>{
        if(response.data.result==="success") {
          let info = response.data.info;
          this.configFormData = info;
          this.configFormData.softVersion = info.softVersionA + "." + info.softVersionB + "." + info.softVersionC;
          this.configFormData.hardVersion = info.hardVersionA + "." + info.hardVersionB + "." + info.hardVersionC;
          this.loadingQuery = false;
          this.$message({message: response.data.msg, type: 'success'});
        } else {
          this.$message({message: response.data.msg, type: 'error'});
          this.loadingQuery = false;
        }
      }).catch(()=>{
        this.loadingQuery=false;
        //console.log(err)
      })
    },
    configDialogClosed(){
      this.loadingQuery=false;
    },
    initControls(){
      this.configFormData.deviceNo='',
      this.configFormData.deviceNo1='',
      this.configFormData.communicationNo='',
      this.configFormData.communicationNo1='',
      this.configFormData.coordinateX='',
      this.configFormData.coordinateX1='',
      this.configFormData.coordinateY='',
      this.configFormData.coordinateY1='',
      this.configFormData.liftAramLong='',
      this.configFormData.liftAramLong1='',
      this.configFormData.balanceArmLong='',
      this.configFormData.balanceArmLong1='',
      this.configFormData.towerCapHeight='',
      this.configFormData.towerCapHeight1='',
      this.configFormData.towerBodyHeight='',
      this.configFormData.towerBodyHeight1='',
      this.configFormData.maxWeight='',
      this.configFormData.maxWeight1='',
      this.configFormData.repoPloidy='',
      this.configFormData.repoPloidy1='',
      this.configFormData.channelNumber='',
      this.configFormData.channelNumber1='',
      this.configFormData.softVersion='',
      this.configFormData.hardVersion='',
      this.configFormData.highlyCalibratedAd1='',
      this.configFormData.highlyCalibratedX1='',
      this.configFormData.highlyCalibratedAd2='',
      this.configFormData.highlyCalibratedX2='',
      this.configFormData.amplitudeCalibrateAd1='',
      this.configFormData.amplitudeCalibrateX1='',
      this.configFormData.amplitudeCalibreateAd2='',
      this.configFormData.amplitudeCalibrateX2='',
      this.configFormData.turnCalibrateAd1='',
      this.configFormData.turnCalibrateX1='',
      this.configFormData.turnCalibrateAd2='',
      this.configFormData.turnCalibrateX2='',
      this.configFormData.weightCalibrateAd1='',
      this.configFormData.weightCalibrateX1='',
      this.configFormData.weightCalibrateAd2='',
      this.configFormData.weightCalibrateX2='',
      this.configFormData.thresholdHeight='',
      this.configFormData.thresholdNearAmplitude='',
      this.configFormData.thresholdFarAmplitude='',
      this.configFormData.thresholdTurnLeft='',
      this.configFormData.thresholdTurnRight='',
      this.configFormData.collisionAlarmDistance='',
      this.configFormData.collisionWarnDistance='',
      this.configFormData.interferenceAlarmDistance='',
      this.configFormData.interferenceWarnDistance='',
      this.configFormData.weightAlarmPercent='',
      this.configFormData.weightWarnPercent='',
      this.configFormData.windAlarmValue='',
      this.configFormData.windWarnValue='',
      this.configFormData.incidenceAlarmValue='',
      this.configFormData.incidenceWarnDistance='',

      this.configFormData.weightWarn='',
      this.configFormData.weightAlarm='',
      this.configFormData.heightWarn='',
      this.configFormData.heightAlarm='',
      this.configFormData.speedWarn='',
      this.configFormData.speedAlarm='',
      this.configFormData.incidenceXWarn='',
      this.configFormData.incidenceXAlarm='',
      this.configFormData.incidenceYWarn='',
      this.configFormData.incidenceYAlarm='',
      this.configFormData.deviceSetCode='',
      this.configFormData.liveDataFrequency='',
      this.configFormData.ipAddress='',
      this.configFormData.numberPeopleAlarm='',
      this.configFormData.softwareVersion='',
      this.configFormData.hardwareVersion='',
      this.configFormData.keepAliveFrequency='',
      this.configFormData.alarmFrequency=''
    },
    changeText(field){
      let value = this.configFormData[field];
      var reg = "",msg = "";
      if(field==="deviceNo1"||field==="highlyCalibratedAd11"||field==="highlyCalibratedAd21"||field==="amplitudeCalibrateAd11"
          ||field==="amplitudeCalibreateAd21"||field==="turnCalibrateAd11"||field==="turnCalibrateAd21"){
        reg = /^[1-9]\d{0,3}$/;
        msg = "该属性取值范围1到9999！";
      } else if(field==="communicationNo1"){
        reg = /^([1-8])$/;
        msg = "该属性取值范围1到8！";
      } else if(field==="coordinateX1"||field==="coordinateY1"||field==="liftAramLong1"||field==="balanceArmLong1"
          ||field==="towerBodyHeight1" ||field==="maxWeight1"||field==="emptyHookVal1"||field==="highlyCalibratedX11"
          ||field==="highlyCalibratedX21"||field==="amplitudeCalibrateX11" ||field==="amplitudeCalibrateX21"
          ||field==="turnCalibrateX11"||field==="turnCalibrateX21"||field==="weightCalibrateX11"||field==="weightCalibrateX21"
          ||field==="thresholdHeight1"||field==="thresholdNearAmplitude1"||field==="thresholdFarAmplitude1"
          ||field==="thresholdTurnLeft1"||field==="thresholdTurnRight1" ||field==="collisionAlarmDistance1"
          ||field==="collisionWarnDistance1"||field==="interferenceAlarmDistance1"||field==="interferenceWarnDistance1"
          ||field==="windAlarmValue1"||field==="windWarnValue1"||field==="incidenceAlarmValue1"||field==="incidenceWarnDistance1"){
        reg = /^[0-9]\d{0,2}?(\.[0-9]{1,2})?$/;
        msg = "该属性取值范围0到999.99！";
      } else if(field==="towerCapHeight1"){
        reg = /^([0-9]|[1-9]\d|[1-9]\d(2)|[1-9]\d(3)|[1-5]\d(4)|6[0-4]\d(3)|65[0-4]\d(2)|655[0-2]\d|6553[0-5])$/;
        msg = "塔帽高取值范围0到65535！";
      } else if(field==="repoPloidy1"){
        if (value!=2&&value!=4) {
          this.$message({message: "该属性取值范围2或4！", type: 'error'});
          return false;
        } else {
          return true;
        }
      } else if(field==="channelNumber1"){
        reg = /^([1-9]|1[0-6])$/;
        msg = "该属性取值范围1到16！";
      } else if(field==="towersFpzgn1"||field==="dataSwitch1"||field==="isEmptyHook1"){
        if (value!=0&&value!=1) {
          this.$message({message: "该属性取值范围0或1！", type: 'error'});
          return false;
        } else {
          return true;
        }
      } else if(field==="modelIndex1"){
        reg = /^([1-9]|[1-8][0-9]|90)$/;
        msg = "该属性取值范围1到90！";
      } else if(field==="weightAlarmPercent1"||field==="weightWarnPercent1"){
        reg = /^([1-9]|[1-9][0-9]|100)$/;
        msg = "该属性取值范围1到100！";
      } else if(field==="weightCalibrateAd11"||field==="weightCalibrateAd21"){
        if (value<=0||value>20000000) {
          this.$message({message: "该属性取值范围0到20000000！", type: 'error'});
          return false;
        } else {
          if(!/^[1-9][0-9]*$/.test(value)){
            this.$message({message: "该属性取值范围0到20000000！", type: 'error'});
            return false;
          }
          return true;
        }
      } else if(field==="dataFrequency1"){
        if (value<3||value>86400) {
          this.$message({message: "该属性取值范围3到86400！", type: 'error'});
          return false;
        } else {
          if(!/^[1-9][0-9]*$/.test(value)){
            this.$message({message: "该属性取值范围3到86400！", type: 'error'});
            return false;
          }
          return true;
        }
      } else if(field==="netIpAddress1"||field==="netGateway1"||field==="netSbunetMask1"){
        reg = /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])$/;
        msg = "该属性取值范围有误！";
      }
      if(reg==""){
        return true;//临时应对 20240701 升降机
      }
      if (!reg.test(value)) {
        this.$message({message: msg, type: 'error'});
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
/* 利用穿透，设置input边框隐藏 */
.inputDeep {
  width: 50px;
}
.inputxx{
  width: 100px;
  margin-left: 1px;
}
.net_inputxx{
  width: 200px;
  margin-left: 1px;
}
.sjjinput{
  width: 90px;
}
.sjjinputxx{
  width: 90px;
  margin-left: 1px;
}
</style>
