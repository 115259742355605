<template>
  <div>
    <el-tree :load="loadNode" @node-click="handleNodeClick"  :props="treeProps" lazy></el-tree>
  </div>
</template>
<script>
import {store} from "@/views/consoleArea/store";
import {EventBus} from "@/views/consoleArea/event-bus"
export default {
  name: "myTree",
  data(){
    return{
      treeProps:{
        label:'name',
        children:'zones',
        isLeaf:'leaf',
        id:'id'
      }
    }
  },
  methods:{
    loadNode(node,resolve){
      if (node.level===0){
        //this.getData("/sys/company/listIdName",resolve,false)
        var userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        var data= {
          comId: userInfo.comId
        }
        this.$axios.post("/sys/company/listIdName",data).then((response)=>{
          resolve(response.data.length==0?[]:response.data)
        });
      }else if (node.level===1){
        this.getData(`/sys/project/listByCompany/${node.data.id}`,resolve,true)
      }else if (node.level==2){
        resolve([])
      }
    },
    handleNodeClick(data,node){
      if (node.level===2){
        /*store.setTreeSelectId(data.id)*/
        store.setSelectedIds([node.parent.data.id,node.data.id])
        EventBus.$emit("nodeClick")

      }
    },
    getData(url,resolve,isLeaf){
      this.$axios.post(url).then(response=>{
        if (isLeaf){
          response.data.forEach(item=>{
            item.leaf=isLeaf
          })
        }
        if (response.data.length===0){
          resolve([])
        }else {
          resolve(response.data)
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
