const user={
    state:{
        nickname:'',
        username:'',
        id:'',
        //menus:[],
        permissions:[]
    },
    mutations:{
        SET_USER:(state,data)=>{
            state.nickname=data.user.nickname
            state.username=data.user.username
            state.id = data.user.id
            //state.menus=data.menus
            state.permissions=data.userPermission
        },
        RESET_USER:(state)=>{
            state.nickname=''
            state.username=''
            state.id = ''
            //state.menus=[]
            state.permission=[]
        }
    },
    actions: {
        // 获取用户按钮权限信息
        getPermission({commit}) {
            let _this =this;
            return new Promise((resolve, reject) => {
                _this.$axios("/getPermission").then((response)=> {
                    if (response.data.result === "success") {
                        //储存用户信息
                        commit('SET_USER', response.data.userPermission);
                        //cookie保存登录状态,仅靠vuex保存的话,页面刷新就会丢失登录状态
                        this.setToken();
                    }
                    resolve(response.data);
                }).catch(error => {
                    reject(error)
                });
            });
        }
    }
}
export default user
