<template>
    <span id="devChart" class="deviceStatus">
    </span>
</template>
<script>
export default {
  name:"leftDeviceStatus",
  data(){
    return{
    }
  },
  mounted() {
    this.devStatus();
  },
  methods:{
    resizeDom(dom) {
      //通过窗体高宽计算容器高宽，渲染echart图表的div的宽高度已达到自适应目的
      dom.style.width = (window.innerWidth-15)+'px';
      dom.style.height = (window.innerHeight-35)+'px';
    },
    devStatus(){
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        companyId: userInfo.comId
      }
      var scatterechartDom=document.getElementById('devChart');
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(scatterechartDom)
      this.$axios.post("/sys/device/deviceStatistics",data).then( (response) =>{
        // 绘制图表
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          color:['#D53A35', '#00FF18'],
          legend: {
            orient: 'vertical',
            left: 'right',
            padding:[0,20,0,0],
            textStyle:{
              color: '#fff',
              "fontSize": 14
            },
            data: ['在线', '离线'],
          },
          series: [
            {
              name: '设备数量',
              type: 'pie',
              radius: '65%',
              center: ['40%', '50%'],
              data: response.data.list,
              label:{
                normal: {
                show: true,
                textStyle: {
                  "fontSize": 14
                }
              }},
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        });
      }).catch((error)=>{
        console.log(error);
      });
      //当窗口发生变化时
      window.addEventListener("resize", () => {
        this.resizeDom(scatterechartDom); //重置div宽高度
        myChart.resize();//重绘echart图表
      });
    }
  }
}
</script>
<style scoped>
.deviceStatus{
  width: 100%!important;
  height: calc( 100% - 60px )!important;
  float:left;
}
</style>
