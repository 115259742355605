<!--
右二
-->
<template>
  <div class="visual_box">
    <div class="visual_title">
      <span>状态5</span>
      <img src="../../views/dataV/static/images/ksh33.png">
    </div>
    <div class="visual_open"  v-for="(item,i) in this.rightTwoState" :key="i">
      <h5>{{item.name}}</h5>
      <span v-if="item.value=='正常'"><img src="../../assets/images/alarm_0.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='预警'"><img src="../../assets/images/alarm_1.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='报警'"><img src="../../assets/images/alarm_2.png" width="25" height="25" class="svg"></span>
      <span v-else-if="item.value=='违章'"><img src="../../assets/images/alarm_2.png" width="25" height="25" class="svg"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "rightTwoState",
  props:["rightTwoState"],
}
</script>
