<template>
  <div class="app-container">
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['免密链接']">
      <template slot="controls">
      </template>
    </crumbs-bar>
    <div class="filter-container">
      <el-form :inline="true" :model="listQuery" ref="listQuery" class="demo-form-inline">
        <el-form-item style="margin-left: 30px;" label="设备SN" prop="设备SN">
          <el-input v-model="listQuery.devId" placeholder="设备SN" clearable=""></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 30px;" label="备注" prop="备注">
          <el-input v-model="listQuery.note" placeholder="备注" clearable=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="plus" @click="showCreate" v-if="hasPerm('nopass:add')">添加
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" v-loading.body="listLoading" element-loading-text="拼命加载中" border fit
              highlight-current-row>
      <el-table-column align="center" label="序号" width="80">
        <template slot-scope="scope">
          <span v-text="getIndex(scope.$index)"> </span>
        </template>
      </el-table-column>
      <el-table-column label="设备SN" width="150">
        <template slot-scope="scope">
          <a :href="scope.row.url" target="_blank">
            {{scope.row.devId}}
          </a>
        </template>
      </el-table-column>
      <el-table-column label="设备类型" width="150">
        <template slot-scope="scope">
          {{macType(scope.row.macType)}}
        </template>
      </el-table-column>
      <el-table-column label="免密地址" prop="url" show-overflow-tooltip></el-table-column>
      <el-table-column label="备注" prop="note" width="300" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" v-if="hasPerm('nopass:update')" @click="showUpdate(scope.$index)">修改</el-button>
          <el-button size="mini" type="danger" v-if="hasPerm('nopass:del')" @click="delVideo(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.pageNum"
      :page-size="listQuery.pageRow"
      :total="totalCount"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="600px">
      <el-form class="small-space" :model="tempForm" label-position="left" label-width="120px"
               style='margin-left:50px; margin-right: 50px;'>
        <el-form-item label="设备类型" required>
          <el-select v-model="tempForm.macType" placeholder="请选择" style="width: 340px;" :disabled="isDis">
            <el-option v-for="item in macTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备SN" required>
          <el-input type="text" oninput="value=value.replace(/^0|[^0-9]/g, '')" v-model="tempForm.devId" :disabled="isDis">
          </el-input>
        </el-form-item>
        <el-form-item label="备注" required>
          <el-input type="text" v-model="tempForm.note">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="dialogStatus=='create'" type="success" @click="createUser">创 建</el-button>
        <el-button type="primary" v-else @click="updateUser">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "noPassUrl",
  data() {
    return {
      isDis: false,
      totalCount: 0, //分页组件--数据总条数
      list: [],//表格的数据
      listLoading: false,//数据加载等待动画
      listQuery: {
        devId: '',
        note:'',
        pageNum: 1,//页码
        pageRow: 20,//每页条数
      },
      dialogStatus: 'create',
      dialogFormVisible: false,
      textMap: {
        update: '编辑',
        create: '新增'
      },
      tempForm: {
        id:'',
        devId: '',
        macType: '',
        note: '',
      },
      macTypes: [{value: 100,label: '塔机'},
        {value: 101,label: '升降机'},
        {value: 102,label: '卸料平台'},
        {value: 103,label: '一体机'}
      ],
    }
  },
  computed: {
    macType() {
      return function (type) {
        switch (type) {
          case 100:
            return "塔机"
          case 101:
            return "升降机"
          case 102:
            return "卸料平台"
          case 103:
            return "一体机"
          default:
            return "无设备类型"
        }
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    queryClick() {
      this.getList();
    },
    handleChange(value) {
      console.log(value);
    },
    getList() {
      //查询列表
      this.listLoading = true;
      this.listQuery.sysType=2;//房建
      this.$axios.post("/machine/nopass/list",this.listQuery).then(data => {
        this.listLoading = false;
        this.list = data.data.info.list;
        this.totalCount = data.data.info.totalCount;
      })
    },//列表
    handleSizeChange(val) {
      //改变每页数量
      this.listQuery.pageRow = val
      this.handleFilter();
    },
    handleCurrentChange(val) {
      //改变页码
      this.listQuery.pageNum = val
      this.getList();
    },
    handleFilter() {
      //查询事件
      this.listQuery.pageNum = 1
      this.getList()
    },
    getIndex($index) {
      //表格序号
      return (this.listQuery.pageNum - 1) * this.listQuery.pageRow + $index + 1
    },
    showCreate() {
      this.isDis=false;
      //显示新增对话框
      this.tempForm.id = '';
      this.tempForm.devId = '';
      this.tempForm.macType = '';
      this.tempForm.note = '';
      this.dialogStatus = "create"
      this.dialogFormVisible = true
    },
    showUpdate($index) {
      this.isDis=true;
      this.tempForm.id = '';
      this.tempForm.devId = '';
      this.tempForm.macType = '';
      this.tempForm.note = '';
      let model = this.list[$index];
      this.tempForm.devId = model.devId;
      this.tempForm.macType = model.macType;
      this.tempForm.note = model.note;
      this.tempForm.id = model.id;

      this.dialogStatus = "update"
      this.dialogFormVisible = true
    },
    createUser() {
      if(this.tempForm.macType===''){
        this.$message({ type:'error', message:'请选择设备类型！' })
        return;
      }
      if(this.tempForm.devId===''){
        this.$message({ type:'error', message:'请输入设备SN！' })
        return;
      }
      if(this.tempForm.note===''){
        this.$message({ type:'error', message:'请输入备注！' })
        return;
      }
      let _vue = this;
      //添加
      this.tempForm.sysType = 2;//系统类型
      this.noPassPage();//根据设备类型，设置免密画面
      this.$axios.post("/machine/nopass/save",this.tempForm).then((res) => {
        this.dialogFormVisible = false;
        if(res.data.info==1){
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 1 * 1000,
            onClose: () => {
              _vue.getList();
            }
          })
        } else {
          this.$message({ type:'error', message:'添加失败！' });
        }
        this.dialogFormVisible = false
      })
    },
    updateUser() {
      if(this.tempForm.macType===''){
        this.$message({ type:'error', message:'请选择设备类型！' })
        return;
      }
      if(this.tempForm.devId===''){
        this.$message({ type:'error', message:'请输入设备SN！' })
        return;
      }
      if(this.tempForm.note===''){
        this.$message({ type:'error', message:'请输入备注！' })
        return;
      }
      //修改用户信息
      let _vue = this;
      this.$axios.post("/machine/nopass/upate",this.tempForm).then((res) => {
        this.dialogFormVisible = false;
        if(res.data.info==1){
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1 * 1000,
            onClose: () => {
              _vue.getList();
            }
          })
        } else {
          this.$message({ type:'error', message:'修改失败！' });
        }
      })
    },//重新获取token
    delVideo($index) {
      let _this = this;
      let model = this.list[$index];
      this.tempForm.id = model.id;
      this.tempForm.sysType = 2;//系统类型
      this.$axios.post("/machine/nopass/delUrl",this.tempForm).then((res)=>{
        if(res.data.info==1){
          this.$message({
            message: "删除成功！",
            type: 'success',
            duration: 1 * 1000,
            onClose: () => {
              _this.getList();
            }
          });
        } else {
          this.$message({ type:'error', message:'删除失败！' });
        }
      })
    },
    handleRefresh(){
      this.getList()
    },
    noPassPage(){
      if(this.tempForm.macType==100){
        this.tempForm.devPage = "towerStateFree";
      } else if (this.tempForm.macType==101){
        this.tempForm.devPage = "elevatorStateFree";
      } else if (this.tempForm.macType==102){
        this.tempForm.devPage = "unloadingPlatStateFree";
      } else if (this.tempForm.macType==103){
        this.tempForm.devPage = "allInOneStateFree";
      } else {
        this.tempForm.devPage = "towerStateFree";
      }
    }
  }
}
</script>
<style>
.el-select-dropdown {
  box-shadow: none !important;
  min-width: 0px;
  border: 0 !important;
}

.el-select-dropdown__item span {
  width: 200px;
  text-align: center;
}

.tableHeader {
  background: rgb(64, 158, 255);
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family: HiraginoSansGB-W3;
  font-weight: 600;
  padding: 0 20px;
}

.tableHeader span {
  width: 100px;
  text-align: center;
}
</style>
