const mqttHost = '47.114.51.90';
//const mqttHost = 'WIN-8HUH0P8M4P4';
const mqttPort = 8083;
const mqttUserName = 'admin';
const mqttPassword = 'admin';
//const mqttPassword = 'admin666';
const initUrl = '47.114.51.90';
const houseCollUrl = 'http://fj.zzkde.com/housecoll';
const houseApiUrl = 'http://fj.zzkde.com/houseapi';
//const initUrl = '192.168.0.136';

export default {
    mqttHost,
    mqttPort,
    mqttUserName,
    mqttPassword,
    initUrl,
    houseCollUrl,
    houseApiUrl
}
