<!--
中间关键指标
-->
<template>
    <div class="visual_conTop">
        <div v-for="(item,i) in this.keyParam" :key="i" class="visual_conTop_box visual_conTop1">
            <div>
                <h3>{{item.name}}</h3>
                <p>{{item.value}}</p>
                <div class="conTop_smil">
                    <a class="null">null</a>
                    <a class="xd"><span>{{item.rule}}</span><i class="fa fa-long-arrow-down"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "keyParam",
        props: ["keyParam"],
    }
</script>
