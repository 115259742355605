<template>
<el-dialog width="500px" :title="title" class="status_change"
           :visible.sync="myVisible" :show-close="false" :close-on-click-modal="false">
  <el-form ref="myData" :model="myData" label-width="80px" :rules="formRule">
    <el-form-item label="角色名称" prop="name">
      <el-input v-model="myData.name"></el-input>
    </el-form-item>
    <el-form-item label="角色描述" prop="remarks">
      <el-input v-model="myData.remarks"></el-input>
    </el-form-item>
    <el-form-item label="资源信息" prop="ids">
      <el-tree
          v-model="myData.ids"
          :data="tranData"
          show-checkbox
          node-key="menuId"
          ref="tree"
          :default-checked-keys = "checkedArray"
          :props="defaultProps">
      </el-tree>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button size="small" @click="myVisible=false">取消</el-button>
    <el-button size="small" @click="save" type="primary">确定</el-button>
  </div>
</el-dialog>
</template>

<script>
import {mustFill} from "@/validation/valid";

export default {
  name: "addEditDialog",
  props:['visible','title','formData'],
  data(){
    return{
      formRule:{
        name:[mustFill],
        remarks:[mustFill]
      },
      tranData: [],
      checkedArray:[],
      defaultProps: {
        children: 'childs',
        label: 'name'
      }
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.visible
      },
      set:function (val){
        this.$emit("update:visible",val);
      }
    },
    myData:function (){
      //防止画面第一次加载无数据
      if(this.formData.id != undefined) {
        this.loadMenuList();
        this.loadSingleMenu();
      }
      return this.formData;
    }
  },
  created() {
  },
  methods:{
    save(){
      this.$refs["myData"].validate((valid) => {
        if (valid) {
          //let res = this.$refs.tree.getCheckedKeys()
          //let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedNodes())
          let res = this.$refs.tree.getCheckedKeys();
          let resHalf = this.$refs.tree.getHalfCheckedKeys();
          let array = [], arrayHalf = [];
          res.forEach(item =>{
            array+= item +','
          })
          resHalf.forEach(item =>{
            arrayHalf+= item +','
          })
          this.myData.ids = array;
          this.myData.halfIds = arrayHalf;
          this.$axios.post('/sys/role/save', this.myData).then((response) => {
            if (response.data.result === 'success') {
              this.$message({message: '保存成功', type: 'success'})
              this.myVisible = false
              this.$emit("saveSuccess")
              //this.$refs['form'].resetFields();
            } else {
              this.$message({message: '保存失败', type: 'error'})
            }
          });
        }else {
          this.$message({
            message: "请补全角色信息",
            type: "warning",
          });
        }
      });
    },
    loadMenuList(){
      this.$axios.post('/sys/menu/allMenuList').then((response) => {
        this.tranData=response.data.data;
      }).catch((err) => {
        window.console.log(err);
      });
    },
    loadSingleMenu(){
      this.$axios.post(`/sys/roleMenu/singlRoleMenuList/${this.formData.id}`).then((response) => {
        response.data.data.forEach(i=>{
          this.checkedArray.push(i.menuId)
        });
        this.checkedArray = this.unique(this.checkedArray);
      }).catch((err) => {
        window.console.log(err);
      });
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
</style>
