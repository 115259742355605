<template>
<el-card>
  <div> 电能监控开发中</div>
</el-card>
</template>

<script>
export default {
  name: "electricMonitor"
}
</script>

<style scoped>

</style>
