<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['区域管理']">
      <template slot="controls">
        <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline" v-if="hasPerm('area:add')">新增</el-button>
      </template>
    </crumbs-bar>
    <!-- <el-header >
        <el-button style="float: left" type="primary" @click="add" size="small">  添加区域</el-button>
    </el-header>-->
    <el-container>
      <el-aside class="elaside">
        <my-tree></my-tree>
      </el-aside>
      <el-main style="padding: 0 0 0 5px;">
        <my-table></my-table>
      </el-main>
    </el-container>
    <my-dialog></my-dialog>
  </div>
</template>

<script>
import myTree from "@/views/consoleArea/myTree";
import myTable from "@/views/consoleArea/myTable";
import myDialog from "@/views/consoleArea/myDialog";
import {store} from "@/views/consoleArea/store";
import {EventBus} from "@/views/consoleArea/event-bus";
export default {
  name: "region",
  components:{
    myTree,myTable,myDialog
  },
  data(){
    return {
      store
    }
  },
  methods:{
    add(){
      if (this.store.state.selectedIds.length===0){
        this.$alert('请先选择一个项目','提示')
        return
      }
      EventBus.$emit('addProject')
    },
    handleRefresh(){
      EventBus.$emit('refreshTable','刷新')
    }
  }
}
</script>

<style scoped>
.clearfix:after{
  content: "";
  display: block;
  clear: both;
}
.elaside{
  padding-left: 5px;
  padding-top: 10px;
  border-right:1px dashed #b6b6b6;
  height: 88vh;
}
</style>
