<template>
  <div  class="login-container">
    <el-form  :model="formData" :rules="rules" ref="formData" status-icon label-position="left" label-width="0" class="login-page" >
      <h3 style="padding: 10px;margin-bottom: 20px">系统登录-恺立智能</h3>
      <el-form-item prop="username" >
        <el-input type="text" placeholder="用户名" auto-complete="off" v-model="formData.username"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" placeholder="密码" auto-complete="off" v-model="formData.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @keyup.enter.native="login" style="width: 100%" type="primary" @click="login" :loading="loading" >
          登录
        </el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import {setToken} from "@/utils/auth";

export default {
  name: "login",
  data(){
    return {
      loading:false,
      formData:{
        username:'',
        password:''
      },
      rules:{
        username: [{required:true,message:'请输入您的账号',trigger:'blur'}],
        password: [{required:true,message:'请输入您的密码',trigger:'blur'}]
      },
      checked:false
    }
  },
  mounted() {
    document.onkeyup=(e)=>{
      console.log("keyup")
      if(e.key==='Enter'){
        this.login();
      }
    }
  },
  methods:{
    login(){
      this.$refs.formData.validate((valid)=>{
        if (valid){
          this.loading=true
          this.$axios.post("/login",this.formData).then((response)=>{
            if (response.data.msg==="success"){
              if(response.data.user.isCheck===1) {
                this.token = response.data.token;
                window.localStorage.setItem('auth', this.token);
                let {id, comId, proId, posId, username} = response.data.user
                let userInfo = {
                  id, comId, proId, posId, username
                }
                window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
                setToken();
                this.loginSuccess(response);//登录成功处理
              } else {
                this.loading=false
                this.$message.error('登录失败，未审核！')
              }
            }else {
              this.loginFail();//登录失败处理
            }
          }).catch((err)=>{
            this.requestError(err);//处理错误
          });
        }else{
          return false;
        }
      })
    },
    updateUser(data){
      this.$store.commit("SET_USER",data);
    },
    loginSuccess(response){
      document.onkeyup=function (){}//取消监听
      this.updateUser(response.data) //更新当前登录用户信息
      this.$router.push('/home');//路由跳转到首页
    },
    loginFail(){
      this.loading=false
      this.$message.error('登录失败，用户名或密码错误')
    },
    requestError(err){
      this.loading=false
      this.$message.error(err)
      //this.$message.error('登录失败，网络错误')
      console.log(err);
    }
  }

}
</script>

<style scoped>
  .login-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: url("../../assets/login.jpg") no-repeat center;
    background-size: 100% auto;
    background-color: #194C83;
  }
  .login-page{
    width: 300px;
    height: 260px;
    background-color: #ffffff;
    border:1px solid #eaeaea;
    padding: 15px 35px;
    box-shadow: 0 0 25px #cac6c6;
    border-radius: 5px;
  }
</style>
