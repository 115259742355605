<template>
  <div class="card_flex">
    <el-card class="layui-elevator">
      <span>设备数量：</span>
      <span>{{deviceStatics.total}}台</span>
    </el-card>
    <el-card class="layui-elevator">
      <span>当前在线：</span>
      <span>{{deviceStatics.online}}台</span>
    </el-card>
    <el-card class="layui-elevator">
      <span>当前离线：</span>
      <span>{{deviceStatics.offline}}台</span>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "statisticsCard",
  props:{
    projectId:String
  },
  data(){
    return {
      deviceStatics:{
        total:'--',
        online:'--',
        offline:'--'
      }
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    loadData(){
      this.$axios.post(`/sys/msgLiveDataUnloading/statistics/${this.projectId}/device/102`).then((response)=>{
        this.deviceStatics=response.data;
      })
    }
  }
}
</script>

<style scoped>
.card_flex{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 10px 0;
}
.card_flex>div{
  text-align: center;
  flex: 1;
  color: #008CFF;
}
.layui-elevator{
  height: 100%; background: rgba(0, 124, 249, 0.1);
  border: 1px solid #0056A1; border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
</style>
