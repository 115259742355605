<template>
<div>
  <el-row>
    <el-col>
      <div class="overview">
      <el-card class="overflowCard">
        <div>
          <div>12</div>
          <div>项目总数</div>
        </div>
      </el-card>
      <el-card  class="overflowCard">
        <div>
          <div>5</div>
          <div>在建项目</div>
        </div>
      </el-card>
      <el-card  class="overflowCard">
        <div>
          <div>7</div>
          <div>已完成项目</div>
        </div>
      </el-card>
      <el-card  class="overflowCard">
        <div>
          <div>30</div>
          <div>监测设备总数</div>
        </div>
      </el-card>
      <el-card class="overflowCard">
        <div>
          <div>29</div>
          <div>在线设备总数</div>
        </div>
      </el-card>

      <el-card class="overflowCard">
        <div>
          <div>1</div>
          <div>离线设备总数</div>
        </div>
      </el-card>
      </div>
    </el-col>

  </el-row>

  <el-row>
    <el-col style="display: flex;flex-wrap: wrap;justify-content: space-between">
      <el-card class="overviewCard">
        <div slot="header" class="card-header">
          <div >
            <div><span>塔机监控</span></div>
            <div><el-button type="primary" @click="$router.push('/deviceMonitorDetail')">详情</el-button></div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-body-left" id="tower_chart"></div>
          <div class="card-body-right">
            <div><span>3</span><span>覆盖项目数</span></div>
            <div><span>5</span><span>塔机总数</span></div>
          </div>
        </div>
      </el-card>

      <el-card class="overviewCard">
        <div slot="header" class="card-header">
          <div >
            <div><span>升降机监控</span></div>
            <div><el-button type="primary">详情</el-button></div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-body-left" id="elevator_chart"></div>
          <div class="card-body-right">
            <div><span>3</span><span>覆盖项目数</span></div>
            <div><span>5</span><span>塔机总数</span></div>
          </div>
        </div>
      </el-card>

      <el-card class="overviewCard">
        <div slot="header" class="card-header">
          <div >
            <div><span>卸料平台</span></div>
            <div><el-button type="primary">详情</el-button></div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-body-left" id="unloading_chart"></div>
          <div class="card-body-right">
            <div><span>3</span><span>覆盖项目数</span></div>
            <div><span>5</span><span>塔机总数</span></div>
          </div>
        </div>
      </el-card>

      <el-card class="overviewCard">
        <div slot="header" class="card-header">
          <div >
            <div><span>电能监控</span></div>
            <div><el-button  type="primary">详情</el-button></div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-body-left" id="electric_chart">

          </div>
          <div class="card-body-right">
            <div><span>3</span><span>覆盖项目数</span></div>
            <div><span>5</span><span>塔机总数</span></div>
          </div>
        </div>
      </el-card>

    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  name: "deviceMonitor",
  mounted() {
    this.drawCharts();
  },
  methods:{
    drawCharts(){
      let towerChart=this.$echarts.init(document.getElementById('tower_chart'));
      towerChart.setOption( {
        title: {
          text: '在线率',
          left: 'center',
          top: 'center'
        },
        series: [
          {
            type: 'pie',
            data: [
              {
                value: 335,
                name: 'A'
              },
              {
                value: 234,
                name: 'B'
              },
              {
                value: 1548,
                name: 'C'
              }
            ],
            radius: ['40%', '70%']
          }
        ]
      });


      let elevatorChart=this.$echarts.init(document.getElementById('elevator_chart'));
      elevatorChart.setOption( {
        title: {
          text: '在线率',
          left: 'center',
          top: 'center'
        },
        series: [
          {
            type: 'pie',
            data: [
              {
                value: 335,
                name: 'A'
              },
              {
                value: 234,
                name: 'B'
              },
              {
                value: 1548,
                name: 'C'
              }
            ],
            radius: ['40%', '70%']
          }
        ]
      });


      let unloading_chart=this.$echarts.init(document.getElementById('unloading_chart'));
      unloading_chart.setOption( {
        title: {
          text: '在线率',
          left: 'center',
          top: 'center'
        },
        series: [
          {
            type: 'pie',
            color:['#fac858','#91cc75'],
            data: [
              {
                value: 30,
                name: 'A',
              },
              {
                value: 60,
                name: 'b',
              }
            ],
            radius: ['50%', '70%']
          }
        ]
      });

      let electricChart=this.$echarts.init(document.getElementById('electric_chart'));
      electricChart.setOption( {
        title: {
          text: '在线率',
          left: 'center',
          top: 'center'
        },
        series: [
          {
            type: 'pie',
            data: [
              {
                value: 335,
                name: 'A'
              },
              {
                value: 234,
                name: 'B'
              },
              {
                value: 1548,
                name: 'C'
              }
            ],
            radius: ['40%', '70%']
          }
        ]
      });
    }
  }
}
</script>

<style scoped>
.overview{
  display: flex;
  justify-content: center;
}
 .overflowCard{
   height: 100px;
   flex: 1;
   margin: 10px;
   text-align: center;
}
 .overflowCard >div>div>div{
   margin: 10px;
 }

.overviewCard{
  width: 590px;
  height: 400px;
  margin: 10px;
}
.card-header>div{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-body{
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-body>div{
  flex: 1;
  height: 260px;
}
.card-body-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-body-right>div{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;

}
.card-body-right>div>span{
  display: block;

}
.card-body-left{
  text-align: center;
  width: 300px;
  height: 300px;
}
</style>
