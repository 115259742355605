<template>
    <span id="leftThreeChart" class="deviceStatus">
    </span>
</template>
<script>
export default {
  name:"leftThreeMain",
  data(){
    return{
    }
  },
  mounted() {
    this.devStatus();
  },
  methods:{
    resizeDom(dom) {
      //通过窗体高宽计算容器高宽，渲染echart图表的div的宽高度已达到自适应目的
      dom.style.width = (window.innerWidth-15)+'px';
      dom.style.height = (window.innerHeight-35)+'px';
    },
    devStatus(){
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        companyId: userInfo.comId,
        proId: userInfo.proId,
        count: 6 //查当天
      }
      this.$axios.post("/sys/alarm/weekCurve",data).then( (response) =>{
        let colorDataRes = ['#AA069F','#ff0103',"#02c3f1", "#ffe000", "#9a7fd1","#006ced",
          '#00ff00', '#93CE07','#FC7D02',
          '#AC3B2A'];
        let centerDevData = response.data;
        let lineDataArr=[],colorData = [],i=0;
        for (var key in centerDevData.records) {
          var res = centerDevData.records[key];
          let lineData=[];
          for(var val in res){
            lineData.push(res[val]);
          }
          let data1={
            name: key,
            type: 'line',
            smooth: true,
            showSymbol: true,
            symbolSize: 8,
            zlevel: 3,
            lineStyle: {
              normal: {
                width: 2,
              },
            },
            data: lineData,
          }
          lineDataArr.push(data1);
          colorData.push(colorDataRes[i]);
          i++;
        }
        var scatterechartDom=document.getElementById('leftThreeChart');
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(scatterechartDom);

        myChart.setOption({
          grid: {
            top: '3%',
            left: '3%',
            right: '5%',
            bottom:'3%',
            containLabel: true,
          },
          color : colorData,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(255,255,255,0)', // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255,255,255,1)', // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,255,255,0)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              axisLabel: {
                formatter: '{value}',
                fontSize: 14,
                margin: 20,
                textStyle: {
                  color: '#7ec7ff',
                },
              },
              axisLine: {
                lineStyle: {
                  color: '#243753',
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#243753',
                },
              },
              axisTick: {
                show: false,
              },
              data: centerDevData.times,
            },
          ],
          yAxis: [
            {
              boundaryGap: false,
              type: 'value',
              axisLabel: {
                textStyle: {
                  color: '#7ec7ff',
                },
              },
              nameTextStyle: {
                color: '#fff',
                fontSize: 12,
                lineHeight: 40,
              },
              splitLine: {
                lineStyle: {
                  color: '#243753',
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#283352',
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: lineDataArr,
        }
        );
      }).catch((error)=>{
        console.log(error);
      });
    }
  }
}
</script>
<style scoped>
.deviceStatus{
  width: 100%!important;
  height: calc( 100% - 60px )!important;
  float:left;
}
</style>
