<template>
  <el-dialog :title="this.deviceTitle" :show-close="false" width="60%" :close-on-click-modal="false"
             :visible.sync="myVisible" @open="loadData" class="status_change" left>
      <span>
        <el-form :inline="true" :model="pageQuery" ref="pageQuery" class="demo-form-inline">
          <el-form-item>
            <span class="demonstration">请选择时间段</span>
            <el-date-picker
                style="margin-right: 30px;"
                value-format="yyyy-MM-dd HH:mm:ss"
                label="时间段"
                v-model="pageQuery.datetime"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button v-loading.body="historyListLoading" type="primary" @click="queryClick($event)">查询</el-button>
          </el-form-item>
        </el-form>
      </span>
      <span>
        <el-table height="400" :data="page.records" border :row-style="{height:'44px'}" :cell-style="{padding:'4px'}">
          <el-table-column type="index" :index="indexMethod" label="序号" width="100" fixed="left"></el-table-column>
          <el-table-column label="时间戳" prop="createTime" width="170" fixed="left"></el-table-column>
          <el-table-column label="高度(m)" prop="height"></el-table-column>
          <el-table-column label="幅度(m)" prop="amplitude"></el-table-column>
          <el-table-column label="回转(°)" prop="turn"></el-table-column>
          <el-table-column label="载重(kg)" prop="weight"></el-table-column>
          <el-table-column label="允许载重(kg)" prop="allowWeight" width="120"></el-table-column>
          <el-table-column label="高度(%)" prop="heightPercentage"></el-table-column>
          <el-table-column label="幅度(%)" prop="amplitudePercentage"></el-table-column>
          <el-table-column label="载重(%)" prop="weightPercentage"></el-table-column>
          <el-table-column label="风速(m/s)" prop="windVelocity" width="100"></el-table-column>
          <el-table-column label="倾斜(°)" prop="incidence"></el-table-column>
          <el-table-column label="塔机个数" prop="towerCount"></el-table-column>
        </el-table>
        <el-pagination
          :page-size="page.size"
          :page-sizes="this.$pageSizes"
          :current-page="page.current"
          :total="page.total"
          layout="total,sizes,prev,pager,next,jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="refresh()" size="small">刷新</el-button>
      <el-button @click="myVisible=false" size="small">关闭</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "historyDataDialog",
  props:{
    dialogVisible:{
      type:Boolean,
      default:false
    },
    deviceId:String,
    deviceType:String,
    deviceTitle:String
  },
  data(){
    return{
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        hitCount:false,
        searchCount:true,
        pages:0
      },
      pageQuery:{
        deviceType:this.deviceType,
        datetime: [],
        size:this.$pageSize,
        current: 1
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近15分钟',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 0.25);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近30分钟',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 0.5);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近1小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近3小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 3);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近6小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 6);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近12小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 12);
              picker.$emit('pick', [start, end]);
            }
          },{
          text: '最近1天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近1个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近3个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },//日期控件
      historyListLoading: false,
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit("update:dialogVisible",val);
      }
    }
  },
  methods:{
    loadData(){
      this.$axios.post(`/sys/msgLiveDataTower/list/${this.deviceId}`,this.pageQuery).then((response)=>{
        this.page=response.data
      }).catch(err=>{
        console.log(err)
      })
    },
    handleSizeChange(value){
      this.pageQuery.size=value;
      this.loadData()
    },
    handleCurrentChange(value){
      this.pageQuery.current=value
      this.loadData()
    },
    refresh(){
      this.loadData();
    },
    queryClick(){
      this.loadData();
    },
    indexMethod(index) {
      index = (index + 1) + (this.page.current - 1) * this.page.size
      return index
    }
  }

}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
.demonstration {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
