<template>
  <el-dialog :title="title" :show-close="false" class="status_change"
             :visible.sync="myVisible" width="500px">
    <el-form ref="formData" :model="formData" label-width="80px" label-suffix=":" :rules="formRule">
      <el-form-item label="登录名" prop="username">
        <el-input v-model="formData.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="formData.password" placeholder="不填则表示不修改"></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input type="password" v-model="formData.rePassword" placeholder="不填则表示不修改"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="formData.nickname"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <el-select v-model="formData.roleId" placeholder="请选择" style="width: 380px">
          <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所有者" prop="owner">
        <el-cascader
            v-model="formData.owner"
            :key="Math.random()"
            :options="options"
            :props="optionProps"
            @change="handleChange"
            style="width: 380px">
        </el-cascader>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="myVisible=false" size="small">关闭</el-button>
      <el-button type="primary" @click="save()" size="small">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import http from "axios";
import {mustFill} from "@/validation/valid";

export default {
  name: "formDialog",
  props:['title','visible','myData'],
  data(){
    return{
      roles:[],
      optionProps: {
        expandTrigger: 'hover',
        value: "id",
        label: "name",
        children: "children"
      },
      options: [],
      formRule:{
        username:[mustFill],
        nickname:[mustFill],
        roleId:[mustFill],
        owner:[mustFill]
      }
    }
  },
  computed:{
    formData:function (){
      return this.myData
    },
    myVisible:{
      get:function (){
        return this.visible;
      },
      set:function (value){
        console.log(value);
        this.$emit("update:visible",value);
      }
    }
  },
  created() {
    this.loadRoleList()
    this.getTreeData();
  },
  methods:{
    save(){
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          this.$axios.post('/sys/user/save', this.formData).then((response) => {
            if (response.data.result === 'success') {
              this.$message({message: '保存成功', type: 'success'})
              this.$emit('saveSuccess')
              this.myVisible = false;
            } else {
              this.$message({message: response.data.msg, type: 'error'})
            }
          });
        }else {
          this.$message({
            message: "请补全用户信息",
            type: "warning",
          });
        }
      });
    },
    loadRoleList(){
      this.$axios.post(`/sys/role/roleList`).then((response)=>{
        this.roles=response.data
      })
    },
    getTreeData(){
      http({
        url:"/sys/company/listThreeTree",
        method: "post",
        data: {}
      }).then(res=>{
        this.options=res.data
      })
    },
    handleChange(value) {
      console.log(value);
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
</style>
