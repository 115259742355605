<template>
<div style="padding-top: 20px; background-color: #061537; height: 100%">
  <!-- 项目统计信息 -->
  <project-statics></project-statics>
  <!-- 每个项目的信息卡片 -->
  <div class="projectCard" >
    <project-card
        v-for="project in projects"
        v-bind:key="project.id"
        v-bind:project-data="project"/>
  </div>
</div>
</template>
<script>
import projectCard from "./subComponent/projectCard";
import ProjectStatics from "@/views/projectOverview/subComponent/projectStatics";
export default {
  name: "projectOverview",
  components:{
    ProjectStatics,
    projectCard
  },
  data(){
    return {
      projects:[]
    }
  },
  created() {
    this.getData()
  },
  methods:{
    getData(){
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        comId: userInfo.comId,
        limit: 15
      }
      this.$axios.post("/sys/project/listAll",data).then( (response) =>{
        this.projects=response.data.list;
      }).catch((error)=>{
        console.log(error);
      })
    }
  }
}
</script>
<style scoped>
.projectCard{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.projectCard:after{
  content: "";
  width: 600px;
}
</style>
