<template>
    <div id="videoDiv" style="width: 100%; height: 700px; padding-top: 60px;">
      <div id="videoDiv1" class="video"></div>
    </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
var player = null;
export default {
  name: "showVideo",
  props:['videoData'],
  data() {
    return {
      newVideoData:{}
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.visible;
      },
      set:function (value){
        this.$emit("update:visible",value);
      }
    }
  },
  watch:{
    videoData:function (newData){
      if(newData!=undefined) {
        this.newVideoData = newData;
        //this.binderVidoeData();
        this.ezopenVideoData();
      }
    }
  },
  mounted() {
  },
  methods:{
    ezopenVideoData(){
      fetch('/houseapi/video/application/token',{
        method:'post',
        body: JSON.stringify({
          devId: this.newVideoData.devId
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(res => {
          var accessToken = res.data.accessToken;
          if(player!=null){
            player.stop();
          }
          player = new EZUIKit.EZUIKitPlayer({
            id: 'videoDiv1', // 视频容器ID
            accessToken: accessToken,
            url: this.newVideoData.ezopenUrl,
            // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
            template: 'pcLive',
            width: 870,
            height: 630,
          });
        });
    },
    binderVideoData() {
      this.$nextTick(() => {
        var videoDiv = document.getElementById("videoDiv");
        console.log(videoDiv)
        var divHtml = '';
        for (var i = 0; i < this.newVideoData.length; i++) {
          divHtml += this.setDiv(this.newVideoData[i])
          videoDiv.innerHTML = divHtml;
        }
        /*for (var i = 0; i < this.newVideoData.length; i++) {
          new EZUIKit.EZUIPlayer('myPlayer' + this.newVideoData[i].id);
        }*/
      });
    },
    setDiv: function(item) {
      /*var div = '<object type=\'application/x-vlc-plugin\' class="obj_c" id=\'vlc'+item.id+'\' events=\'True\' pluginspage="http://www.videolan.org" '
        + 'codebase="http://downloads.videolan.org/pub/videolan/vlc-webplugins/2.0.6/npapi-vlc-2.0.6.tar.xz">'
        + '<param name=\'mrl\' class="mrl" value=\'' + item.rtmpUrl + '\' />'
        + '<param name=\'volume\' value=\'50\' />'
        + '<param name=\'autoplay\' value=\'true\' />'
        + '<param name=\'loop\' value=\'false\' />'
        + '<param name=\'fullscreen\' value=\'true\' />'
        + '</object>'*/
      var div = '<video id="myPlayer' + item.id + '" autoplay muted src="' + item.hlsUrl + '"  style="margin: 5px;" height="400" controls playsInline webkit-playsinline>' + '</video>'
      return div
    },
    beforeDestroy() {
      this.player && this.player.stop() //销毁并停止直播视频
    }
  }
}
</script>

<style>
.video{
  margin-top: 30px;
}
</style>
