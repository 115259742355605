<!--
左二
-->
<template>
  <div class="visual_box">
    <div class="visual_title">
      <span>状态2</span>
      <img src="../../views/dataV/static/images/ksh33.png">
    </div>
    <div class="visual_open"  v-for="(item,i) in this.stateTwo" :key="i">
      <h5>{{item.name}}</h5>
      <p style="color: #FBF400">
        <span style="color: #4545e3" class="txt">{{item.value}}{{item.rule}}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "leftTwoState",
  props:["stateTwo"],
}
</script>
