import {Message} from 'element-ui'
//import store from '../store'
import Axios from 'axios'
import router from "@/router";

Axios.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    const token = window.localStorage.getItem('auth');
    config.headers.token = token;
    return config;
  },
  error => {
    return Promise.reject(error);
  });

Axios.interceptors.response.use(function (response) {
  //console.log("tzh:"+response.config.url);
  //console.log("tzh:"+response.data.code);
  if (response.data.code == "20011") {
    /*Message({
      showClose: true,
      message: response.data.msg,
      type: 'error',
      duration: 500,
      onClose: () => {
        store.dispatch('FedLogOut').then(() => {
          location.reload()// 为了重新实例化vue-router对象 避免bug
        })
      }
    });
    return Promise.reject("未登录")*/
    Message({
      title: '提示',
      type: "warning",
      message: response.data.msg
    });
    router.push({path: "/login"});
  }
  return response;
},function (error){
  if(error.response==undefined) {
    router.push({path: "/login"})
  }else{
    switch (error.response.status){
      case 404:
        Message.error({message:'404错误'});
        break;
      default:error.message='连接出错'
    }
    return Promise.reject(error);
  }
});
