<!--
  名称：查询设备历史数据
  描述：按日期和设备号查询，可以导出excel报表 ,动态加载列
-->
<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['吊钩可视化数据']">
    </crumbs-bar>
    <el-container>
      <!--部门数据-->
      <el-aside class="elaside">
        <el-tree highlight-current lazy
            :load="loadNode"
            :props="defaultProps"
            @node-click="handleNodeClick"
        />
      </el-aside>
      <el-main style="padding: 0 0 0 5px;">
        <el-form :inline="true" :model="pageQuery" :rules="rules" ref="pageQuery" style="margin-top: 10px;">
          <el-form-item style="margin-left: 5px;" required label="设备编号" prop="deviceId">
            <el-input v-model="pageQuery.deviceId" readonly placeholder="设备编号"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="demonstration">日期</span>
            <el-date-picker
              style="margin-right: 30px;"
              value-format="yyyy-MM-dd"
              v-model="pageQuery.datetime"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button v-loading.body="historyListLoading" type="primary" @click="queryClick($event)">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-loading.body="listLoading" type="primary" @click="excleExport($event)">
              <span v-show="export_percentage===-1">导出Excel</span>
              <span v-show="export_percentage!==-1">导出中:{{ export_percentage }}%</span>
            </el-button>
          </el-form-item>
        </el-form>
        <div v-if="listState">
          <el-table :data="page.records" v-loading.body="listLoading" element-loading-text="拼命加载中" border fit
                    highlight-current-row>
            <el-table-column align="center" label="序号" width="80px" fixed="left">
              <template slot-scope="scope">
                <span v-text="getIndex(scope.$index)"> </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="设备编号" width="150px" fixed="left">
              {{this.pageQuery.deviceId}}
            </el-table-column>
            <el-table-column align="center" v-for="(col,index) in cols" :key="index"
                             :prop="col.prop" :label="col.name" :width="col.width">
              <template slot-scope="scope">
                  {{scope.row[col.prop]}}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :total="page.total"
            :current-page="page.current"
            :page-size="page.size"
            :page-sizes="this.$pageSizes"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
import * as XLSX from 'xlsx/xlsx.mjs'
import FileSaver from "file-saver";

export default {
  name: "hookData",
  data() {
    return {
      consoleDeviceStore,
      export_percentage: -1,
      rules: {
        deviceId: [
          {required: true, message: '请选择一个设备', trigger: 'blur'}
        ]
      },//查询表单验证
      readonly: true,
      historyListLoading: false,
      listLoading: false,//数据加载等待动画
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        orders: [],
        hitCount:false,
        searchCount: true,
        pages:0
      },
      pageQuery:{
        datetime: this.getDate(),
        deviceId: undefined,
        size:this.$pageSize,
        current: 1,
        type:0
      },
      defaultProps: {
        id:'id',
        label:'name',
        isLeaf:'leaf',
        type:"type"
      },
      url1:'/sys/company/listIdName',
      url2:'/sys/project/listByCompany',
      url3:'/sys/area/listByProject',
      url4:'/sys/device/listByAreaId',
      devType: 0,
      listState:false,
      titles:{},
      cols: [],
      names103: ["时间戳","电池电压","充电电流","充电方式","频道号","LORA ID","通信状态","通信质量"]
    }
  },
  computed:{
  },
  methods: {
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      //var hour = " 00:00:00"; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;//
      return defaultDate;
    },
    /*异步加载节点*/
    loadNode(node,resolve){
      switch (node.level){
        case 0:
          var userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
          var data= {
            comId: userInfo.comId
          }
          this.$axios.post(this.url1,data).then((response)=>{
            resolve(response.data.length==0?[]:response.data)
          });
          break
        case 1:
          this.resolveChildren(this.url2+'/'+node.data.id,resolve,false)
          break
        case 2:
          this.resolveChildren(this.url3+'/'+node.data.id,resolve,false)
          break
        case 3:
          this.resolveChildren(this.url4+'/'+node.data.id,resolve,true)
          break
        default:
          break
      }
    },
    resolveChildren(url,resolve,isLeaf){
      this.$axios.post(url).then((response)=>{
        if (isLeaf){
          response.data.forEach((item)=>{
            item.leaf=true
          })
        }
        resolve(response.data.length==0?[]:response.data)
      })
    },
    // 节点单击事件
    handleNodeClick(data, node) {
      let level = node.level;
      //1级为公司，2为项目，3为区域
      if (level === 4) {
        //更新store中的数据
        let org={
          companyId:node.parent.parent.parent.data.id,
          projectId:node.parent.parent.data.id,
          areaId:node.parent.data.id,
          companyName:node.parent.parent.parent.data.name,
          projectName:node.parent.parent.data.name,
          areaName:node.parent.data.name
        }
        consoleDeviceStore.setOrg(org)

        this.devType = data.type;//设备类型赋值

        //标题加载
        this.listState = true;
        this.hookTitle();
        this.getList(data.id)
      }
    },
    queryClick(evt) {
      let target = evt.target
      if (target.nodeName === "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.pageQuery.size=13;
      this.pageQuery.current=1;
      this.getList(this.pageQuery.deviceId)
    },
    getList(deviceId) {
      this.pageQuery.deviceId = deviceId;
      this.$refs['pageQuery'].validate((valid) => {
        if (valid) {
          this.historyListLoading = true;
          this.pageQuery.comId = consoleDeviceStore.state.org.companyId;
          this.pageQuery.proId = consoleDeviceStore.state.org.projectId;
          this.pageQuery.type = this.devType;
          this.$axios.post("/sys/statistics/getHookDataListByCode",this.pageQuery).then((response) => {
            this.historyListLoading = false;
            this.page=response.data;
            this.totalCount = this.page.total;
          });
          this.historyListLoading = false;
        } else {
          return false;
        }
      })
    },
    hookTitle(){
      var props = ["createTime","batteryVoltage","chargingCurrent","chargingWay","loraChannel","loraId","loraStatus","loraQuality"];
      var widths = [170,150,150,150,150,150,120,120];
      this.cols = [];
      for(var i=0;i<props.length;i++){
        this.titles = {};
        this.$set(this.titles, "prop", props[i]);
        this.$set(this.titles, "name", this.names103[i]);
        this.$set(this.titles, "width", widths[i]);
        this.cols.push(this.titles);
      }
    },
    handleSizeChange(size) {
      //改变每页数量
      this.pageQuery.size=size;
      this.getList(this.pageQuery.deviceId);
    },
    handleCurrentChange(current) {
      //改变页码
      this.pageQuery.current=current;
      this.getList(this.pageQuery.deviceId);
    },
    getIndex(index) {
      index = (index + 1) + (this.page.current - 1) * this.page.size
      return index
    },
    handleRefresh(){
      this.getList(this.pageQuery.deviceId);
    },
    excleExport(evt) {
      let target = evt.target
      target.blur()
      this.$refs['pageQuery'].validate((valid) => {
        if (valid) {
          let _this = this;
          const row = 1000
          let request_times = Math.ceil(this.totalCount / row); //计算分几次请求，1001总数的话会请求11次
          if (request_times === 0) {
            this.$message.error("没有数据，导出取消！");
            return
          }
          if(this.totalCount>100000){
            this.$message.error("请导出小于10万的数据！");
            return
          }
          let funcs = []; //Promise.all要用到的参数, 存放每次请求的Promise对象
          let complete_count = 0; //成功请求次数
          this.export_percentage = 0; //设置当前进度百分比为0
          for (let i = 1; i <= request_times; i++) { // 循环请求次数，构造请求的Promise对象并插入funcs数组
            let func = new Promise(function (resolve, reject) { //定义Promise并处理请求逻辑
              let data = [];
              let param = {
                datetime: _this.pageQuery.datetime,
                deviceId: _this.pageQuery.deviceId,
                comId: consoleDeviceStore.state.org.companyId,
                proId: consoleDeviceStore.state.org.projectId,
                type: _this.pageQuery.type,
                size: row,
                current: i
              };
              if (_this.pageQuery.deviceId === undefined) {
                reject();
              }
              _this.$axios.post("/sys/statistics/getHookDataListByCode",param).then(function (response) {
                for (let i = 0; i < response.data.records.length; i++) { //将后台返回中需要的字段取出
                  let item = [];
                  let src = response.data.records[i]
                  item.push(_this.pageQuery.deviceId);
                  item.push(src.createTime);
                  item.push(src.batteryVoltage);
                  item.push(src.chargingCurrent);
                  item.push(src.chargingWay);
                  item.push(src.loraChannel);
                  item.push(src.loraId);
                  item.push(src.loraStatus);
                  item.push(src.loraQuality);
                  data.push(item);
                }
                complete_count++; //成功请求次数+1
                _this.export_percentage = Math.floor((row * complete_count) / (request_times * 10)); //设置当前进度百分比

                resolve(data);

              }).catch(function (error) {
                console.log(error)
                reject();
              });
            });
            funcs.push(func);
          }

          Promise.all(funcs).then(function (values) { //使用Promise.all调用funcs里面的函数，并合并数据，最后给js-xlsx生成Excel
            let header = [['设备编号']]
            for (const cos in _this.names103) {
              header[0].push(_this.names103[cos])
            }
            let aoa = [];
            //将数据合并
            for (let i = 0; i < values.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                aoa.push(values[i][j]);
              }
            }
            const c = 1000000
            let sheet = Math.ceil(aoa.length / c)
            let wb = XLSX.utils.book_new();
            for (let i = 1; i <= sheet; i++) {
              wb.SheetNames.push('sheet' + i);
              wb.Sheets['sheet' + i] = XLSX.utils.aoa_to_sheet(header.concat(aoa.slice(0, c)));
              aoa.splice(0, c)
            }
            let wopts = {bookType: 'xlsx', bookSST: false, type: 'array'};
            let wbout = XLSX.write(wb, wopts);
            FileSaver.saveAs(new Blob([wbout], {type: "application/octet-stream"}), "data.xlsx");
            _this.export_percentage = -1;
          });
        } else {
          return false
        }
      })
    }
  },
}
</script>

<style scoped>
.elaside{
  padding-left: 0px;
  padding-top: 10px;
  border-right:1px dashed #b6b6b6;
  height: 88vh;
}
.demonstration {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
