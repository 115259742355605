<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'App',
    created() {
      if(sessionStorage.getItem("store")){
        this.$store.replaceState(Object.assign({},this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
      }
      window.addEventListener("beforeunload",()=>{
        sessionStorage.setItem("store",JSON.stringify(this.$store.state))
      })
    }
  }
</script>

<style>
html,body,#app{
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
html{
  box-sizing: border-box;
}
body{
  padding: 0;
  margin: 0;
}
.clearfix:after{
  display: block;
  content: "";
  clear: both;
}
</style>
