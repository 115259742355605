<template>
  <el-dialog :title="this.deviceCycleTitle" :show-close="false" :close-on-click-modal="false"
      width="60%" :visible.sync="myVisible" class="status_change" @open="loadTable" left>
    <span>
      <el-form :inline="true" :model="pageQuery" ref="pageQuery" class="demo-form-inline">
        <el-form-item>
          <span class="demonstration">请选择时间段</span>
          <el-date-picker
              style="margin-right: 30px;"
              value-format="yyyy-MM-dd HH:mm:ss"
              label="时间段"
              v-model="pageQuery.datetime"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button v-loading.body="historyListLoading" type="primary" @click="queryClick($event)">查询</el-button>
        </el-form-item>
      </el-form>
    </span>
    <span>
      <el-table stripe border height="400" :data="page.records" style="width: 100%;">
        <el-table-column type="index" :index="indexMethod" label="序号" width="80" fixed="left"></el-table-column>
        <el-table-column label="开始时间" prop="beginTime" width="170" fixed="left"></el-table-column>
        <el-table-column label="结束时间" prop="endTime" width="170" fixed="left"></el-table-column>
        <el-table-column label="吊重(t)" prop="weight"></el-table-column>
        <el-table-column label="起吊点高度(m)" prop="beginHeight" width="120"></el-table-column>
        <el-table-column label="起吊点幅度(m)" prop="beginAmplitude" width="120"></el-table-column>
        <el-table-column label="起吊点回转(°)" prop="beginTurn" width="120"></el-table-column>
        <el-table-column label="卸吊点高度(m)" prop="endHeight" width="120"></el-table-column>
        <el-table-column label="卸吊点幅度(m)" prop="endAmplitude" width="120"></el-table-column>
        <el-table-column label="卸吊点回转(°)" prop="endTurn" width="120"></el-table-column>
        <el-table-column label="是否违章" width="120">
          <template v-slot="scope">
            <span v-if="scope.row.violate===0">正常</span>
            <span v-if="scope.row.violate===1">违章</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      :page-sizes="this.$pageSizes"
      :current-page="page.current"
      :page-size="page.size"
      :total="page.total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total,sizes,prev,pager,next,jumper">
      </el-pagination>
    </span>
    <span slot="footer">
      <el-button @click="refresh()" size="small">刷新</el-button>
      <el-button @click="myVisible=false" size="small">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "workingCycleDialog",
  props:{
      dialogVisible:{
        type:Boolean,
        default:false
      },
    deviceId:String,
    deviceType:String,
    deviceCycleTitle:String
  },
  data(){
    return{
      page:{
        records:[],
        size:0,
        current:0,
        total:0,
        hitCount:false,
        searchCount:true,
        pages:0
      },
      pageQuery:{
        deviceType:this.deviceType,
        datetime: [],
        size:this.$pageSize,
        current: 1
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近15分钟',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 0.25);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近30分钟',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 0.5);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近3小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 3);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近6小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 6);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近12小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 12);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近1个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近3个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },//日期控件
      historyListLoading: false,
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit('update:dialogVisible',val)
      }
    }
  },
  methods:{
    loadTable(){
      this.$axios.post(`/sys/msgWorkingCycleTower/list/${this.deviceId}`,this.pageQuery).then(response=>{
        this.page=response.data
      }).catch(err=>{
        console.log(err)
      })
    },
    handleSizeChange(val){
      this.pageQuery.size=val
      this.loadTable()
    },
    handleCurrentChange(val){
      this.pageQuery.current=val
      this.loadTable()
    },
    refresh(){
      this.loadTable();
    },
    queryClick(){
      this.loadTable();
    },
    indexMethod(index) {
      index = (index + 1) + (this.page.current - 1) * this.page.size
      return index
    }
  }
}
</script>
<style scoped>
.demonstration {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
