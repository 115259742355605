<template>
  <div class="dashboard-container" style="background-color: #ffffff;height: 100%;" >
    <!-- 面包屑导航 -->
    <main-bar>
    </main-bar>
    <!--<a href="#" target="_blank"><img style="margin: 40px"
      src="http://img.heeexy.com/github.png"></a>-->
    <div id="myChart"
         style="border-right: 1px solid #e6e6e6;border-bottom: 1px solid #e6e6e6;padding: 10px;width: 50%;height: 410px;float: left">
    </div>
    <div id="macAlarm" style="border-bottom: 1px solid #e6e6e6;padding: 10px;float: left;width: 50%;height: 410px;">
    </div>
    <div style="width: 50%;height: 410px;float: left;overflow-y: auto; padding-right: 5px;">
      <div style="padding: 10px;font-size: 20px;font-weight:bold;">今日设备报警统计</div>
      <el-table :data="page.records" v-loading.body="listLoading" element-loading-text="拼命加载中"
                border fit highlight-current-row>
        <el-table-column align="center" label="序号" width="70px">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="proName" label="项目名"></el-table-column>
        <el-table-column align="left" prop="areaName" label="区域名" width="100px"></el-table-column>
        <el-table-column align="left" prop="devName" label="设备名称"></el-table-column>
        <el-table-column align="left" prop="code" label="设备编号" width="110px"></el-table-column>
        <el-table-column align="center" prop="cnt" label="报警次数" width="90px"></el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="page.total"
          :current-page="page.current"
          :page-size="page.size"
          :page-sizes="[2, 3, 4, 5]"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <div id="weekAlarm" style="padding: 10px;border-left: 1px solid #e6e6e6;float: left;width: 50%;height: 410px;">
    </div>
  </div>
</template>
<script>
import MainBar from "@/components/global/MainBar";

export default {
  name: 'dashboard',
  components: {MainBar},
  data() {
    return {
      totalCount: 0,
      listLoading: false,//数据加载等待动画
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        orders: [],
        hitCount:false,
        searchCount: true,
        pages:0
      },
      pageQuery:{
        deviceId: undefined,
        size:5,
        current: 1,
      },
    }
  },
  mounted() {
    this.drawLine();
    this.macAlarm();
    this.weekAlarm();
    this.getList();
  },
  methods: {
    drawLine() {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        companyId: userInfo.comId
      }
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'));
      this.$axios.post("/sys/device/deviceStatistics",data).then( (response) =>{
        // 绘制图表
        myChart.setOption({
          title: {
            text: '设备状态统计',
            left: 'left'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          color:['#D53A35', '#00FF18'],
          legend: {
            orient: 'vertical',
            left: 'right',
            data: ['在线', '离线'],
          },
          series: [
            {
              name: '设备数量',
              type: 'pie',
              radius: '55%',
              center: ['50%', '60%'],
              data: response.data.list,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        });
      }).catch((error)=>{
        console.log(error);
      });
    },
    macAlarm() {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        companyId: userInfo.comId,
        count: 0 //查当天
      }
      this.$axios.post("/sys/alarm/dayCurve",data).then( (response) =>{
        let colorDataRes = ["#02c3f1", "#ffe000", "#9a7fd1","#006ced",
          '#00ff00', '#ff0103','#93CE07','#FC7D02',
          '#AA069F','#AC3B2A'];
        let centerDevData = response.data;
        let lineDataArr = [],colorData = [],i=0;

        for (var key in centerDevData.records) {
          var res = centerDevData.records[key];
          let lineData=[];
          for(var val in res){
            lineData.push(res[val]);
          }
          let data1={
            name: key,
            smooth: true,
            type: 'line',
            lineStyle: {
              normal: {
                width: 2
              },
            },
            data:lineData
          }
          lineDataArr.push(data1);
          colorData.push(colorDataRes[i]);
          i++;
        }
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById('macAlarm'))
        // 绘制图表
        myChart.setOption({
          title: {text: '当天设备报警统计'},
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(0,0,0,0)', // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(0,0,0,1)', // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,0,0,0)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: centerDevData.times,
            //  改变x轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#243753',
                fontSize:'14'
              },
            }
          },
          yAxis: {
            type: 'value',
            //  改变y轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#000',
                fontSize:'14'
              },
            },
            splitLine: {
              lineStyle: {
                color: '#243',
              },
            },
            axisTick: {
              show: false,
            }
          },
          series: lineDataArr
        });
      }).catch((error)=>{
        console.log(error);
      });
    },
    weekAlarm() {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        companyId: userInfo.comId,
        count: 6 //查当天
      }
      this.$axios.post("/sys/alarm/weekCurve",data).then( (response) =>{
        let colorDataRes = ['#AA069F','#ff0103',"#02c3f1", "#ffe000", "#9a7fd1","#006ced",
          '#00ff00', '#93CE07','#FC7D02',
          '#AC3B2A'];
        let centerDevData = response.data;
        let lineDataArr = [],colorData = [],i=0;

        for (var key in centerDevData.records) {
          var res = centerDevData.records[key];
          let lineData=[];
          for(var val in res){
            lineData.push(res[val]);
          }
          let data1={
            name: key,
            smooth: true,
            type: 'line',
            lineStyle: {
              normal: {
                width: 2
              },
            },
            data:lineData
          }
          lineDataArr.push(data1);
          colorData.push(colorDataRes[i]);
          i++;
        }
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById('weekAlarm'))
        // 绘制图表
        myChart.setOption({
          title: {text: '一周内设备报警统计'},
          color : colorData,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(0,0,0,0)', // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(0,0,0,1)', // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,0,0,0)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: centerDevData.times,
            //  改变x轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#243753',
                fontSize:'14'
              },
            }
          },
          yAxis: {
            type: 'value',
            //  改变y轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#000',
                fontSize:'14'
              },
            },
            splitLine: {
              lineStyle: {
                color: '#243',
              },
            },
            axisTick: {
              show: false,
            }
          },
          series: lineDataArr
        });
      }).catch((error)=>{
        console.log(error);
      });
    },
    getList() {
      //查询列表
      this.listLoading = true;
      //todo 添加接口调用
      this.listLoading = false;
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      this.pageQuery.comId = userInfo.comId;
      this.$axios.post("/sys/alarm/dayAlarmList",this.pageQuery).then((response) => {
        this.page = response.data
      })
    },
    handleSizeChange(size) {
      //改变每页数量
      this.pageQuery.size = size;
      this.getList(this.pageQuery.deviceId);
    },
    handleCurrentChange(current) {
      //改变页码
      this.pageQuery.current = current;
      this.getList(this.pageQuery.deviceId);
    },
    getIndex(index) {
      index = (index + 1) + (this.page.current - 1) * this.page.size
      return index
    }
  }
}
</script>
<style scoped>

</style>
