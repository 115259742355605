export const store={
    state:{
        treeSelectId:'',
        companyId:'',
        selectedIds:[],
        dialogVisible:false,
        dialogTitle:''

    },
    setTreeSelectId(val){
        this.state.treeSelectId=val
    },
    setSelectedIds(val){
        this.state.selectedIds=val
    },
    showDialog(title){
        this.state.dialogTitle=title
        this.state.dialogVisible=true
    },
    closeDialog(){
        this.state.dialogVisible=false
    }

}
