<template>
  <span style="width: 100%!important;height: calc( 100% - 60px )!important;float:left; padding-left: 5px">
    <table width="98%" border="0" cellspacing="1" bgcolor="#2B7CB5">
      <tr>
        <td bgcolor="#042C70" height="35px" class="talbe_td" width="65%">项目名称</td>
        <td bgcolor="#042C70" class="talbe_td" width="20%">项目负责人</td>
        <td bgcolor="#042C70" class="talbe_td" width="15%">操作</td>
      </tr>
      <tr v-for="project in tableData"
          v-bind:key="project.id">
        <td bgcolor="#00155A" height="35px">&nbsp;{{ project.name }}</td>
        <td bgcolor="#00155A">&nbsp;{{project.principal}}</td>
        <td bgcolor="#00155A" class="talbe_td">
          <span v-for="(devcnt,index) in project.devCntList" v-bind:key="index">
            <router-link :to="{path:`/towerDevice/${project.id}`}" v-if="devcnt.devCnt===0&&index<=0">
              <el-link type="primary">详情</el-link><!--:href="'/someAProjectOverView/'+(project.id)+''"-->
            </router-link>
            <router-link :to="{path:`/towerDevice/${project.id}`}" v-else-if="devcnt.devCnt>0&&devcnt.type==100&&index<=0">
              <el-link type="primary">详情</el-link><!--:href="'/someAProjectOverView/'+(project.id)+''"-->
            </router-link>
            <router-link :to="{path:`/elevatorDevice/${project.id}`}" v-else-if="devcnt.devCnt>0&&devcnt.type==101&&index<=0">
              <el-link type="primary">详情</el-link><!--:href="'/someAProjectOverView/'+(project.id)+''"-->
            </router-link>
            <router-link :to="{path:`/unloadingDevice/${project.id}`}" v-else-if="devcnt.devCnt>0&&devcnt.type==102&&index<=0">
              <el-link type="primary">详情</el-link><!--:href="'/someAProjectOverView/'+(project.id)+''"-->
            </router-link>
            <router-link :to="{path:`/allInOneDevice/${project.id}`}" v-else-if="devcnt.devCnt>0&&devcnt.type==103&&index<=0">
              <el-link type="primary">详情</el-link><!--:href="'/someAProjectOverView/'+(project.id)+''"-->
            </router-link>
            <router-link :to="{path:`/liftingHookDevice/${project.id}`}" v-else-if="devcnt.devCnt>0&&devcnt.type==104&&index<=0">
              <el-link type="primary">详情</el-link><!--:href="'/someAProjectOverView/'+(project.id)+''"-->
            </router-link>
          </span>
        </td>
      </tr>
    </table>
  </span>
</template>
<script>
export default {
  name:"leftOneMain",
  data(){
    return{
      tableData:[]
    }
  },
  created() {
    this.getPojectData();
  },
  methods:{
    getPojectData(){
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        comId: userInfo.comId,
        limit: 4
      }
      this.$axios.post("/sys/project/listAll",data).then( (response) =>{
        this.tableData = response.data.list;
      }).catch((error)=>{
        console.log(error);
      });
    }
  }
}
</script>
<style scoped>
.talbe_td{
  text-align: center;
}
</style>
