<template>
<div class="prbody">
  <section>
    <div class="left">
      <div class="layui-col-xs12">
        <div class="lItem left1">
          <div class="title">
            <div style="float: left"><img class="titleIcon" src="../../assets/pro/img/titleIcon.png"></div>
            <div style="float: left">项目信息</div>
            <img class="titleBg" src="../../assets/pro/img/title.png">
          </div>
          <left-one-main></left-one-main>
        </div>
      </div>
      <div class="layui-col-xs12">
        <div class="lItem">
          <div class="title">
            <div style="float: left"><img class="titleIcon" src="../../assets/pro/img/titleIcon.png"></div>
            <div style="float: left">设备在线状态</div>
            <img class="titleBg" src="../../assets/pro/img/title.png">
          </div>
          <left-two-main></left-two-main>
        </div>
      </div>
      <div class="layui-col-xs12">
        <div class="lItem">
          <div class="title html">
            <div style="float: left"><img class="titleIcon" src="../../assets/pro/img/titleIcon.png"></div>
            <div style="float: left">一周设备警报</div>
            <img class="titleBg" src="../../assets/pro/img/title.png">
          </div>
          <left-three-main></left-three-main>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="centerT layui-col-xs12">
        <div class="lItem">
          <pro-statics></pro-statics>
        </div>
      </div>

      <div class="layui-col-xs12 centerC">
        <div class="layui-col-xs12 lItem">
          <center-echarts-map></center-echarts-map>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="layui-col-xs12">
        <div class="lItem html">
          <div class="title">
            <div style="float: left"><img class="titleIcon" src="../../assets/pro/img/titleIcon.png"></div>
            <div style="float: left">项目进度</div>
            <img class="titleBg" src="../../assets/pro/img/title.png">
          </div>
          <right-one-main></right-one-main>
        </div>
      </div>
      <div class="layui-col-xs12">
        <div class="lItem">
          <div class="title html">
            <div style="float: left"><img class="titleIcon" src="../../assets/pro/img/titleIcon.png"></div>
            <div style="float: left">当天设备警报</div>
            <img class="titleBg" src="../../assets/pro/img/title.png">
          </div>
          <right-two-main></right-two-main>
        </div>
      </div>
      <div class="layui-col-xs12">
        <div class="lItem">
          <div class="title html">
            <div style="float: left"><img class="titleIcon" src="../../assets/pro/img/titleIcon.png"></div>
            <div style="float: left">当月设备警报</div>
            <img class="titleBg" src="../../assets/pro/img/title.png">
          </div>
          <right-three-main></right-three-main>
        </div>
      </div>
    </div>
  </section>
</div>
</template>
<script>
import ProStatics from "@/views/projectOverview/subComponent/proStatics";
import CenterEchartsMap from "@/views/projectOverview/subComponent/center";
import LeftOneMain from "@/views/projectOverview/subComponent/left1";
import LeftTwoMain from "@/views/projectOverview/subComponent/left2";
import LeftThreeMain from "@/views/projectOverview/subComponent/left3";
import RightOneMain from "@/views/projectOverview/subComponent/right1";
import RightTwoMain from "@/views/projectOverview/subComponent/right2";
import RightThreeMain from "@/views/projectOverview/subComponent/right3";
export default {
  components: {
    RightThreeMain,RightTwoMain,RightOneMain,LeftThreeMain,
    LeftTwoMain,LeftOneMain,CenterEchartsMap, ProStatics}
}
</script>
<style scoped>
::-webkit-scrollbar { width: 5px;height: 5px; }
::-webkit-scrollbar-thumb { background-color: #014e95; -webkit-border-radius: 2em; -moz-border-radius: 2em; border-radius: 2em;}
::-webkit-scrollbar-thumb:vertical:hover{ background-color: #014e95; }
::-webkit-scrollbar-thumb:vertical:active{ background-color: #014e95; }
::-webkit-scrollbar-button{ display: none; }
*{
  margin: 0; padding: 0;
}
.fr{
  float: right;
}
.prbody{
  height:auto !important;min-height:100%;width: 100vw;
  color: #fff; font-size: 14px; padding-top: 15px;
  background-color: #00094E;background-image: url(../../assets/pro/img/bg.png);background-size: 100% 100%;
}
.title{
  height: 42px; padding: 0 10px;font-size: 18px; font-family: Adobe Heiti Std; color: #DBE5FA; line-height: 42px;
  position: relative;text-shadow: 0 0 10px #DBE5FA, 0 0 10px #DBE5FA;
}
.titleIcon {
  width: 40px;
}
.titleBg {
  width: calc( 100% - 20px ); position: absolute; bottom: 0; left: 10px; height: 21px;
}

section{
  width: 100%; height: calc( 100vh - 86px );padding: 0px 10px 10px 10px; box-sizing: border-box;
}
section .left {
  width: 510px; height: 100%; float: left;
}
section .center {
  overflow: hidden; width: calc( 100% - 1020px ); height: 100%; float: left;
}
section .right {
  width: 510px; height: 100%; float: right;
}
.html{
  cursor: pointer;
}
.layui-col-xs12{
  padding: 10px;
}
.layui-col-xs12 .lItem{
  height: 100%; background: rgba(0, 124, 249, 0.1); border: 1px solid #0056A1; border-radius: 4px;
}

.left .layui-col-xs12{
  height: calc( 100%/3 );
}
.center .centerT{
  height: 100px; margin-bottom: 20px;
}
.center .centerT .lItem{
  background: transparent; border: 0; padding: 0 18px;
}
.center .centerC{
  height: calc( 100% - 120px ); padding: 10px;
}
.center .centerC .layui-col-xs6{
  height: calc( 100%/2 ); width: calc( 100%/2 );
}
.center .centerC .titleIcon {
  width: 13px;
}
.center .centerC .titleBg {
  width: calc( 100% - 20px ); position: absolute; bottom: 7px; left: 10px; height: 12px;
}

.center .centerB{
  height: calc( 100%/3 );
}
.right .layui-col-xs12{
  height: calc( 100%/3 );
}

.left1 .wwslBox{
  text-align: right;
}
.left1 .wwslItem{
  margin: 20px 0;
}
.left1 .wwslLabel{
  color: #fff; font-size: 16px;
}
.left1 .wwslVal{
  color: #DBE5FA; font-size: 30px;font-family: myFont; text-shadow: 0 0 10px #218BEE, 0 0 20px #218BEE;
}
.left1 .wwslDw{
  color: #DBE5FA; font-size: 18px; text-shadow: 0 0 10px #218BEE, 0 0 20px #218BEE;
}

.left3{
  padding: 10px 20px;height: calc( 100% - 62px ); overflow: auto;
}
.left3 p{
  margin-bottom: 5px;
}
.left3 .oneTr{
  background-image: url(../../assets/pro/img/tabBg.png);background-size: 100% 100%; margin-bottom: 9px; height: 27px;
  display: flex; align-items: center;
}
.left3 .layui-progress {
  background-color: #004489; width: 150px; display: inline-block; margin-right: 28px;
}
.left3 .layui-progress-bar {
  background-color: #00B4FF;
}
.left3 .num{
  margin: 0 33px 0 15px; width: 40px; display: inline-block; text-align: center;
}
.left3 .xmmc{
  width: 135px; display: inline-block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.left3 .val{
  font-family: myFont;
}

.center3 .lbtLeft {
  width: calc( 100% - 100px ); height: calc( 100% - 42px ); float: left; position: relative; overflow: hidden;
  padding: 10px; box-sizing: border-box;
}
.center3 .lbtRight {
  width: 100px; height: calc( 100% - 42px ); float: left;
}
.center3 .wwslBox{
  position: absolute; right: 25px; top: 50%; margin-top: -95px; text-align: center;
}
.center3 .wwslItem{
  margin: 15px 0; width: 70px;
}
.center3 .wwslLabel{
  color: #fff; font-size: 14px;
}
.center3 .wwslVal{
  color: #DBE5FA; font-size: 24px;font-family: myFont; font-weight: 900; text-shadow: 0 0 10px #218BEE, 0 0 20px #218BEE;
}
.center3 .swiper-container {
  width: 100%; height: 100%;margin-top: 5px;
}
.center3 .swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.center3 .swiper-slide .imgBox{
  /* background: #007FFE; */ margin: 5px; width: 50%; height: 100%; display: flex; align-items: top; justify-content: center;
}
.center3 .swiper-slide .imgBox img {
  display: block; max-width: 100%; max-height: calc( 100% - 32px );
}
.center3 .swiper-slide .imgBox p{
  height: 32px; line-height: 32px;position: absolute; bottom: 0;
}

.right3{
  height: calc( 100% - 42px ); overflow: auto;
}
</style>
