<template>
  <el-dialog :title="this.deviceTitle" :show-close="false" :close-on-click-modal="false"
             width="60%" class="status_change"
             :visible.sync="myVisible" @open="onOpen" left>
    <span>
      <el-form :inline="true" :model="pageQuery" ref="pageQuery" class="demo-form-inline">
        <el-form-item>
          <span class="demonstration">请选择时间段</span>
          <el-date-picker
              style="margin-right: 30px;"
              value-format="yyyy-MM-dd HH:mm:ss"
              label="时间段"
              v-model="pageQuery.datetime"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button v-loading.body="historyListLoading" type="primary" @click="queryClick($event)">查询</el-button>
        </el-form-item>
      </el-form>
    </span>
    <span>
      <el-table height="400" :data="page.records" border :row-style="{height:'44px'}" :cell-style="{padding:'4px'}">
        <el-table-column type="index" :index="indexMethod" label="序号" width="100" fixed="left"></el-table-column>
        <el-table-column label="时间戳" prop="createTime" width="170" fixed="left"></el-table-column>
        <el-table-column label="当前重量(kg)" prop="weight" width="120"></el-table-column>
        <el-table-column label="载重百分比(%)" prop="weightPercent" width="120"></el-table-column>
        <el-table-column label="当前倾角x(°)" prop="incidenceX" width="120"></el-table-column>
        <el-table-column label="当前倾角y(°)" prop="incidenceY" width="120"></el-table-column>
        <el-table-column label="最大允许载重(kg)" prop="maxWeight" width="140"></el-table-column>
        <el-table-column label="最大允许倾斜(°)" prop="maxIncidence" width="140"></el-table-column>
        <el-table-column label="电压(V)" prop="power"></el-table-column>
        <el-table-column label="当前状态">
          <template v-slot="scope">
            <span style="background-color: #3ae034;padding:0 8px;color: white;" v-if="scope.row.state===0">正常</span>
            <span style="background-color: #ff0e46;padding:0 8px;color: white;" v-if="scope.row.state===1">超载</span>
            <span style="background-color: #ff0e46;padding:0 8px;color: white;" v-if="scope.row.state===2">倾斜</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="margin-top: 10px"
        :page-size="page.size"
        :current-page="page.current"
        :total="page.total"
        :page-sizes="this.$pageSizes"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total,sizes,prev,pager,next,jumper">
      </el-pagination>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="refresh()" size="small">刷新</el-button>
      <el-button @click="myVisible=false" size="small">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "history",
  props:{
    visible:Boolean,
    deviceCode:String,
    deviceTitle:String
  },
  data(){
    return{
      page:{
        records:[],
        size:0,
        current:0,
        total:0,
        pages:0
      },
      pageQuery:{
        datetime: [],
        size:this.$pageSize,
        current: 1
      },
      pickerOptions: {
      shortcuts: [
        {
          text: '最近15分钟',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 0.25);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近30分钟',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 0.5);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近3小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 3);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近6小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 6);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近12小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 12);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近1个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近3个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      historyListLoading: false,
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.visible
      },
      set:function (value){
        this.$emit("update:visible",value)
      }
    }
  },
  methods:{
    loadData(){
      this.$axios.post(`/sys/msgLiveDataUnloading/list/${this.deviceCode}`,this.pageQuery).then((response)=>{
        this.page=response.data
      })
    },
    handleSizeChange(val){
      this.pageQuery.size=val
      this.loadData()
    },
    handleCurrentChange(value){
      this.pageQuery.current=value
      this.loadData()
    },
    refresh(){
      this.loadData();
    },
    onOpen(){
      this.loadData()
    },
    queryClick(){
      this.loadData();
    },
    indexMethod(index) {
      index = (index + 1) + (this.page.current - 1) * this.page.size
      return index
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
.demonstration {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
