<template>
<div :style="{position: 'absolute',width: areaWidth+'px',height: areaHeight+'px'}" >
  <div class="collision-area" >
    <div class="center"></div>
    <div class="txt" v-if="myAngle>-360&&myAngle<-180" style="top: calc(30%);left: calc(50%);">{{ devNum }}</div>
    <div class="txt" v-if="myAngle>-180&&myAngle<0" style="top: calc(50%);left: calc(50%);">{{ devNum }}</div>
    <div class="txt" v-if="myAngle>=0&&myAngle<=180" style="top: calc(30%);left: calc(50%);">{{ devNum }}</div>
    <div class="txt" v-if="myAngle>180&&myAngle<=360" style="top: calc(50%);left: calc(50%);">{{ devNum }}</div>
<!--    <div class="txt">{{myAngle}}</div>-->
  </div>
  <div class="boundary"></div>
  <div class="big-arm" :style="{transition: myTransition+'s',transform: 'rotate('+myAngle+'deg)'}">
    <div class="smallCar" :style="{left:amplitude+'px'}"></div>
  </div>
</div>
</template>

<script>
export default {
  name: "antiCollision",
  props:{
    devNum:Number,
    areaWidth:Number,
    areaHeight:Number,
    angle:Number,
    amplitude:Number
  },
  data(){
    return{
      myAngle: -this.angle,
      myTransition:3,
      flag:0
    }
  },
  watch:{
    angle:function (newAngle,oldAngle){
      //console.log("angle："+newAngle+","+oldAngle);
      this.myTransition=3
      //第四进入第一
       if ((newAngle>=0&&newAngle<90)&&(oldAngle>270&&oldAngle<=360)){
         this.myAngle=-(newAngle+360)
         this.flag=1
         //console.log("myangle2："+this.myAngle)
         return
       }
       //第一进入第四
      if ((newAngle>270&&newAngle<=360)&&(oldAngle>=0&&oldAngle<90)){
        this.myAngle=360-newAngle
        this.flag=0
        //console.log("myangle3："+this.myAngle)
        return
      }
      this.myAngle=-newAngle
    },
    myAngle:function (){
      //console.log("myangle1："+this.myAngle)
      if (this.myAngle<=-360&&this.flag==1){
        setTimeout(this.reset41,2500)
      }
      if (this.myAngle>0&&this.flag==0){
        setTimeout(this.reset14,2500)
      }
    }
  },
  methods:{
    reset41(){
      this.myTransition=0
      this.myAngle=this.myAngle+360
    },
    reset14(){
      this.myTransition=0
      this.myAngle=this.myAngle-360
    }
  }
}
</script>

<style scoped>
.center{
  height: 8px;
  width: 8px;
  background-color: #FBF400;
  z-index: 110;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  position: absolute;
  border-radius: 50%;
}
.txt{
  color: #ffffff;
  z-index: 111;
  position: absolute;
}
.boundary{
  width: 100%;
  height: 100%;
  border: 1px solid #EEB1FD;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: 122;
}
.collision-area{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid black;
  position: absolute;
  z-index: 120;
}
.big-arm{
  height: 2px;
  width: 50%;
  left: 50%;
  top: calc(50% - 1px);
  position: absolute;
  transform-origin:left center ;
  background-color: #00d8ff;
  z-index: 100;
}
.smallCar{
  height: 7px;
  width: 7px;
  background-color: #ff0e46;
  position: absolute;
  margin-top: -2px;
  transition: 3s;
  z-index: 100;
}
</style>
