<template>
<div style="text-align: left;">
  <el-button type="primary" style="margin-bottom: 20px" @click="add"  size="small">添加</el-button>
  <el-table :data="page.records">
    <el-table-column label="序号" type="index"></el-table-column>
    <el-table-column label="权限代码" prop="permissionCode"></el-table-column>
    <el-table-column label="权限名称" prop="permissionName"></el-table-column>
    <el-table-column label="操作">
      <template v-slot:default="scope">
        <el-button size="mini" @click="edit(scope.row)">编辑</el-button>
        <el-button size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      style="margin-top: 20px"
      :current-page=page.current
      :page-size="page.size"
      :page-sizes="this.$pageSizes"
      :total="page.total"
      layout="total,sizes,prev,pager,next,jumper"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
  ></el-pagination>
<form-dialog :visible.sync="formDialogVisible" :title="formDialogTitle" @saved="loadTable"  :form-data="formData"></form-dialog>
</div>
</template>

<script>
import formDialog from "./formDialog";
  export default {
    name: "permission",
    components:{
      formDialog
    },
    data(){
      return{
        formDialogVisible:false,
        formDialogTitle:'',
        formData:{
          permissionCode:'',
          permissionName:''
        },
        page:{
          records:[],
          total:0,
          current:0,
          size:0,
          pages:0
        },
        pageQuery:{
          size:this.$pageSize,
          current: 1
        }
      }

    },
    created() {
      this.loadTable()
    },
    methods:{
      add(){
        this.formDialogTitle='新增权限'
        this.formData={}
        this.formDialogVisible=true

      },
      edit(row){
        this.formDialogTitle='编辑权限'
        this.formData=row;
        this.formDialogVisible=true
      },
      loadTable(){
        this.$axios.post('/sys/permission/list',this.pageQuery).then((response)=>{
          this.page=response.data
        })
      },
      handleCurrentChange(val){
        this.pageQuery.current=val
        this.loadTable()
      },
      handleSizeChange(val){
        this.pageQuery.size=val
        this.loadTable()
      },
      del(id){
        this.$confirm('确定删除该权限么','提示').then(()=>{
          this.$axios.post(`/sys/permission/del/${id}`).then((response)=>{
            if (response.data.result==="success"){
              this.$message.success('删除成功')
              this.loadTable()
            }else{
              this.$message.error('删除失败')
            }
          })
        }).catch(()=>{
          this.$message.info('取消删除')
        })
      }

    }
  }


</script>

<style scoped>

</style>
