<template>
<div>
  <el-card style="width: 600px;height: 400px;margin-top: 20px">
    <div slot="header">
      项目概况
    </div>
    <div class="box-body">
      <div>
        <span>项目名称：</span>
        <span>{{ project.name }}</span>
      </div>
      <div>
        <span>项目进度：</span>
        <span><el-progress style="display: inline-block;width: 300px" :text-inside="true" :stroke-width="15" :percentage="project.progress"></el-progress></span>
      </div>
      <div>
        <span>项目地址：</span>
        <span>{{ project.address }}</span>
      </div>
      <div>
        <span>项目负责人：</span>
        <span>{{ project.principal }}</span>
      </div>
      <div>
        <span>负责人电话：</span>
        <span>{{ project.tel }}</span>
      </div>
    </div>
  </el-card>
</div>
</template>

<script>
export default {
  name: "overview",
  props:{
    projectId:String
  },
  data(){
    return{
      project:''
    }
  },
  created() {
    console.log(this.projectId)
    this.$axios.post("/sys/project/info/"+this.projectId).then((response)=>{
      this.project=response.data
    }).catch(err=>{
      console.log(err)
    })
  }
}
</script>

<style scoped>
.box-body >div{
  margin-top: 30px;
}
</style>
