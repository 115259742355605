<template>
  <el-dialog :visible.sync="myVisible" title="人脸参数配置" @closed="configDialogClosed"
             class="status_change" @open="queryConfig()"
             :close-on-click-modal="false" :show-close="false" width="580px" left>
    <div style="height:418px;overflow:auto">
      <el-form ref="configFormData" :model="configFormData" label-width="120px" :inline="true"
               style="margin-right: 2px; margin-top: 10px;">
        <el-row>
          <el-col :span="24">
            <el-form-item label="识别门限" size="small" prop="recognieLimit1">
              <el-input v-model="configFormData.recognieLimit" style="width: 100px;" :disabled="true"></el-input>
              <el-input v-model="configFormData.recognieLimit1" placeholder="识别门限值范围0-99！" class="inputxx"></el-input>
            </el-form-item>
            <el-form-item size="small" >
              <el-button type="primary" @click="config('recognieLimit1')" :disabled="btnRecognieLimit">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="活体门限" size="small" prop="liveLimit1">
              <el-input v-model="configFormData.liveLimit" style="width: 100px;" :disabled="true"></el-input>
              <el-input v-model="configFormData.liveLimit1" placeholder="活体门限值范围0-99！" class="inputxx"></el-input>
            </el-form-item>
            <el-form-item size="small">
              <el-button type="primary" @click="config('liveLimit1')" :disabled="btnLiveLimit">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="活体状态" size="small" prop="liveStatus1">
              <el-input v-model="configFormData.liveStatus" style="width: 100px;" :disabled="true"></el-input>
              <el-select v-model="configFormData.liveStatus1">
                <el-option v-for="item in liveStatus" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item size="small" >
              <el-button type="primary" @click="config('liveStatus1')" :disabled="btnLiveStatus">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="开门时长" size="small" prop="openTimes1">
              <el-input v-model="configFormData.openTimes" style="width: 100px;" :disabled="true"></el-input>
              <el-input v-model="configFormData.openTimes1" placeholder="开门时长值范围1秒-24小时！" class="inputxx"></el-input>
            </el-form-item>
            <el-form-item size="small">
              <el-button type="primary" @click="config('openTimes1')" :disabled="btnOpenTimes">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="同人时长" size="small" prop="sameTimes1">
              <el-input v-model="configFormData.sameTimes" style="width: 100px;" :disabled="true"></el-input>
              <el-input v-model="configFormData.sameTimes1" placeholder="同人时长值范围0-999999！" class="inputxx"></el-input>
            </el-form-item>
            <el-form-item size="small" >
              <el-button type="primary" @click="config('sameTimes1')" :disabled="btnSameTimes">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="两眼阈值" size="small" prop="eyesDistance1">
              <el-input v-model="configFormData.eyesDistance" style="width: 100px;" :disabled="true"></el-input>
              <el-input v-model="configFormData.eyesDistance1" placeholder="两眼阈值值范围0-999！" class="inputxx"></el-input>
            </el-form-item>
            <el-form-item size="small" >
              <el-button type="primary" @click="config('eyesDistance1')" :disabled="btnEyesDistance">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="语音" size="small" prop="audioSwitch">
              <el-input v-model="configFormData.audioSwitch" style="width: 100px;" :disabled="true"></el-input>
              <el-select v-model="configFormData.audioSwitch1">
                <el-option v-for="item in audioSwitch" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item size="small">
              <el-button type="primary" @click="config('audioSwitch1')" :disabled="btnAudioSwitch">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="上报记录URL" size="small" prop="recordUrl">
              <el-input v-model="configFormData.recordUrl" style="width: 315px;"></el-input>
            </el-form-item>
            <el-form-item size="small">
              <el-button type="primary" @click="updateConfigUrl('recordUrl')" :disabled="btnRecordUrl">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" :loading="loadingQuery" icon="el-icon-alarm-clock" @click="setSysTime" v-if="hasPerm('face:settime')" style="display: none;">
        时间同步</el-button>
      <el-button type="primary" size="small" :loading="loadingQuery" icon="el-icon-switch-button" @click="remoteRestart" v-if="hasPerm('face:restart')">
        远程重启</el-button>
      <el-button type="primary" size="small" :loading="loadingQuery" icon="el-icon-search" @click="queryConfig">
        {{ loadingQuery?'查询中':'查询参数' }}</el-button>
      <el-button size="small" @click="myVisible=false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: "manFaceConfig",
  props: {
    deviceSn:String,
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      liveStatus: [{
        value: 0,
        label: '关'
      }, {
        value: 1,
        label: '开'
      }],
      audioSwitch: [{
        value: 0,
        label: '关'
      }, {
        value: 1,
        label: '开'
      }],
      loadingQuery:false,
      configFormData:{
        recognieLimit:'',
        recognieLimit1:'',
        liveLimit:'',
        liveLimit1:'',
        liveStatus:'',
        liveStatus1:'',
        openTimes:'',
        openTimes1:'',
        sameTimes:'',
        sameTimes1:'',
        eyesDistance:'',
        eyesDistance1:'',
        audioSwitch:'',
        audioSwitch1:0,
        recordUrl:''
      },

      btnRecognieLimit:false,
      btnLiveLimit:false,
      btnLiveStatus:false,
      btnOpenTimes:false,
      btnSameTimes:false,
      btnEyesDistance:false,
      btnAudioSwitch:false,
      btnRecordUrl:false
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit("update:dialogVisible",val);
      }
    }
  },
  Mounted() {
    this.queryConfig();
  },
  methods:{
    config(field){
      var val = this.configFormData[field];
      if((val==='')|| val===undefined){
        this.$message({message: "参数设置不能为空！", type: 'error'});
        return;
      }
      let data = {
        deviceSn: this.deviceSn,
        name: field.substring(0, field.length - 1),
        value: val
      }
      this.$axios.post(`/dev/operator/configSet`, data).then((response)=> {
        if(response.data.result==="success") {
          this.$message({message: "该参数设置成功！", type: 'success'});
        } else {
          this.$message({message: response.data.msg, type: 'error'});
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    updateConfigUrl(field){
      var val = this.configFormData[field];
      if((val==='')|| val===undefined){
        this.$message({message: "第三方上报认证记录URL不能为空！", type: 'error'});
        return;
      }
      let data = {
        deviceSn: this.deviceSn,
        name: field,
        value: val
      }
      this.$axios.post(`/dev/config/updateConfigUrl`, data).then((response)=> {
        if(response.data.result==="success") {
          this.$message({message: "上报认证记录URL设置成功！", type: 'success'});
        } else {
          this.$message({message: response.data.msg, type: 'error'});
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    queryConfig(){
      this.$axios.post(`/dev/config/info/${this.deviceSn}`).then((response)=>{
        if(response.data.result==="success"&&response.data.info!=null) {
          this.configFormData = response.data.info;
          if(response.data.info.liveStatus==0){
            this.$set(this.configFormData, 'liveStatus', "关");
          }else if(response.data.info.liveStatus==1){
            this.$set(this.configFormData, 'liveStatus', "开");
          }
          if(response.data.info.audioSwitch==0){
            this.$set(this.configFormData, 'audioSwitch', "关");
          }else if(response.data.info.audioSwitch==1){
            this.$set(this.configFormData, 'audioSwitch', "开");
          }
          this.$set(this.configFormData, 'audioSwitch1', response.data.info.audioSwitch);
          this.$set(this.configFormData, 'liveStatus1', response.data.info.liveStatus);
          this.$set(this.configFormData, 'recognieLimit1', response.data.info.recognieLimit);
          this.$set(this.configFormData, 'liveLimit1', response.data.info.liveLimit);
          this.$set(this.configFormData, 'openTimes1', response.data.info.openTimes);
          this.$set(this.configFormData, 'sameTimes1', response.data.info.sameTimes);
          this.$set(this.configFormData, 'eyesDistance1', response.data.info.eyesDistance);

          this.loadingQuery = false;
        }
      }).catch(()=>{
        this.loadingQuery = false;
      })
    },
    setSysTime(){
      this.$confirm('确定与服务器时间同步','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=> {
        let data = {
          deviceSn: this.deviceSn,
        }
        this.$axios.post(`/dev/operator/setSysTime`, data).then((response) => {
          if (response.data.result === 'success') {
            this.$message.success('与服务器时间已同步，请确认时间！')
          } else {
            this.$message.error('与服务器时间已同步失败！')
          }
        });
      });
    },
    remoteRestart(){
      this.$confirm('确定要重启设备吗？','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=> {
        let data = {
          deviceSn: this.deviceSn,
        }
        this.$axios.post(`/dev/operator/restart`, data).then((response) => {
          if (response.data.result === 'success') {
            this.$message.success('设备已重新启动！')
          } else {
            this.$message.error('设备已重新启动失败！')
          }
        });
      });
    },
    configDialogClosed(){
      this.loadingQuery=false;
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
/* 利用穿透，设置input边框隐藏 */
.inputDeep {
  width: 50px;
}
.inputxx{
  width: 215px;
  margin-left: 1px;
}
.sjjinput{
  width: 90px;
}
.sjjinputxx{
  width: 90px;
  margin-left: 1px;
}
</style>
