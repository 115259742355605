<template>
  <div>
<div>
  <el-card>
    <div>
      <el-button size="small" @click="$router.push('/elevatorMonitor')">升降机安全监控</el-button>
      <el-button size="small" @click="$router.push('/towerMonitor')" >塔机安全监控</el-button>
      <el-button size="small" @click="$router.push('/unloadingMonitor')" >卸料平台</el-button>
      <el-button size="small" @click="$router.push('/electricMonitor')" >电能监控</el-button>
    </div>
  </el-card>
</div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "deviceMonitorDetail"
}
</script>

<style scoped>

</style>
