<template>
  <div class="ksh">
    <loading v-if="isLoading"/>
    <top-header :name="name"/>
    <div class="visual">
      <div class="visual_left">
        <left-one-state :state-one="stateOne"/>
        <left-two-state :state-two="stateTwo"/>
        <left-three-state :state-three="stateThree"/>
      </div>
      <div class="visual_con">
        <key-param :key-param="keyParam"/>
        <cartoon :cartoon-state-percent="cartoonStatePercent" :data-time="dataTime"
                 :anti-collisions="antiCollisions" :mac-info="macInfo"/>
      </div>
      <div class="visual_right">
        <right-one-state :right-one-state="rightOneState"/>
        <right-two-state :right-two-state="rightTwoState"/>
        <right-three-state :right-three-state="rightThreeState"/>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "../../components/MacState/loading";
import topHeader from "../../components/MacState/topHeader";
import keyParam from "../../components/MacState/keyParam";
import cartoon from "../../components/TowerState/cartoon";
import leftOneState from "../../components/TowerState/leftOneState";
import leftTwoState from "../../components/TowerState/leftTwoState";
import leftThreeState from "../../components/TowerState/leftThreeState";
import rightOneState from "../../components/TowerState/rightOneState";
import rightTwoState from "../../components/TowerState/rightTwoState";
import rightThreeState from "../../components/TowerState/rightThreeState";
import moment from "moment";
import mqtt from "mqtt";

export default {
  name: "towerState",
  components: {
    loading,
    topHeader,
    leftOneState,
    leftTwoState,
    leftThreeState,
    keyParam,
    cartoon,
    rightOneState,
    rightTwoState,
    rightThreeState,
  },
  data() {
    return {
      connection: {
        host: this.COMMON.mqttHost,
        port: this.COMMON.mqttPort,
        //endpoint: '',
        endpoint: '/mqtt',
        clean: true, // 保留会话
        connectTimeout: 4000, // 超时时间
        reconnectPeriod: 4000, // 重连时间间隔
        // 认证信息
        clientId: this.randomString(10),
        username: this.COMMON.mqttUserName,
        password: this.COMMON.mqttPassword,
        //password: 'public',
      },
      subscription: {
        topic: "/guide/"+this.$route.query.deviceId+"/data",
        qos: 0,
      },
      //receiveNews: '',
      subscribeSuccess: false,
      isLoading: false,
      macId: this.$route.query.deviceId,
      keyParam: [],//9 10 11 12 13
      stateOne: [],//14 15 16 17 18 19 20 21
      stateTwo: [],//22 23 24 25 26 27 28 29
      stateThree: [],//30 31 32 33 34 35 36 37
      rightOneState: [],//19
      rightTwoState: [],//20
      rightThreeState: [],//21
      cartoonState: [],//塔机动画  hight 9,  radius 10, towerBody 17, brachium 18
      cartoonStatePercent:[],
      //chNames: {},
      macInfo:{
        ID:this.$route.query.deviceId
      },
      name: '',//this.$route.query.deviceId+"#塔机",
      dataTime: '',
      towerBody:'',
      brachium:'',
      antiCollisions:[]
    }
  },
  created() {

    this.cartoonStatePercent=[50,50]
        /*{
      hight:9,
      radius:10,
      towerBody:17,
      brachium:18
    }*/

    this.getRealInfo()
  },
  methods:{
    //随机数
    randomString(len) {
      len = len || 32;
      const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      const maxPos = $chars.length;
      let pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    createConnection() {
      const { host, port, endpoint, ...options } = this.connection
      const connectUrl = `ws://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
      this.client.on('connect', () => {
        console.log('Connection succeeded!')
        console.log(this.connection.clientId)
        const { topic, qos } = this.subscription
        this.client.subscribe(topic, qos, (error, res) => {
          if (error) {
            console.log('Subscribe to topics error', error)
            return
          }
          this.subscribeSuccess = true
          console.log('Subscribe to topics res', res)
        })
      })
      this.client.on('error', error => {
        console.log('Connection failed', error)
      })
      this.client.on('message', (topic, message) => {
        //this.receiveNews = this.receiveNews.concat(message)
        //console.log('收到来自', topic, '的消息', message.toString())
        //console.log(`Received message ${message} from topic ${topic}`)

        this.binderData(message.toString())
      })
      // 断开发起重连
      /*        this.client.on('reconnect', (error) => {
                console.log('正在重连:', error)
              })*/

    },
    getRealInfo: function (){
      let jsStr= '';
      this.$axios.post(`/sys/msgLiveDataTower/info/${this.$route.query.deviceId}`).then((response) => {
        if (response.data.result === 'success') {
          jsStr = response.data.info;
          let jsonData=JSON.parse(jsStr);
          this.name = jsonData.deviceName;//设备名称
          this.binderData(jsStr);
        }
      });
      this.createConnection();
    },
    binderData(jsData){
      if(jsData.length==0){
        return;
      }
      let _this=this;
      let jsonData=JSON.parse(jsData);
      let msgStr = jsonData.json;
      let json = JSON.parse(msgStr);
      console.log(json);
      _this.dataTime = '';
      _this.keyParam = [];
      _this.stateOne = [];
      _this.stateTwo = [];
      _this.stateThree = [];
      _this.rightOneState = [];
      _this.rightTwoState = [];
      _this.rightThreeState = [];
      _this.cartoonStatePercent=[json.heightPercentage/100,json.amplitudePercentage/100]
      _this.dataTime = moment(json.createTime).format("YYYY-MM-DD HH:mm:ss");//更新时间
      _this.keyParam.push({name:'实时高度',value:json.height,rule:'m'})
      _this.keyParam.push({name:'实时幅度',value:json.amplitude,rule:'m'})
      _this.keyParam.push({name:'回转',value:json.turn,rule:'°'})
      _this.keyParam.push({name:'实时载重',value:json.weight,rule:'t'})
      _this.keyParam.push({name:'载重百分比',value:json.weightPercentage,rule:'%'})
      //状态1
      _this.stateOne.push({name:'风速',value:json.windVelocity,rule:'m/s'})
      _this.stateOne.push({name:'当前允许载重',value:json.allowWeight,rule:'吨'})
      _this.stateOne.push({name:'倾斜',value:json.incidence,rule:'°'})
      _this.stateOne.push({name:'工作状态',value:json.workStatus===1?'工作中':'空闲'})
      _this.stateOne.push({name:'实时高度',value:json.height,rule:'m'})
      _this.stateOne.push({name:'实时幅度',value:json.amplitude,rule:'m'})
      _this.stateOne.push({name:'回转',value:json.turn,rule:'°'})
      //状态2
      _this.stateTwo.push({name:'实时载重',value:json.weight,rule:'t'})
      _this.stateTwo.push({name:'载重百分比',value:json.weightPercentage,rule:'%'})
      _this.stateTwo.push({name:'高度百分比',value:json.heightPercentage,rule:'%'})
      _this.stateTwo.push({name:'幅度百分比',value:json.amplitudePercentage,rule:'%'})
      _this.stateTwo.push({name:'大臂长度',value:json.boomLength,rule:'m'})
      //状态3
      if(json.zoneProtectionUp===1){
        _this.stateThree.push({name:'干涉上预警',value:_this.alarmType(json.zoneProtectionUp,3)})//预警
        _this.stateThree.push({name:'干涉上报警',value:"正常"})//报警
      } else if(json.zoneProtectionUp===2){
        _this.stateThree.push({name:'干涉上预警',value:"正常"})//预警
        _this.stateThree.push({name:'干涉上报警',value:_this.alarmType(json.zoneProtectionUp,3)})//预警
      } else{
        _this.stateThree.push({name:'干涉上预警',value:"正常"})//预警
        _this.stateThree.push({name:'干涉上报警',value:"正常"})//报警
      }
      if(json.zoneProtectionDown===1){
        _this.stateThree.push({name:'干涉下预警',value:_this.alarmType(json.zoneProtectionDown,3)})//预警
        _this.stateThree.push({name:'干涉下报警',value:"正常"})//报警
      } else if(json.zoneProtectionDown===2){
        _this.stateThree.push({name:'干涉下预警',value:"正常"})//预警
        _this.stateThree.push({name:'干涉下报警',value:_this.alarmType(json.zoneProtectionDown,3)})//预警
      } else{
        _this.stateThree.push({name:'干涉下预警',value:"正常"})//预警
        _this.stateThree.push({name:'干涉下报警',value:"正常"})//报警
      }
      if(json.zoneProtectionLeft===1){
        _this.stateThree.push({name:'干涉左预警',value:_this.alarmType(json.zoneProtectionLeft,3)})//预警
        _this.stateThree.push({name:'干涉左报警',value:"正常"})//报警
      } else if(json.zoneProtectionLeft===2){
        _this.stateThree.push({name:'干涉左预警',value:"正常"})//预警
        _this.stateThree.push({name:'干涉左报警',value:_this.alarmType(json.zoneProtectionLeft,3)})//预警
      } else{
        _this.stateThree.push({name:'干涉左预警',value:"正常"})//预警
        _this.stateThree.push({name:'干涉左报警',value:"正常"})//报警
      }
      if(json.zoneProtectionRight===1){
        _this.stateThree.push({name:'干涉右预警',value:_this.alarmType(json.zoneProtectionRight,3)})//预警
        _this.stateThree.push({name:'干涉右报警',value:"正常"})//报警
      } else if(json.zoneProtectionRight===2){
        _this.stateThree.push({name:'干涉右预警',value:"正常"})//预警
        _this.stateThree.push({name:'干涉右报警',value:_this.alarmType(json.zoneProtectionRight,3)})//预警
      } else{
        _this.stateThree.push({name:'干涉右预警',value:"正常"})//预警
        _this.stateThree.push({name:'干涉右报警',value:"正常"})//报警
      }

      //状态4 幅度报警
      if(json.alarmAmplitude===1){
        _this.rightOneState.push({name:'近端限位预警',value:_this.alarmAmplitudeType(json.alarmAmplitude)})//预警
        _this.rightOneState.push({name:'近端限位报警',value:"正常"})
        _this.rightOneState.push({name:'远端限位预警',value:"正常"})
        _this.rightOneState.push({name:'远端限位报警',value:"正常"})
      } else if(json.alarmAmplitude===2){
        _this.rightOneState.push({name:'近端限位预警',value:"正常"})//预警
        _this.rightOneState.push({name:'近端限位报警',value:_this.alarmAmplitudeType(json.alarmAmplitude)})
        _this.rightOneState.push({name:'远端限位预警',value:"正常"})
        _this.rightOneState.push({name:'远端限位报警',value:"正常"})
      } else if(json.alarmAmplitude===3){
        _this.rightOneState.push({name:'近端限位预警',value:"正常"})//预警
        _this.rightOneState.push({name:'近端限位报警',value:"正常"})
        _this.rightOneState.push({name:'远端限位预警',value:_this.alarmAmplitudeType(json.alarmAmplitude)})
        _this.rightOneState.push({name:'远端限位报警',value:"正常"})
      } else if(json.alarmAmplitude===4){
        _this.rightOneState.push({name:'近端限位预警',value:"正常"})//预警
        _this.rightOneState.push({name:'近端限位报警',value:"正常"})
        _this.rightOneState.push({name:'远端限位预警',value:"正常"})
        _this.rightOneState.push({name:'远端限位报警',value:_this.alarmAmplitudeType(json.alarmAmplitude)})
      } else{
        _this.rightOneState.push({name:'近端限位预警',value:"正常"})//预警
        _this.rightOneState.push({name:'近端限位报警',value:"正常"})
        _this.rightOneState.push({name:'远端限位预警',value:"正常"})
        _this.rightOneState.push({name:'远端限位报警',value:"正常"})
      }
      // 回转报警
      if(json.alarmTurn===1){
        _this.rightOneState.push({name:'左限位预警',value:_this.alarmTurnType(json.alarmTurn)})//预警
        _this.rightOneState.push({name:'左限位报警',value:"正常"})
        _this.rightOneState.push({name:'右限位预警',value:"正常"})
        _this.rightOneState.push({name:'右限位报警',value:"正常"})
      } else if(json.alarmTurn===2){
        _this.rightOneState.push({name:'左限位预警',value:"正常"})//预警
        _this.rightOneState.push({name:'左限位报警',value:_this.alarmTurnType(json.alarmTurn)})
        _this.rightOneState.push({name:'右限位预警',value:"正常"})
        _this.rightOneState.push({name:'右限位报警',value:"正常"})
      } else if(json.alarmTurn===3){
        _this.rightOneState.push({name:'左限位预警',value:"正常"})//预警
        _this.rightOneState.push({name:'左限位报警',value:"正常"})
        _this.rightOneState.push({name:'右限位预警',value:_this.alarmTurnType(json.alarmTurn)})
        _this.rightOneState.push({name:'右限位报警',value:"正常"})
      } else if(json.alarmTurn===4){
        _this.rightOneState.push({name:'左限位预警',value:"正常"})//预警
        _this.rightOneState.push({name:'左限位报警',value:"正常"})
        _this.rightOneState.push({name:'右限位预警',value:"正常"})
        _this.rightOneState.push({name:'右限位报警',value:_this.alarmTurnType(json.alarmTurn)})
      } else{
        _this.rightOneState.push({name:'左限位预警',value:"正常"})//预警
        _this.rightOneState.push({name:'左限位报警',value:"正常"})
        _this.rightOneState.push({name:'右限位预警',value:"正常"})
        _this.rightOneState.push({name:'右限位报警',value:"正常"})
      }
      //状态5
      if(json.alarmHeight===1){
        _this.rightTwoState.push({name:'高度预警',value:_this.alarmType(json.alarmHeight,3)})//预警
        _this.rightTwoState.push({name:'高度报警',value:"正常"})//报警
      } else if(json.alarmHeight===2){
        _this.rightTwoState.push({name:'高度预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'高度报警',value:_this.alarmType(json.alarmHeight,3)})//预警
      } else{
        _this.rightTwoState.push({name:'高度预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'高度报警',value:"正常"})//报警
      }
      if(json.alarmWindVelocity===1){
        _this.rightTwoState.push({name:'风速预警',value:_this.alarmType(json.alarmWindVelocity,3)})//预警
        _this.rightTwoState.push({name:'风速报警',value:"正常"})//报警
      } else if(json.alarmWindVelocity===2){
        _this.rightTwoState.push({name:'风速预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'风速报警',value:_this.alarmType(json.alarmWindVelocity,3)})//预警
      } else{
        _this.rightTwoState.push({name:'风速预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'风速报警',value:"正常"})//报警
      }
      if(json.alarmIncidence===1){
        _this.rightTwoState.push({name:'倾斜预警',value:_this.alarmType(json.alarmIncidence,3)})//预警
        _this.rightTwoState.push({name:'倾斜报警',value:"正常"})//报警
      } else if(json.alarmIncidence===2){
        _this.rightTwoState.push({name:'倾斜预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'倾斜报警',value:_this.alarmType(json.alarmIncidence,3)})//预警
      } else{
        _this.rightTwoState.push({name:'倾斜预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'倾斜报警',value:"正常"})//报警
      }
      if(json.alarmWeight===1){
        _this.rightTwoState.push({name:'载重预警',value:_this.alarmType(json.alarmWeight,3)})//预警
        _this.rightTwoState.push({name:'载重报警',value:"正常"})//报警
        _this.rightTwoState.push({name:'载重违章',value:"正常"})//报警
      } else if(json.alarmWeight===2){
        _this.rightTwoState.push({name:'载重预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'载重报警',value:_this.alarmType(json.alarmWeight,3)})//预警
        _this.rightTwoState.push({name:'载重违章',value:"正常"})//报警
      } else if(json.alarmWeight===3){
        _this.rightTwoState.push({name:'载重预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'载重报警',value:"正常"})//预警
        _this.rightTwoState.push({name:'载重违章',value:_this.alarmType(json.alarmWeight,3)})//报警
      } else{
        _this.rightTwoState.push({name:'载重预警',value:"正常"})//预警
        _this.rightTwoState.push({name:'载重报警',value:"正常"})//报警
        _this.rightTwoState.push({name:'载重违章',value:"正常"})//报警
      }
      //碰撞预警
      if(json.collisionProofUp===1){
        _this.rightThreeState.push({name:'碰撞上预警',value:_this.alarmType(json.collisionProofUp,3)})//预警
        _this.rightThreeState.push({name:'碰撞上报警',value:"正常"})//报警
      } else if(json.collisionProofUp===2){
        _this.rightThreeState.push({name:'碰撞上预警',value:"正常"})//预警
        _this.rightThreeState.push({name:'碰撞上报警',value:_this.alarmType(json.collisionProofUp,3)})//预警
      } else{
        _this.rightThreeState.push({name:'碰撞上预警',value:"正常"})//预警
        _this.rightThreeState.push({name:'碰撞上报警',value:"正常"})//报警
      }
      if(json.collisionProofDown===1) {
        _this.rightThreeState.push({name: '碰撞下预警', value: _this.alarmType(json.collisionProofDown, 3)})
        _this.rightThreeState.push({name: '碰撞下报警', value: "正常"})//报警
      } else if(json.collisionProofDown===2) {
        _this.rightThreeState.push({name: '碰撞下预警', value: "正常"})
        _this.rightThreeState.push({name: '碰撞下报警', value: _this.alarmType(json.collisionProofDown, 3)})//报警
      } else {
        _this.rightThreeState.push({name: '碰撞下预警', value: "正常"})//预警
        _this.rightThreeState.push({name: '碰撞下报警', value: "正常"})//报警
      }
      if(json.collisionProofLeft===1) {
        _this.rightThreeState.push({name: '碰撞左预警', value: _this.alarmType(json.collisionProofLeft, 3)})
        _this.rightThreeState.push({name: '碰撞左报警', value: "正常"})//报警
      } else if(json.collisionProofLeft===2) {
        _this.rightThreeState.push({name: '碰撞左预警', value: "正常"})
        _this.rightThreeState.push({name: '碰撞左报警', value: _this.alarmType(json.collisionProofLeft, 3)})//报警
      } else {
        _this.rightThreeState.push({name: '碰撞左预警', value: "正常"})//预警
        _this.rightThreeState.push({name: '碰撞左报警', value: "正常"})//报警
      }
      if(json.collisionProofRight===1) {
        _this.rightThreeState.push({name: '碰撞右预警', value: _this.alarmType(json.collisionProofRight, 3)})
        _this.rightThreeState.push({name: '碰撞右报警', value: "正常"})//报警
      } else if(json.collisionProofRight===2) {
        _this.rightThreeState.push({name: '碰撞右预警', value: "正常"})
        _this.rightThreeState.push({name: '碰撞右报警', value: _this.alarmType(json.collisionProofRight, 3)})//报警
      } else {
        _this.rightThreeState.push({name: '碰撞右预警', value: "正常"})//预警
        _this.rightThreeState.push({name: '碰撞右报警', value: "正常"})//报警
      }
      //底部参数
      _this.macInfo = {
        windVelocity:json.windVelocity,
        weightPercentage:json.weightPercentage,
        heightPercentage:json.heightPercentage,
        amplitudePercentage:json.amplitudePercentage,
      };
      let towerCount= json.towerCount;
      //console.log("塔机个数:"+towerCount)
      //console.log("antiCollisions:"+this.antiCollisions.length)
      //如果第一次加载，画出相应的图形
      if (towerCount>=0){//this.antiCollisions.length===0
        this.antiCollisions = [];
        //本机
        //本机坐标x
        let theCoordinateX=json.coordinateX
        //本机坐标y
        let theCoordinateY=json.coordinateY
        let boomLength=json.boomLength;
        let device={
          devNum:json.towerNumber,//塔机通信编号
          length:boomLength, //本机大臂长度
          angle:(json.turn),  //本机回转角度
          amplitude:json.amplitude, //本机小车幅度
          xOffset:(boomLength+0), //本车定位
          yOffset:(boomLength+0)  //本车定位
        }
        //console.log(json);
        this.antiCollisions.push(device)
        //碰撞关系的机器
        for(let i=1;i<towerCount+1;i++){
          let devNum=json['towerNumber'+i]//塔机通信编号
          let coordinateX=json['coordinate'+i+'X']  //碰撞关系机器坐标x
          let coordinateY=json['coordinate'+i+'Y']  //碰撞关系机器坐标y
          let xOffset=coordinateX-theCoordinateX //碰撞关系机器x偏移
          let yOffset=coordinateY-theCoordinateY //碰撞关系机器y偏移
          let boomLength=json['boomLength'+i] //碰撞关系大臂长度
          let angle=(json['turn'+i])  //碰撞关系机器角度
          let amplitudePercent=json['amplitudePercent'+i]   //碰撞关系幅度百分比
          let amplitude=amplitudePercent*boomLength;  //碰撞关系幅度

          let antiCollision={
            devNum:devNum,
            length:boomLength,
            angle:angle,
            amplitude:amplitude,
            xOffset:(boomLength-xOffset),
            yOffset:(boomLength+yOffset)
          }
          //console.log("coordinate1X:"+coordinateX);
          //console.log(antiCollision);
          this.antiCollisions.push(antiCollision)
        }
      }/*else{//图形已加载 动画
        console.log("antiCollisions:"+this.antiCollisions);
        this.antiCollisions[0].amplitude=json.amplitude
        this.antiCollisions[0].angle=json.turn
        console.log("json:"+JSON.stringify(json));
        for (let i=1;i<towerCount+1;i++){
          let boomLength1=json['boomLength'+i]
          let angle1=json['turn'+i]
          //console.log("angle1:"+angle)
          let amplitudePercent1=json['amplitudePercent'+i]
          let amplitude1=amplitudePercent1*boomLength1;
          console.log("boomLength:"+boomLength1);
          console.log("angle:"+angle1);
          console.log("amplitudePercent:"+amplitudePercent1);
          console.log("amplitude:"+amplitude1);
          this.antiCollisions[i].amplitude=amplitude1
          this.antiCollisions[i].angle=angle1
        }
      }*/
    },
    alarmTurnType(value){
      if (value===0){
        return '正常'
      }else if (value===1){
        return '左限位预警'
      }else if (value===2){
        return '左限位报警'
      }else if (value===3){
        return '右限位预警'
      }else if (value===4){
        return '右限位报警'
      }
    },
    alarmAmplitudeType(value){
      if (value===0){
        return '正常'
      }else if(value===1){
        return '近端限位预警'
      }else if(value===2){
        return '近端限位报警'
      }else if (value===3){
        return '远端限位预警'
      }else if (value===4){
        return '远端限位报警'
      }
    },
    alarmType(value,type){
      if (type===3){
        if(value===0){
          return '正常'
        }else if(value===1){
          return '预警'
        }else if (value===2){
          return '报警'
        }else if (value===3){
          return '违章'
        }
      }
    }
  }
}
</script>

<style>
@import "./static/css/index.css";
@import "./static/css/visual.css";
</style>
