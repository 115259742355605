<template>
    <div id="load">
        <div class="load_img"><!-- 加载动画 -->
            <img class="jzxz1">
            <img class="jzxz2">
        </div>
    </div>
</template>

<script>
    export default {
        name: "loading"
    }
</script>
