<template>
  <div style="text-align: left">
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['角色管理']">
      <template slot="controls">
        <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline">新增</el-button>
      </template>
    </crumbs-bar>

    <el-row style="margin: 0 20px 0 10px;">
      <el-col>
        <el-table :data="page.records" stripe highlight-current-row>
          <el-table-column type="index" label="序号" width="80px"></el-table-column>
          <el-table-column prop="name" label="角色名称"></el-table-column>
          <!-- <el-table-column label="权限">
            <template v-slot="scope">
              <div v-for="(item,index) in scope.row.permissions" :key="index">
                {{item.code}} |{{item.name}}
              </div>
            </template>
          </el-table-column>-->
          <el-table-column prop="remarks" label="角色描述" width="300px"></el-table-column>
          <el-table-column label="操作" width="150px">
            <template slot-scope="scope">
              <el-button type="primary" @click="edit(scope.row)" size="mini">修改</el-button>
              <el-button type="danger" @click="del(scope.row.id)" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-pagination style="margin: 20px 0 0 10px"
      :page-sizes=this.$pageSizes
      :page-size=page.size
      :total=page.total
      :current-page=page.current
      layout="total,sizes,prev,pager,next,jumper">
    </el-pagination>
    <add-edit-dialog v-on:saveSuccess="loadTable" :visible.sync="addEditDialogVisible" :title="addEditDialogTitle" :formData="formData"></add-edit-dialog>
    </div>
</template>

<script>
import addEditDialog from "./addEditDialog";
export default {
  name: "role",
  components:{addEditDialog},
  data(){
    return {
      addEditDialogVisible:false,
      addEditDialogTitle:'',
      formData:{},
      page:{
        records:[],
        current:0,
        size:0,
        total:0,
        pages:0
      },
      pageQuery:{
        size:this.$pageSize,
        current: 1
      }
    }
  },
  created() {
    this.loadTable()
  },
  methods:{
    loadTable(){
      this.$axios.post('/sys/role/list',this.pageQuery).then((response)=>{
        this.page=response.data
      })
    },
    add(){
      this.addEditDialogTitle='新增角色'
      this.formData={}
      this.addEditDialogVisible=true
    },
    edit(row){
      this.formData=row;
      this.addEditDialogTitle='编辑角色'
      this.addEditDialogVisible=true
    },
    del(id){
      this.$confirm('确定删除该角色么','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=>{
        this.$axios.post(`/sys/role/del/${id}`).then((response)=>{
          if(response.data.result==='success'){
            this.$message.success('删除成功')
            this.loadTable()
          }else {
            this.$message.error('删除失败')
          }
        })
      }).catch(()=>{
        this.$message.info('取消删除')
      })

    },
    handleRefresh(){
      this.loadTable()
    }
  }
}
</script>

<style scoped>

</style>
