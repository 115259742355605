<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['人脸识别设备管理']">
    </crumbs-bar>
    <el-container>
      <el-container>
        <el-aside class="elaside">
          <my-tree></my-tree>
        </el-aside>
        <el-main style="padding: 0 0 0 5px;">
          <fy-face-table></fy-face-table>
        </el-main>
      </el-container>
    </el-container>
    <fy-face-driver></fy-face-driver>
  </div>
</template>

<script>
import myTree from "@/views/consoleDevice/myTree";
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
import {EventBus} from "@/views/consoleDevice/event-bus";
import FyFaceDriver from "./fyFaceDriver";
import FyFaceTable from "./fyFaceTable";

export default {
  name: "fyFaceDevice",
  components:{
    FyFaceTable,
    FyFaceDriver,
    myTree
  },
  data(){
    return{
      comId: JSON.parse(window.localStorage.getItem('userInfo')).comId,
      consoleDeviceStore
    }
  },
  mounted(){

  },
  methods:{
    handleRefresh(){
      EventBus.$emit('refresh','刷新')
    }
  }
}
</script>

<style scoped>
.elaside{
  padding-left: 5px;
  padding-top: 10px;
  border-right:1px dashed #b6b6b6;
  height: 88vh;
}
</style>
