<template>
  <div class="prbody">
    <el-row>
      <el-col>
        <el-card class="layui-elevator">
          <div >
            <el-button size="small" :type="btnProject" @click="$router.push('/projectView')">项目概况</el-button>
            <el-button id="sqjButton" size="small" :type="btnElevator" @click="to('/elevatorDevice',1)">升降机安全监控</el-button>
            <el-button id="tjButton" size="small" :type="btnTower" @click="to('/towerDevice',2)">塔机安全监控</el-button>
            <el-button id="ytjButton" size="small" :type="btnAllInOne" @click="to('/allInOneDevice',4)">一体机监控</el-button>
            <el-button id="dgkshButton" size="small" :type="btnLifting" @click="to('/liftingHookDevice',5)">吊钩可视化监控</el-button>
            <el-button id="xlptButton" size="small" :type="btnUnloading" @click="to('/unloadingDevice',3)">卸料平台安全监控</el-button>
            <!-- <el-button size="small" @click="to('/electricDevice')">电能监控</el-button>-->
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "someAProject",
  props:{
    projectId:String
  },
  data(){
    return{
      btnProject:'',
      btnElevator:'primary',
      btnTower:'',
      btnAllInOne:'',
      btnLifting:'',
      btnUnloading:'',
      tabFlag:''
    }
  },
  created:function (){
    var url = window.location.href;
    if(url.indexOf("elevatorDevice")!=-1){
      this.tabChang(1);
    }
    if(url.indexOf("towerDevice")!=-1){
      this.tabChang(2);
    }
    if(url.indexOf("unloadingDevice")!=-1){
      this.tabChang(3);
    }
    if(url.indexOf("allInOneDevice")!=-1){
      this.tabChang(4);
    }
    if(url.indexOf("liftingHookDevice")!=-1){
      this.tabChang(5);
    }
  },
  methods:{
    to(url,val){
      this.tabFlag = val;
      this.tabChang(val);//tab按钮变化
      this.$router.push({
        path:`${url}/${this.projectId}`
      })
    },
    tabChang(val){
      if(val===1){
        this.btnElevator='primary';
        this.btnTower='';
        this.btnAllInOne='';
        this.btnLifting='';
        this.btnUnloading='';
      }
      if(val===2){
        this.btnElevator='';
        this.btnTower='primary';
        this.btnAllInOne='';
        this.btnLifting='';
        this.btnUnloading='';
      }
      if(val===3){
        this.btnElevator='';
        this.btnTower='';
        this.btnAllInOne='';
        this.btnLifting='';
        this.btnUnloading='primary';
      }
      if(val===4){
        this.btnElevator='';
        this.btnTower='';
        this.btnAllInOne='primary';
        this.btnLifting='';
        this.btnUnloading='';
      }
      if(val===5){
        this.btnElevator='';
        this.btnTower='';
        this.btnAllInOne='';
        this.btnLifting='primary';
        this.btnUnloading='';
      }
    }
  }
}
</script>

<style scoped>
.prbody{
  min-height:100%;width: 100vw;color: #fff; font-size: 14px; padding-top: 15px;
  background-color: #00094E;
  background-repeat: repeat-y;
  background-image: url(../../assets/pro/img/bg.png);background-size: 100% 100%;
}

.layui-elevator{
  height: 100%; background: rgba(0, 124, 249, 0.1);
  border: 1px solid #0056A1; border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
</style>
