<!--
卸料平台 历史重量数据
-->
<template>
  <div class="visual_conBot_un">
    <img class="visual_conBot_l" alt="" src="../../views/dataV/static/images/ksh42.png">
    <img class="visual_conBot_2" alt="" src="../../views/dataV/static/images/ksh43.png">
    <img class="visual_conBot_3" alt="" src="../../views/dataV/static/images/ksh44.png">
    <img class="visual_conBot_4"  alt="" src="../../views/dataV/static/images/ksh45.png">
    <div class="anti-bottom" id="unloadingTwoChart">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftTwoState",
  props: ["macInfo", "macId"],
  data(){
    return{
      lineDataLabel:[],
      lineDataValue:[],
      devInfo:{}
    }
  },
  mounted() {
    this.devStatus();
  },
  watch: {
    macInfo: function (newVal) {
      this.devInfo = newVal;
      this.lineDataLabel.push(this.devInfo.createTime);//时间
      this.lineDataValue.push(this.devInfo.weight);//重量值
      console.log("状态值："+this.devInfo.state);
      this.devStatus();
    }
  },
  methods: {
    devStatus(){
      var unloadingTwoChart=document.getElementById('unloadingTwoChart');
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(unloadingTwoChart)

      //let label = ['18日', '19日', '20日', '21日', '22日', '23日', '24日'];
      //let value = [233, 200, 180, 199, 233, 210, 180];

      myChart.setOption({
        grid: {
          top: '10%',
          left: '2%',
          right: '2%',
          bottom:'5%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,255,255,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255,255,255,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,255,255,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          }
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              formatter: '{value}',
              fontSize: 14,
              margin: 20,
              textStyle: {
                color: '#7ec7ff',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#243753',
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#243753',
              },
            },
            axisTick: {
              show: false,
            },
            data: this.lineDataLabel,
          },
        ],
        yAxis: [
          {
            boundaryGap: false,
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#7ec7ff',
              },
            },
            nameTextStyle: {
              color: '#fff',
              fontSize: 12,
              lineHeight: 40,
            },
            splitLine: {
              lineStyle: {
                color: '#243753',
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#283352',
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '卸料载重重量',
            type: 'line',
            smooth: true,
            showSymbol: true,
            symbolSize: 8,
            zlevel: 3,
            itemStyle: {
              color: '#19a3df',
              borderColor: '#a3c8d8',
            },
            lineStyle: {
              normal: {
                width: 6,
                color: '#19a3df',
              },
            },
            data: this.lineDataValue,
          },
        ]
      });
    }
  }
}
</script>
<style scoped>
.anti-bottom{
  width:100%;
  height:100%;
  /*background:rgba(16,54,87,0.5);
  border:1px solid #345f92;*/
  position:absolute;
  z-index:0;
}
</style>
