<!--
  名称：查询设备历史曲线
  描述：按日期和设备号查询，可以导出excel报表 ,动态加载列
-->
<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['历史曲线']">
    </crumbs-bar>
    <el-container>
      <!--部门数据-->
      <el-aside class="elaside">
        <el-tree highlight-current lazy
            :load="loadNode"
            :props="defaultProps"
            @node-click="handleNodeClick"
        />
      </el-aside>
      <el-main style="padding: 0 0 0 5px;">
        <el-form :inline="true" :model="pageQuery" :rules="rules" ref="pageQuery" style="margin-top: 10px;">
          <el-form-item style="margin-left: 5px;" required label="设备编号" prop="deviceId">
            <el-input v-model="pageQuery.deviceId" readonly placeholder="设备编号"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="demonstration">日期</span>
            <el-date-picker
              style="margin-right: 30px;"
              value-format="yyyy-MM-dd"
              v-model="pageQuery.datetime"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button v-loading.body="historyListLoading" type="primary" @click="queryClick($event)">查询</el-button>
          </el-form-item>
        </el-form>
        <div id="historyCurve" style="width: 100%;height: 90%; min-height: 650px;"></div>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";

export default {
  name: "historyCurve",
  data() {
    return {
      consoleDeviceStore,
      export_percentage: -1,
      rules: {
        code: [
          {required: true, message: '请选择一个设备', trigger: 'blur'}
        ]
      },//查询表单验证
      readonly: true,
      historyListLoading: false,
      //listLoading: false,//数据加载等待动画
      pageQuery:{
        datetime: this.getDate(),
        deviceId: undefined,
        type:0
      },
      defaultProps: {
        id:'id',
        label:'name',
        isLeaf:'leaf',
        type:"type"
      },
      url1:'/sys/company/listIdName',
      url2:'/sys/project/listByCompany',
      url3:'/sys/area/listByProject',
      url4:'/sys/device/listByAreaId',
      legendData100: ['高度','幅度','回转','载重','风速','倾斜'],
      legendData101: ['重量','高度','速度','x倾角','y倾角','人数','楼层'],
      legendData102: ['重量','倾角x','倾角y','电量'],
      devName:'',
      devType:0
    }
  },
  methods: {
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      //var hour = " 00:00:00"; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;//
      return defaultDate;
    },
    /*异步加载节点*/
    loadNode(node,resolve){
      switch (node.level){
        case 0:
          var userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
          var data= {
            comId: userInfo.comId
          }
          this.$axios.post(this.url1,data).then((response)=>{
            resolve(response.data.length==0?[]:response.data)
          });
          break
        case 1:
          this.resolveChildren(this.url2+'/'+node.data.id,resolve,false)
          break
        case 2:
          this.resolveChildren(this.url3+'/'+node.data.id,resolve,false)
          break
        case 3:
          this.resolveChildren(this.url4+'/'+node.data.id,resolve,true)
          break
        default:
          break
      }
    },
    resolveChildren(url,resolve,isLeaf){
      this.$axios.post(url).then((response)=>{
        if (isLeaf){
          response.data.forEach((item)=>{
            item.leaf=true
          })
        }
        resolve(response.data.length==0?[]:response.data)
      })
    },
    // 节点单击事件
    handleNodeClick(data, node) {
      let level = node.level;
      //1级为公司，2为项目，3为区域
      if (level === 4) {
        //更新store中的数据
        let org={
          companyId:node.parent.parent.parent.data.id,
          projectId:node.parent.parent.data.id,
          areaId:node.parent.data.id,
          companyName:node.parent.parent.parent.data.name,
          projectName:node.parent.parent.data.name,
          areaName:node.parent.data.name
        }
        consoleDeviceStore.setOrg(org)

        this.devName = data.name;
        this.devType = data.type;
        this.getList(data.id,data.type)
      }
    },
    queryClick(evt) {
      let target = evt.target
      if (target.nodeName === "SPAN") {
        target = evt.target.parentNode
      }
      target.blur()
      this.getList(this.pageQuery.deviceId,this.devType);
    },
    getList(deviceId,type) {
      this.pageQuery.deviceId = deviceId;
      this.$refs['pageQuery'].validate((valid) => {
        if (valid) {
          this.historyListLoading = true;
          this.pageQuery.comId = consoleDeviceStore.state.org.companyId;
          this.pageQuery.proId = consoleDeviceStore.state.org.projectId;
          this.pageQuery.type = this.devType;
          this.$axios.post("/sys/statistics/getHistoryCurveListByCode",this.pageQuery).then((response) => {
            this.historyListLoading = false;
            if(type==100||type==103){
              this.towerHistoryCurve(response.data);//塔机
            }
            if(type==101){
              this.elevatorHistoryCurve(response.data);//升降机
            }
            if(type==102){
              this.unloadingHistoryCurve(response.data);//卸料平台
            }
          })
          this.historyListLoading = false;
        } else {
          return false;
        }
      })
    },
    towerHistoryCurve(hisData) {
      let oneData=[],twoData=[],threeData=[],fourData=[],fiveData=[],sixData=[],timeData=[];
      hisData.records.forEach((item)=>{
        oneData.push(item.height);//高度
        twoData.push(item.amplitude);//幅度
        threeData.push(item.turn);//回转
        fourData.push(item.weight);//载重
        fiveData.push(item.windVelocity);//风速
        sixData.push(item.incidence);//倾斜
        timeData.push(item.createTime);//时间
      })
      let myChart = this.$echarts.init(document.getElementById('historyCurve'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.devName
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,0,0,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(0,0,0,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,0,0,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          }
        },
        legend: {
          data: this.legendData100
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: timeData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '高度',
            type: 'line',
            data: oneData
          },
          {
            name: '幅度',
            type: 'line',
            data: twoData
          },
          {
            name: '回转',
            type: 'line',
            data: threeData
          },
          {
            name: '载重',
            type: 'line',
            data: fourData
          },
          {
            name: '风速',
            type: 'line',
            data: fiveData
          },
          {
            name: '倾斜',
            type: 'line',
            data: sixData
          }
        ],
        dataZoom: [ { show: false, start: 0, end: 100 },
          { type: 'inside', start: 0, end: 100 },
          { show: false, yAxisIndex: 0, filterMode: 'empty', width: 30, height: '80%', showDataShadow: false, left: '93%' }
        ]
      });
    },
    elevatorHistoryCurve(hisData) {
      let oneData=[],twoData=[],threeData=[],fourData=[],fiveData=[],sixData=[],sevenData=[],timeData=[];
      hisData.records.forEach((item)=>{
        oneData.push(item.weight);//重量
        twoData.push(item.height);//高度
        threeData.push(item.speed);//速度
        fourData.push(item.incidenceX);//x倾角
        fiveData.push(item.incidenceY);//y倾角
        sixData.push(item.peopleNumberAlarm);//人数
        sevenData.push(item.floor);//楼层
        timeData.push(item.createTime);//时间
      })
      let myChart = this.$echarts.init(document.getElementById('historyCurve'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.devName
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: this.legendData101
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: timeData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '重量',
            type: 'line',
            data: oneData
          },
          {
            name: '高度',
            type: 'line',
            data: twoData
          },
          {
            name: '速度',
            type: 'line',
            data: threeData
          },
          {
            name: 'x倾角',
            type: 'line',
            data: fourData
          },
          {
            name: 'y倾角',
            type: 'line',
            data: fiveData
          },
          {
            name: '人数',
            type: 'line',
            data: sixData
          },
          {
            name: '楼层',
            type: 'line',
            data: sevenData
          }
        ]
      });
    },
    unloadingHistoryCurve(hisData) {
      let oneData=[],twoData=[],threeData=[],fourData=[],timeData=[];
      hisData.records.forEach((item)=>{
        oneData.push(item.weight);//重量
        twoData.push(item.incidenceX);//倾角x
        threeData.push(item.incidenceY);//倾角y
        fourData.push(item.power);//电量
        timeData.push(item.createTime);//时间
      })
      let myChart = this.$echarts.init(document.getElementById('historyCurve'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.devName
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: this.legendData102
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: timeData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '重量',
            type: 'line',
            data: oneData
          },
          {
            name: '倾角x',
            type: 'line',
            data: twoData
          },
          {
            name: '倾角y',
            type: 'line',
            data: threeData
          },
          {
            name: '电量',
            type: 'line',
            data: fourData
          }
        ]
      });
    },
    handleRefresh(){
      this.getList(this.pageQuery.deviceId);
    }
  },
}
</script>

<style scoped>
.elaside{
  padding-left: 0px;
  padding-top: 10px;
  border-right:1px dashed #b6b6b6;
  height: 88vh;
}
.demonstration {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
