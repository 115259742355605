<template>
  <el-dialog :title="this.deviceTitle" :show-close="false"
    width="60%" :visible.sync="myVisible" :close-on-click-modal="false"
    @open="loadData" class="status_change" left>
    <span>
      <el-form :inline="true" :model="pageQuery" ref="pageQuery" class="demo-form-inline">
        <el-form-item>
          <span class="demonstration">请选择时间段</span>
          <el-date-picker
            style="margin-right: 30px;"
            value-format="yyyy-MM-dd HH:mm:ss"
            label="时间段"
            v-model="pageQuery.datetime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button v-loading.body="historyListLoading" type="primary" @click="queryClick($event)">查询</el-button>
        </el-form-item>
      </el-form>
    </span>
    <span>
      <el-table height="400" border :data="page.records" fit
                v-loading.body="listLoading" element-loading-text="拼命加载中"
                :row-style="{height:'44px'}" :cell-style="{padding:'4px'}" highlight-current-row>
        <el-table-column type="index" :index="indexMethod" label="序号" width="100" fixed="left"></el-table-column>
        <el-table-column label="时间戳" prop="createTime" width="170" fixed="left"></el-table-column>
        <el-table-column label="驾驶员" prop="driverName" width="120"></el-table-column>
        <el-table-column label="设备状态">
          <template v-slot="scope">
            <span v-if="scope.row.deviceStatus===0">空闲</span>
            <span v-if="scope.row.deviceStatus===1">工作</span>
          </template>
        </el-table-column>
        <el-table-column label="吊重(kg)" prop="weight" width="100"></el-table-column>
        <el-table-column label="重量(%)" prop="weightPercentage"></el-table-column>
        <el-table-column label="高度(m)" prop="height"></el-table-column>
        <el-table-column label="人数" prop="numberOfPeople"></el-table-column>
        <el-table-column label="高度(%)" prop="heightPercentage"></el-table-column>
        <el-table-column label="楼层" prop="floor"></el-table-column>
        <el-table-column label="速度" prop="speed"></el-table-column>
        <el-table-column label="运动方向">
          <template v-slot="scope">
            <span v-if="scope.row.orientation===0">静止</span>
            <span v-if="scope.row.orientation===1">上</span>
            <span v-if="scope.row.orientation===2">下</span>
          </template>
        </el-table-column>
        <el-table-column label="x倾角" prop="incidenceX"></el-table-column>
        <el-table-column label="x倾角(%)" prop="incidenceXPercentage" width="100"></el-table-column>
        <el-table-column label="y倾角" prop="incidenceY"></el-table-column>
        <el-table-column label="y倾角(%)" prop="incidenceYPercentage" width="100"></el-table-column>
      </el-table>
      <el-pagination :total="page.total"
        :current-page="page.current"
        :page-size="page.size"
        :page-sizes="this.$pageSizes" layout="total,sizes,prev,pager,next,jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"></el-pagination>
    </span>
    <span slot="footer">
      <el-button @click="refresh()" size="small">刷新</el-button>
      <el-button @click="myVisible=false" size="small">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "historyDataDialog",
  props:{
    dialogVisible:{
      type:Boolean,
      default:false
    },
    tableData:[],
    deviceId:String,
    deviceTitle:String
  },
  data(){
    return{
      page:{
        records:[],
        size:0,
        current:0,
        total:0,
        pages:0
      },
      pageQuery:{
        datetime: [],
        size:this.$pageSize,
        current:1
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近15分钟',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 0.25);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近30分钟',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 0.5);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近3小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 3);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近6小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 6);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近12小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 12);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近1个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近3个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },//日期控件
      historyListLoading: false,
      listLoading: false,//数据加载等待动画
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit("update:dialogVisible",val);
      }
    }
  },
  methods:{
    loadData(){
      this.$axios.post(`/sys/msgLiveDataElevator/list/${this.deviceId}`,this.pageQuery).then((response)=>{
        this.page=response.data
      })
    },
    handleSizeChange(size){
      this.pageQuery.size=size
      this.loadData();
    },
    handleCurrentChange(current){
      this.pageQuery.current=current
      this.loadData()
    },
    cellStyle(){
      return 'border-color: #868686;'
    },
    refresh(){
      this.loadData();
    },
    queryClick(){
      this.loadData();
    },
    indexMethod(index) {
      index = (index + 1) + (this.page.current - 1) * this.page.size
      return index
    }
  }
}
</script>

<style scoped>
.demonstration {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
