import Vue from "vue";
import VueRouter from "vue-router";
import test from "@/views/test";
import send from "@/views/send/send";
import login from "@/views/login/login";
import home from "@/views/home"
import console from "@/views/console/console";
import projectOverview from "@/views/projectOverview/projectOverview";
import projectView from "@/views/projectOverview/projectView";
import someAProject from "@/views/projectOverview/someAProject";
import dataCenter from "@/views/dataCenter/dataCenter";
import deviceManager from "@/views/deviceManager/deviceManager";
import deviceMonitor from "@/views/deviceMonitor/deviceMonitor";
import user from "@/views/consoleUser/user"
import dashboard from "@/views/consoleDashboard/dashboard";
import company from "@/views/consoleCompany/company";
import role from "@/views/consoleRole/role";
import region from "@/views/consoleArea/area";
import project from "@/views/consoleProject/project";
import overView from "@/views/projectDevice/overview/overview";
import elevator from "@/views/projectDevice/elevator/elevator";
import towerDevice from "@/views/projectDevice/towerCarane/towerCrane";
import unloadingDevice from "@/views/projectDevice/unloadingPlatform/unloadingPlatform";
import allInOneDevice from "@/views/projectDevice/allinone/allInOneCrane";
import liftingHookDevice from "@/views/projectDevice/liftingHook/liftingHookCrane"
import electricDevice from "@/views/projectDevice/electricEnergy/electricDevice";
import deviceMonitorDetail from "@/views/deviceMonitor/deviceMonitorDetail";
import elevatorMonitor from "@/views/deviceMonitor/elevatorMonitor";
import electricMonitor from "@/views/deviceMonitor/electricMonitor";
import towerMonitor from "@/views/deviceMonitor/towerMonitor";
import unloadingMonitor from "@/views/deviceMonitor/unloadingMonitor";
import elevatorState from "@/views/dataV/elevatorState";
import towerState from "@/views/dataV/towerState";
import allInOneState from "@/views/dataV/allInOneState";
import unloadingPlatState from "@/views/dataV/unloadingPlatState";
import elevatorStateFree from "@/views/dataToken/elevatorStateFree";
import towerStateFree from "@/views/dataToken/towerStateFree";
import allInOneStateFree from "@/views/dataToken/allInOneStateFree";
import unloadingPlatStateFree from "@/views/dataToken/unloadingPlatStateFree";
import oppfDevVideo from "@/views/dataV/oopfDevVideo"
import device from "@/views/consoleDevice/device";
import attribute from "@/views/consoleDevice/attribute";
import face from "@/views/consoleface/manFaceDevice";
import fykjFace from "@/views/consoleFyFace/fyFaceDevice";
import alarm from "@/views/consoleAlarm/alarmData";
import historyData from "@/views/consoleStatistics/historyData";
import historyCurve from "@/views/consoleStatistics/historyCurve";
import realtimeCurve from "@/views/consoleStatistics/realtimeCurve";
import menu from "@/views/consoleMenu/menu";
import hookData from "@/views/consoleStatistics/hookData"
import applicationData from "@/views/video/application"
import machineVideo from "@/views/video/machineVideo"
import noPassUrl from "@/views/nopass/noPassUrl"

//解决重复点击路由报错的Bug begin
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//end

Vue.use(VueRouter)

const routes=[
    {path:'/',component:login},
    {path:'/login',component: login},
    {path: '/send',component: send},
    {path:'/test',component: test},
    {path:'/elevatorState',component: elevatorState},
    {path:'/towerState',component: towerState},
    {path:'/unloadingPlatState',component: unloadingPlatState},
    {path:'/allInOneState',component: allInOneState},
    {path:'/elevatorStateFree',component: elevatorStateFree},
    {path:'/towerStateFree',component: towerStateFree},
    {path:'/unloadingPlatStateFree',component: unloadingPlatStateFree},
    {path:'/allInOneStateFree',component: allInOneStateFree},
    {path:'/oppfDevVideo',component: oppfDevVideo},
    {//path: '/home',component: home,redirect:'/projectOverview',
     path: '/home',component: home,redirect:'/projectView',
     children:[
         {path:'/projectOverview',component:projectOverview},
         {path:'/projectView',component:projectView},
         {path: '/someAProject/:projectId',component: someAProject,props:true,children: [
                 {path: '/someAProjectOverView/:projectId',component: overView,props: true},
                 {path: '/elevatorDevice/:projectId',component: elevator,props: true},
                 {path: '/electricDevice/:projectId',component: electricDevice,props: true},
                 {path: '/towerDevice/:projectId',component: towerDevice,props: true},
                 {path: '/unloadingDevice/:projectId',component: unloadingDevice,props:true},
                 {path: '/allInOneDevice/:projectId',component: allInOneDevice,props:true},
                 {path: '/liftingHookDevice/:projectId',component: liftingHookDevice,props:true},
             ]},
         {path:'/dataCenter',component: dataCenter},
         {path:'/console',component: console,children:[
                 {path:'/deviceManager',component:deviceManager},
                 {path:'/user',component: user},
                 {path:'/role',component: role},
                 {path:'/menu',component: menu},
                 {path:'/company',component: company},
                 {path:'/project',component: project},
                 {path:'/region',component: region},
                 {path:'/device',component: device},
                 {path:'/attribute',component: attribute},
                 {path:'/face',component: face},
                 {path:'/fykjFace',component: fykjFace},
                 {path:'/alarm',component: alarm},
                 {path:'/historyData',component: historyData},
                 {path:'/historyCurve',component: historyCurve},
                 {path:'/realtimeCurve',component: realtimeCurve},
                 {path:'/hookData',component: hookData},
                 {path:'/dashboard',component: dashboard},
                 {path:'/applicationData',component: applicationData},
                 {path:'/machineVideo',component: machineVideo},
                 {path:'/noPassUrl',component: noPassUrl}]},
         {path:'/deviceMonitor',component: deviceMonitor},
         {path: '/deviceMonitorDetail',component: deviceMonitorDetail,children: [
                 {path: '/elevatorMonitor',component: elevatorMonitor},
                 {path: '/electricMonitor',component: electricMonitor},
                 {path: '/towerMonitor',component: towerMonitor},
                 {path: '/unloadingMonitor',component: unloadingMonitor},
             ]},
     ]
    }
]
const router=new VueRouter({
    routes
})

export  default  router
