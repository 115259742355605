<template>
  <div class="ksh">
    <loading v-if="isLoading"/>
    <top-header :name="name"/>
    <div class="visual_unloading">
      <div class="visual_left_unloading">
        <div class="one_un">
        <leftOneState :mac-info="macInfo"/>
        </div>
      </div>
      <div class="visual_right_unloading">
        <right-one-state :mac-info="macInfo"/>
        <right-two-state :mac-info="macInfo"/>
      </div>
    </div>
    <div class="tow_dn">
      <leftTwoState :mac-info="macInfo" :mac-id="macId"/>
    </div>
  </div>
</template>

<script>
import loading from "../../components/MacState/loading";
import topHeader from "../../components/MacState/topHeader";
import leftOneState from "../../components/UnloadingPlatState/leftOneState";
import LeftTwoState from "../../components/UnloadingPlatState/leftTwoState";
import rightOneState from "../../components/UnloadingPlatState/rightOneState";
import rightTwoState from "../../components/UnloadingPlatState/rightTwoState";
import mqtt from "mqtt";

export default {
  name: "unloadingPlatStateFree",
  components: {
    LeftTwoState,
    loading,
    topHeader,
    leftOneState,
    rightOneState,
    rightTwoState,
  },
  data() {
    return {
      connection: {
        host: this.COMMON.mqttHost,
        port: this.COMMON.mqttPort,
        //endpoint: '',
        endpoint: '/mqtt',
        clean: true, // 保留会话
        connectTimeout: 4000, // 超时时间
        reconnectPeriod: 4000, // 重连时间间隔
        // 认证信息
        clientId: this.randomString(10),
        username: this.COMMON.mqttUserName,
        password: this.COMMON.mqttPassword,
        //password: 'public',
      },
      subscription: {
        topic: "/guide/"+this.$route.query.macId+"/data",
        qos: 0,
      },
      subscribeSuccess: false,
      isLoading: false,
      macId: this.$route.query.macId,
      token: this.$route.query.token,
      macInfo:{},
      name: ''//this.$route.query.macId+"#卸料平台"
    }
  },
  created() {
    this.getRealInfo()
  },
  methods:{
    //随机数
    randomString(len) {
      len = len || 32;
      const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      const maxPos = $chars.length;
      let pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    createConnection() {
      const { host, port, endpoint, ...options } = this.connection
      const connectUrl = `ws://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
      this.client.on('connect', () => {
        console.log('Connection succeeded!')
        console.log(this.connection.clientId)
        const { topic, qos } = this.subscription
        this.client.subscribe(topic, qos, (error, res) => {
          if (error) {
            console.log('Subscribe to topics error', error)
            return
          }
          this.subscribeSuccess = true
          console.log('Subscribe to topics res', res)
        })
      })
      this.client.on('error', error => {
        console.log('Connection failed', error)
      })
      this.client.on('message', (topic, message) => {
        //this.receiveNews = this.receiveNews.concat(message)
        //console.log('收到来自', topic, '的消息', message.toString())
        //console.log(`Received message ${message} from topic ${topic}`)
        let jsonData=JSON.parse(message.toString());
        //let msgStr = JSON.parse(jsonData.json.toString());
        //console.log(jsonData.json)
        this.binderData(jsonData.json)
      })
    },
    getRealInfo: function (){
      let jsStr= '';
      this.$axios.post(`/sys/msgLiveDataUnloading/info/${this.$route.query.macId}/${this.$route.query.token}`).then((response) => {
        if (response.data.result === 'success') {
          jsStr = response.data.info;
          let jsonData=JSON.parse(jsStr);
          let msgStr = jsonData.json;
          let json = JSON.parse(msgStr.toString());
          this.name = jsonData.deviceName;//设备名称
          this.binderData(json);
        }else{
          this.macInfo = {
            weight:100,
            weightPercent:65,
            maxWeight:56,
            incidenceX:50,
            incidenceY:56,
            maxIncidence:56,
            state:0,
            power:12.56,
            powerPercent:56
          }
        }
      });
      this.createConnection();
    },
    binderData(json){
      if(json.length==0){
        return;
      }
      let _this=this;
      //底部参数
      _this.macInfo = {
        weight:json.weight,
        weightPercent:json.weightPercent,
        maxWeight:json.maxWeight,
        incidenceX:json.incidenceX,
        incidenceY:json.incidenceY,
        maxIncidence:json.maxIncidence,
        state:json.state,
        power:json.power,
        createTime:json.createTime
      };
    }
  }
}
</script>

<style>
@import "../dataV/static/css/index.css";
@import "../dataV/static/css/visual.css";
</style>
