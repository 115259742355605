<template>
  <el-dialog  :visible.sync="store.state.dialogVisible"
              :show-close="false"
              class="status_change"
              width="550px">
    <div slot="title">
      {{store.state.dialogTitle}}
    </div>
    <el-form ref="formData" :model="formData"
             :rules="formRule"
             label-width="120px">
      <el-form-item label="选择项目" prop="ids">
        <el-cascader v-model="formData.ids"
                     :options="options"
                     :props="optionProps"
                     @change="handleChange"
                     style="width: 380px;"> </el-cascader>
      </el-form-item>
      <el-form-item label="区域名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="store.closeDialog()">关闭</el-button>
      <el-button size="small" type="primary" @click="save">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mustFill} from "@/validation/valid"
import {store} from "@/views/consoleArea/store";
import {EventBus} from "@/views/consoleArea/event-bus";
import http from "axios";

export default {
  name: "myDialog",
  props:["rowData"],
  data(){
    return{
      store,
      formData:{
        id:'',
        ids:[],
        companyId:'',
        projectId:'',
        name:''
      },
      optionProps: {
        expandTrigger: 'hover',
        value: "id",
        label: "name",
        children: "children"
      },
      options: [],
      formRule:{
        ids:[mustFill],
        name:[mustFill]
      }
    }
  },
  created() {
    EventBus.$on('editData',(data)=>{
      this.formData=data;
      //let xx=[data.companyId,data.projectId]
      this.formData.ids=store.state.selectedIds
      store.showDialog('修改区域')
    })
    EventBus.$on('addProject',()=>{
      this.formData.ids=store.state.selectedIds
      store.showDialog('新增区域')
      this.formData={}
    })
    this.getData()
  },
  methods:{
    save(){
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if(this.formData.ids[0]==0){
            this.$message({
              message: "请选择项目信息",
              type: "warning",
            });
            return;
          }
          this.formData.companyId = this.formData.ids[0]
          this.formData.projectId = this.formData.ids[1]
          //console.log(this.formData.ids)
          this.$axios.post('/sys/area/save', this.formData).then((response) => {
            if (response.data.result === 'success') {
              store.closeDialog();
              this.refreshTable();
              this.$message({
                message: '保存成功',
                type: 'success'
              })
            } else {
              this.$message({message: '保存失败', type: 'error'})
            }
          })
        }else{
          this.$message({
            message: "请补全信息",
            type: "warning",
          });
        }
      });
    },
    getData(){
      http({
        url:"/sys/company/listTwoTree",
        method: "post",
        data: {}
      }).then(res=>{
        this.options=res.data
      })
    },
    refreshTable(){
      EventBus.$emit('refreshTable')
    },
    handleChange(value){
      console.log(value)
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
</style>
