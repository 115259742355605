<template>
    <span id="proChart" class="deviceAlarm">
    </span>
</template>
<script>
export default {
  name:"rightOneMain",
  data(){
    return{
      rightOneData:[]
    }
  },
  mounted() {
    this.devStatus();
  },
  methods:{
    async getPojectData(){
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        comId: userInfo.comId,
        limit: 6
      }
      await this.$axios.post("/sys/project/listAll",data).then( (response) =>{
        this.rightOneData = response.data.list;
      }).catch((error)=>{
        console.log(error);
      });
    },
    async devStatus(){
      await this.getPojectData();
      let lineNameDataArr=[];
      let lineValueDataArr=[];
      this.rightOneData.forEach((item)=> {
        lineNameDataArr.push(item.name);
        lineValueDataArr.push(item.progress);
      });
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('proChart'))

      myChart.setOption({
        tooltip:{
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,255,255,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255,255,255,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,255,255,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          }
        },
        animation: false,
        grid: {
          top: "15%",
          right: '3%',
          bottom: "15%"//也可设置left和right设置距离来控制图表的大小
        },
        xAxis: {
          data: lineNameDataArr,
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: '#11417a'
            }
          },
          axisTick: {
            show: false //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            margin: 14,
            fontSize: 14,
            textStyle: {
              color: "#A3C0DF" //X轴文字颜色
            }
          },
        },
        yAxis: [
          {
            type: "value",
            gridIndex: 0,
            min: 0,
            max: 100,
            interval: 25,
            // splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#113763',
                width: 1
              },
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#11417a'
              }
            },
            axisLabel: {
              show: true,
              margin: 14,
              fontSize: 14,
              textStyle: {
                color: "#A3C0DF" //X轴文字颜色
              }
            },
          },
        ],
        series: [
          {
            name: "项目进度",
            type: "bar",
            barWidth: 20,
            itemStyle: {
            },
            data: lineValueDataArr,
            z: 10,
            zlevel: 0,
            "label": {
              "show": true,
              "position": "top",
              "distance": 10,
              fontSize:16,
              "color": "#01fff4"
            }
          },
          {
            // 分隔
            type: "pictorialBar",
            itemStyle: {
              normal:{
                color:"#0F375F"
              }
            },
            symbolRepeat: "fixed",
            symbolMargin: 6,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [20, 2],
            symbolPosition: "start",
            symbolOffset: [0, -1],
            // symbolBoundingData: this.total,
            data: lineValueDataArr,
            width: 25,
            z: 0,
            zlevel: 1,
          },
        ]
      });
    }
  }
}
</script>
<style scoped>
.deviceAlarm{
  width: 99%!important;
  height: calc( 100% - 60px )!important;
  float:left;
}
</style>
