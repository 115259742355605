<template>
  <div class="ksh">
    <loading v-if="isLoading"/>
    <top-header :name="name"/>
    <div class="visual">
      <div class="visual_left">
        <left-one-state :state-one="stateOne"/>
        <left-two-state :state-two="stateTwo"/>
        <left-three-state :state-three="stateThree"/>
      </div>
      <div class="visual_con">
        <key-param :key-param="keyParam"/>
        <cartoon :heightPercent="heightPercent" :data-time="dataTime" :mac-info="macInfo"/>
      </div>
      <div class="visual_right">
        <right-one-state :right-one-state="rightOneState"/>
        <right-two-state :right-two-state="rightTwoState"/>
        <right-three-state :right-three-state="rightThreeState"/>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "../../components/MacState/loading";
import topHeader from "../../components/MacState/topHeader";
import leftOneState from "../../components/ElevatorState/leftOneState";
import leftTwoState from "../../components/ElevatorState/leftTwoState";
import leftThreeState from "../../components/ElevatorState/leftThreeState";
import keyParam from "../../components/MacState/keyParam";
import cartoon from "../../components/ElevatorState/cartoon";
import rightOneState from "../../components/ElevatorState/rightOneState";
import rightTwoState from "../../components/ElevatorState/rightTwoState";
import rightThreeState from "../../components/ElevatorState/rightThreeState";
import mqtt from "mqtt";
import moment from "moment";

export default {
  name: "elevatorState",
  components: {
    loading,
    topHeader,
    leftOneState,
    leftTwoState,
    leftThreeState,
    keyParam,
    cartoon,
    rightOneState,
    rightTwoState,
    rightThreeState,
  },
  data() {
    return {
      connection: {
        host: this.COMMON.mqttHost,
        port: this.COMMON.mqttPort,
        //endpoint: '',
        endpoint: '/mqtt',
        clean: true, // 保留会话
        connectTimeout: 4000, // 超时时间
        reconnectPeriod: 4000, // 重连时间间隔
        // 认证信息
        clientId: this.randomString(10),
        username: this.COMMON.mqttUserName,
        password: this.COMMON.mqttPassword,
        //password: 'public',
      },
      subscription: {
        topic: "/guide/"+this.$route.query.deviceId+"/data",
        qos: 0,
      },
      //receiveNews: '',
      subscribeSuccess: false,
      client: {
        connected: false,
      },
      isLoading: false,
      macId: this.$route.query.macId,
      heightPercent: 0,
      keyParam: [],//9 10 11 12 13
      stateOne: [],//14 15 16 17 18 19 20 21
      stateTwo: [],//22 23 24 25 26 27 28 29
      stateThree: [],//30 31 32 33 34 35 36 37
      rightOneState: [],
      rightTwoState: [],
      rightThreeState: [],
      chNames: {},
      macInfo:{
        ID:this.$route.query.deviceId
      },
      name: '',
      dataTime: '',
    }
  },
  created() {
    //this.websocket();
    this.getRealInfo();
  },
  methods:{
    //随机数
    randomString(len) {
      len = len || 32;
      const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      const maxPos = $chars.length;
      let pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    createConnection() {
      const { host, port, endpoint, ...options } = this.connection
      const connectUrl = `ws://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
      this.client.on('connect', () => {
        console.log('Connection succeeded!')
        console.log(this.connection.clientId)
        const { topic, qos } = this.subscription
        this.client.subscribe(topic, qos, (error, res) => {
          if (error) {
            console.log('Subscribe to topics error', error)
            return
          }
          this.subscribeSuccess = true
          console.log('Subscribe to topics res', res)
        })
      })
      this.client.on('error', error => {
        console.log('Connection failed', error)
      })
      this.client.on('message', (topic, message) => {
        //this.receiveNews = this.receiveNews.concat(message)
        console.log('收到来自', topic, '的消息', message.toString())
        //console.log(`Received message ${message} from topic ${topic}`)
        this.binderData(message.toString());
      })
      // 断开发起重连
      /*        this.client.on('reconnect', (error) => {
                console.log('正在重连:', error)
              })*/

    },
    getRealInfo: function (){
      let jsStr= '';
      this.$axios.post(`/sys/msgLiveDataElevator/info/${this.$route.query.deviceId}`).then((response) => {
        if (response.data.result === 'success') {
          jsStr = response.data.info;
          let jsonData=JSON.parse(jsStr);
          this.name = jsonData.deviceName;//设备名称
          this.binderData(jsStr);
        }
      });
      this.createConnection();
    },
    binderData(jsData){
      if(jsData.length==0){
        return;
      }
      let _this=this;
      _this.isLoading = false;
      let jsonData=JSON.parse(jsData);
      let msgStr = jsonData.json;
      let json = JSON.parse(msgStr);
      _this.keyParam=[];
      _this.stateOne=[]
      _this.stateTwo=[]
      _this.stateThree=[]
      _this.stateOne=[]
      _this.rightOneState=[]
      _this.rightTwoState=[]
      _this.rightThreeState=[]

      _this.heightPercent=json.heightPercentage
      _this.dataTime = moment(json.createTime).format("YYYY-MM-DD HH:mm:ss");//更新时间
      _this.keyParam.push({name:'实时起重量',value:json.weight,rule:'KG'});
      _this.keyParam.push({name:'实时人数',value:json.numberOfPeople});
      _this.keyParam.push({name:'实时高度',value:json.height,rule:'m'});
      _this.keyParam.push({name:'高度百分比',value:json.heightPercentage,rule:'%'});
      _this.keyParam.push({name:'重量百分比',value:json.weightPercentage,rule:'%'});

      _this.stateOne.push({name:'设备状态',value:json.deviceStatus==0?'空闲':'工作'})
      _this.stateOne.push({name:'实时速度',value:json.speed,rule:'m/s'})
      let orient='';
      if(json.orientation==0){
        orient = "静止";
      } else if(json.orientation==1){
        orient = "上";
      } else if(json.orientation==2){
        orient = "下";
      }
      _this.stateOne.push({name:'运行方向',value:orient});

      _this.stateTwo.push({name:'实时倾斜度1',value:json.incidenceX,rule:'°'});
      _this.stateTwo.push({name:'实时倾斜百分比1',value:json.incidenceXPercentage,rule:'%'});
      _this.stateTwo.push({name:'实时倾斜度2',value:json.incidenceY,rule:'°'});
      _this.stateTwo.push({name:'实时倾斜百分比2',value:json.incidenceYPercentage,rule:'%'});

      _this.stateThree.push({name:'驾驶员姓名',value:json.driverName});
      _this.stateThree.push({name:'身份证号',value:json.driverIdCard});

      _this.rightOneState.push({name:'前门状态',value:json.frontDoorStatus==0?'关闭':'打开'});
      _this.rightOneState.push({name:'后门状态',value:json.backDoorStatus==0?'关闭':'打开'});

      if(json.weightWarnAlarm==0){
        _this.rightTwoState.push({name:'重量预警',value:"正常"});
        _this.rightTwoState.push({name:'重量报警',value:"正常"});
      } else if(json.weightWarnAlarm==1){
        _this.rightTwoState.push({name:'重量预警',value:"预警"});
        _this.rightTwoState.push({name:'重量报警',value:"正常"});
      } else if(json.weightWarnAlarm==2){
        _this.rightTwoState.push({name:'重量预警',value:"正常"});
        _this.rightTwoState.push({name:'重量报警',value:"报警"});
      }
      if(json.heightWarnAlarm==0){
        _this.rightTwoState.push({name:'高度预警',value:"正常"});
        _this.rightTwoState.push({name:'高度报警',value:"正常"});
      } else if(json.heightWarnAlarm==1){
        _this.rightTwoState.push({name:'高度预警',value:"预警"});
        _this.rightTwoState.push({name:'高度报警',value:"正常"});
      } else if(json.heightWarnAlarm==2){
        _this.rightTwoState.push({name:'高度预警',value:"正常"});
        _this.rightTwoState.push({name:'高度报警',value:"报警"});
      }
      if(json.speedWarnAlarm==0){
        _this.rightTwoState.push({name:'速度预警',value:"正常"});
        _this.rightTwoState.push({name:'速度报警',value:"正常"});
      } else if(json.speedWarnAlarm==1){
        _this.rightTwoState.push({name:'速度预警',value:"预警"});
        _this.rightTwoState.push({name:'速度报警',value:"正常"});
      } else if(json.speedWarnAlarm==2){
        _this.rightTwoState.push({name:'速度预警',value:"正常"});
        _this.rightTwoState.push({name:'速度报警',value:"报警"});
      }
      if(json.incidenceWarnAlarm==0){
        _this.rightTwoState.push({name:'倾斜预警',value:"正常"});
        _this.rightTwoState.push({name:'倾斜报警',value:"正常"});
      } else if(json.incidenceWarnAlarm==1){
        _this.rightTwoState.push({name:'倾斜预警',value:"预警"});
        _this.rightTwoState.push({name:'倾斜报警',value:"正常"});
      } else if(json.incidenceWarnAlarm==2){
        _this.rightTwoState.push({name:'倾斜预警',value:"正常"});
        _this.rightTwoState.push({name:'倾斜报警',value:"报警"});
      }
      //_this.rightThreeState.push({name:'前门报警',value:_this.alarmType(json.frontDoorLockAlarm,2)});
      //_this.rightThreeState.push({name:'后门报警',value:_this.alarmType(json.backDoorLockAlarm,2)});
      //_this.rightThreeState.push({name:'门锁异常报警',value:_this.alarmType(json.doorException,2)});
      if(json.peopleNumberAlarm==0) {
        _this.rightThreeState.push({name: '人数报警', value: "正常"});
      } else if(json.peopleNumberAlarm==1) {
        _this.rightThreeState.push({name: '人数报警', value: "报警"});
      }
      if(json.authenticationAlarm==0) {
        _this.rightThreeState.push({name: '身份认证', value: "正常"});
      }else if(json.authenticationAlarm==1) {
        _this.rightThreeState.push({name: '身份认证', value: "报警"});
      }
    }
  }
}
</script>

<style scoped>
@import "./static/css/visual.css";
</style>
