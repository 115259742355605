<template>
  <div class="app-container">
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['设备视频']">
      <template slot="controls">
      </template>
    </crumbs-bar>
    <div class="filter-container">
      <el-form :inline="true" :model="listQuery" ref="listQuery" class="demo-form-inline">
        <el-form-item style="margin-left: 30px;" label="设备SN" prop="设备SN">
          <el-input v-model="listQuery.devId" placeholder="设备SN" clearable=""></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 30px;" label="备注" prop="备注">
          <el-input v-model="listQuery.note" placeholder="备注" clearable=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="plus" @click="showCreate" v-if="hasPerm('machineVideo:add')">添加
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" v-loading.body="listLoading" element-loading-text="拼命加载中" border fit
              highlight-current-row>
      <el-table-column align="center" label="序号" width="80">
        <template slot-scope="scope">
          <span v-text="getIndex(scope.$index)"> </span>
        </template>
      </el-table-column>
      <el-table-column label="设备SN">
        <template slot-scope="scope">
          <div v-if="scope.row.macType===100">
            <router-link  :to="{path:'/towerState',query:{deviceId:scope.row.devId}}" target="_blank" style="color:#409eff">
              {{scope.row.devId}}
            </router-link>
          </div>
          <div v-else-if="scope.row.macType===101">
            <router-link  :to="{path:'/elevatorState',query:{deviceId:scope.row.devId}}" target="_blank" style="color:#409eff">
              {{scope.row.devId}}
            </router-link>
          </div>
          <div v-else-if="scope.row.macType===103">
            <router-link  :to="{path:'/allInOneState',query:{deviceId:scope.row.devId}}" target="_blank" style="color:#409eff">
              {{scope.row.devId}}
            </router-link>
          </div>
          <div v-else>
            {{scope.row.devId}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="录像机序列号" prop="serialNo"></el-table-column>
      <el-table-column label="备注" prop="note"></el-table-column>
      <el-table-column label="操作" width="230" v-if="hasPerm('machineVideo:update')">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="showUpdate(scope.$index)">修改</el-button>
          <el-button size="mini" type="success" @click="reloadToken(scope.$index)">加载</el-button>
          <el-button size="mini" type="danger" @click="delVideo(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.pageNum"
      :page-size="listQuery.pageRow"
      :total="totalCount"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="600px">
      <el-form class="small-space" :model="tempForm" label-position="left" label-width="120px"
               style='margin-left:50px; margin-right: 50px;'>
        <el-form-item label="应用密钥" required>
          <el-select v-model="tempForm.appId" placeholder="请选择" style="width: 340px;">
            <el-option
              v-for="item in pros"
              :key="item.id"
              :label="item.note"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" required>
          <el-select v-model="tempForm.macType" placeholder="请选择" style="width: 340px;">
            <el-option v-for="item in macTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备SN" required>
          <el-input type="text" v-model="tempForm.devId">
          </el-input>
        </el-form-item>
        <el-form-item label="录像机序列号" required>
          <el-input type="text" v-model="tempForm.serialNo">
          </el-input>
        </el-form-item>
        <el-form-item label="视频数量" required>
          <el-select v-model="tempForm.videoNum" placeholder="请选择" style="width: 340px;">
            <el-option v-for="item in videoNums" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" required>
          <el-input type="text" v-model="tempForm.note">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="dialogStatus=='create'" type="success" @click="createUser">创 建</el-button>
        <el-button type="primary" v-else @click="updateUser">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "machineVideo",
  data() {
    return {
      totalCount: 0, //分页组件--数据总条数
      list: [],//表格的数据
      listLoading: false,//数据加载等待动画
      listQuery: {
        devId: '',
        note:'',
        pageNum: 1,//页码
        pageRow: 20,//每页条数
      },
      dialogStatus: 'create',
      dialogFormVisible: false,
      textMap: {
        update: '编辑',
        create: '新增'
      },
      tempForm: {
        id:'',
        appId:'',
        devId: '',
        macType: '',
        serialNo: '',
        videoNum: '',
        note: '',
      },
      macTypes: [{value: 100,label: '塔机'},
        {value: 101,label: '升降机'},
        {value: 102,label: '卸料平台'},
        {value: 103,label: '一体机'}
      ],
      videoNums: [{value: 1,label: '1个'},
        {value: 2,label: '2个'},
        {value: 3,label: '3个'},
        {value: 4,label: '4个'},
        {value: 5,label: '5个'},
        {value: 6,label: '6个'},
        {value: 7,label: '7个'},
        {value: 8,label: '8个'}
      ],
      pros:[],
    }
  },
  created() {
    this.loadSelList();
    this.getList();
  },
  methods: {
    queryClick() {
      this.getList();
    },
    handleChange(value) {
      console.log(value);
    },
    getList() {
      //查询列表
      this.listLoading = true;
      this.listQuery.sysType=2;//房建
      this.$axios.post("/video/appmac/list",this.listQuery).then(data => {
        this.listLoading = false;
        this.list = data.data.info.list;
        this.totalCount = data.data.info.totalCount;
      })
    },//列表
    handleSizeChange(val) {
      //改变每页数量
      this.listQuery.pageRow = val
      this.handleFilter();
    },
    handleCurrentChange(val) {
      //改变页码
      this.listQuery.pageNum = val
      this.getList();
    },
    handleFilter() {
      //查询事件
      this.listQuery.pageNum = 1
      this.getList()
    },
    getIndex($index) {
      //表格序号
      return (this.listQuery.pageNum - 1) * this.listQuery.pageRow + $index + 1
    },
    showCreate() {
      //显示新增对话框
      this.tempForm.id = '';
      this.tempForm.devId = '';
      this.tempForm.macType = '';
      this.tempForm.serialNo = '';
      this.tempForm.videoNum = '';
      this.tempForm.note = '';
      this.dialogStatus = "create"
      this.dialogFormVisible = true
    },
    showUpdate($index) {
      this.tempForm.id = '';
      this.tempForm.devId = '';
      this.tempForm.macType = '';
      this.tempForm.serialNo = '';
      this.tempForm.videoNum = '';
      this.tempForm.note = '';
      let model = this.list[$index];
      this.tempForm.devId = model.devId;
      this.tempForm.macType = model.macType;
      this.tempForm.serialNo = model.serialNo;
      this.tempForm.videoNum = model.videoNum;
      this.tempForm.note = model.note;
      this.tempForm.id = model.id;
      this.tempForm.appId = model.appId;

      this.dialogStatus = "update"
      this.dialogFormVisible = true
    },
    createUser() {
      if(this.tempForm.appId===''){
        this.$message({ type:'error', message:'请选择应用密钥！' })
        return;
      }
      if(this.tempForm.macType===''){
        this.$message({ type:'error', message:'请选择设备类型！' })
        return;
      }
      if(this.tempForm.devId===''){
        this.$message({ type:'error', message:'请输入设备SN！' })
        return;
      }
      if(this.tempForm.serialNo===''){
        this.$message({ type:'error', message:'请输入录像机序列号！' })
        return;
      }
      if(this.tempForm.videoNum===''||this.tempForm.videoNum===0){
        this.$message({ type:'error', message:'请选择视频数量！' })
        return;
      }
      if(this.tempForm.note===''){
        this.$message({ type:'error', message:'请输入备注！' })
        return;
      }
      let _vue = this;
      //添加
      this.tempForm.sysType = 2;//系统类型
      this.$axios.post("/video/appmac/save",this.tempForm).then((res) => {
        this.dialogFormVisible = false;
        if(res.data.info==1){
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 1 * 1000,
            onClose: () => {
              _vue.getList();
            }
          })
        } else {
          this.$message({ type:'error', message:'添加失败！' });
        }
        this.dialogFormVisible = false
      })
    },
    updateUser() {
      if(this.tempForm.appId===''){
        this.$message({ type:'error', message:'请选择应用密钥！' })
        return;
      }
      if(this.tempForm.macType===''){
        this.$message({ type:'error', message:'请选择设备类型！' })
        return;
      }
      if(this.tempForm.devId===''){
        this.$message({ type:'error', message:'请输入设备SN！' })
        return;
      }
      if(this.tempForm.serialNo===''){
        this.$message({ type:'error', message:'请输入录像机序列号！' })
        return;
      }
      if(this.tempForm.videoNum===''||this.tempForm.videoNum===0){
        this.$message({ type:'error', message:'请选择视频数量！' })
        return;
      }
      if(this.tempForm.note===''){
        this.$message({ type:'error', message:'请输入备注！' })
        return;
      }
      //修改用户信息
      let _vue = this;
      this.$axios.post("/video/appmac/upate",this.tempForm).then((res) => {
        this.dialogFormVisible = false;
        if(res.data.info==1){
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1 * 1000,
            onClose: () => {
              _vue.getList();
            }
          })
        } else {
          this.$message({ type:'error', message:'修改失败！' });
        }
      })
    },//重新获取token
    delVideo($index) {
      let _this = this;
      let model = this.list[$index];
      this.tempForm.devId = model.devId;
      this.tempForm.sysType = 2;//系统类型
      this.$axios.post("/video/appmac/delVideo",this.tempForm).then((res)=>{
        if(res.data.info==1){
          this.$message({
            message: "删除视频成功！",
            type: 'success',
            duration: 1 * 1000,
            onClose: () => {
              _this.getList();
            }
          });
        } else {
          this.$message({ type:'error', message:'删除视频失败！' });
        }
      })
    },
    loadSelList(){
      let params = {
      }
      this.$axios.post("/video/application/list",params).then((res)=>{
        this.pros=res.data.info.list;
      })
    },//下拉框
    reloadToken($index) {
      let model = this.list[$index];
      this.tempForm.devId = model.devId;
      this.$axios.post("/video/application/reloadToken",this.tempForm).then((res)=>{
        if(res.data.info==1){
          this.$message({ message: "成功获取token信息",type: 'success' });
        } else {
          this.$message({ type:'error', message:'token信息获取失败！' });
        }
      })
    },
    handleRefresh(){
      this.getList()
    }
  }
}
</script>
<style>
.el-select-dropdown {
  box-shadow: none !important;
  min-width: 0px;
  border: 0 !important;
}

.el-select-dropdown__item span {
  width: 200px;
  text-align: center;
}

.tableHeader {
  background: rgb(64, 158, 255);
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family: HiraginoSansGB-W3;
  font-weight: 600;
  padding: 0 20px;
}

.tableHeader span {
  width: 100px;
  text-align: center;
}
</style>
