export const consoleDeviceStore={
    state:{
        org:{
            companyId:'',
            projectId:'',
            areaId:'',
            companyName:'',
            projectName:'',
            areaName:''
        }
    },
    setOrg:function (org){
        this.state.org=org
    },
    setOrgItem:function (key,value){
        this.state.org[key]=value;
    }
}
