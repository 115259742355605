<template>
  <el-dialog :visible.sync="myVisible" title="人脸参数配置" @closed="configDialogClosed"
             class="status_change" @open="queryConfig()"
             :close-on-click-modal="false" :show-close="false" width="680px" left>
    <div style="height:418px;overflow:auto">
      <el-form ref="configFormData" :model="configFormData" label-width="180px" :inline="true"
               style="margin-right: 2px; margin-top: 10px;">
        <el-row>
          <el-col :span="24">
            <el-form-item label="活体方法" size="small" prop="enableIrLive1">
              <el-input v-model="configFormData.enableIrLive" style="width: 100px;" :disabled="true"></el-input>
              <el-select v-model="configFormData.enableIrLive1">
                <el-option v-for="item in enableIrLives" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item size="small" >
              <el-button type="primary" @click="config('enableIrLive1')" :disabled="btnDisabled">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="活体等级" size="small" prop="irLiveLevel1">
              <el-input v-model="configFormData.irLiveLevel" style="width: 100px;" :disabled="true"></el-input>
              <el-select v-model="configFormData.irLiveLevel1">
                <el-option v-for="item in irLiveLevels" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item size="small" >
              <el-button type="primary" @click="config('irLiveLevel1')" :disabled="btnDisabled">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="上报记录URL" size="small" prop="recordUrl">
              <el-input v-model="configFormData.recordUrl" style="width: 315px;"></el-input>
            </el-form-item>
            <el-form-item size="small">
              <el-button type="primary" @click="updateConfigUrl('recordUrl')" :disabled="btnDisabled">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="UI界面" size="small" prop="uiType">
              <el-select v-model="configFormData.uiType" style="width: 100px;">
                <el-option v-for="item in uiTypes" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="configFormData.uiUrl">
                <el-option v-for="item in uiUrls" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item size="small">
              <el-button type="primary" @click="config('uiType1')" :disabled="btnDisabled">设置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" :loading="loadingQuery" icon="el-icon-alarm-clock" @click="onlineSetUi" v-if="hasPerm('face:restart')">
        UI界面</el-button>
      <el-button type="primary" size="small" :loading="loadingQuery" icon="el-icon-alarm-clock" @click="onlineOpen" v-if="hasPerm('face:restart')">
        远程开闸</el-button>
      <!-- <el-button type="primary" size="small" :loading="loadingQuery" icon="el-icon-alarm-clock" @click="setSysTime" v-if="hasPerm('face:restart')">
        时间同步</el-button>-->
      <el-button type="primary" size="small" :loading="loadingQuery" icon="el-icon-switch-button" @click="onlineReboot" v-if="hasPerm('face:restart')">
        远程重启</el-button>
      <el-button type="primary" size="small" :loading="loadingQuery" icon="el-icon-search" @click="onlineGetDevInfo">
        {{ loadingQuery?'获取中':'获取参数' }}</el-button>
      <el-button size="small" @click="myVisible=false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: "fyFaceConfig",
  props: {
    deviceId:String,//code
    deviceSn:String,//serialNumber
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      enableIrLives:[
        {
          value: 0,
          label: '活体1（RGB）'
        }, {
          value: 1,
          label: '活体2 (IR)'
        }
      ],
      irLiveLevels:[
        {
          value: 0,
          label: '活体1（RGB）'
        }, {
          value: 1,
          label: '活体2 (IR)'
        }
      ],
      uiTypes: [
        {
          value: 1,
          label: '主界面UI图'
        }, {
          value: 2,
          label: '屏保UI图'
        }, {
          value: 3,
          label: '二维码UI图'
        }
      ],
      uiUrls:[],
      uiUrl1: [
        {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/1.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/1.jpg'
        }, {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/2.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/2.jpg'
        }, {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/3.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/3.jpg'
        }, {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/4.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/4.jpg'
        }, {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/5.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/5.jpg'
        }, {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/6.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/6.jpg'
        }, {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/7.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/7.jpg'
        }, {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/8.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/8.jpg'
        }, {
          value: 'http://fj.zzkde.com:9098/face/guideui/1/9.jpg',
          label: 'http://fj.zzkde.com:9098/face/guideui/1/9.jpg'
        }, {
          value: 10,
          label: 'http://fj.zzkde.com:9098/face/guideui/1/10.jpg'
        }
      ],
      uiUrl2: [
        {
          value: 1,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/1.jpg'
        }, {
          value: 2,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/2.jpg'
        }, {
          value: 3,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/3.jpg'
        }, {
          value: 4,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/4.jpg'
        }, {
          value: 5,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/5.jpg'
        }, {
          value: 6,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/6.jpg'
        }, {
          value: 7,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/7.jpg'
        }, {
          value: 8,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/8.jpg'
        }, {
          value: 9,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/9.jpg'
        }, {
          value: 10,
          label: 'http://fj.zzkde.com:9098/face/guideui/2/10.jpg'
        }
      ],
      uiUrl3: [
        {
          value: 1,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/1.jpg'
        }, {
          value: 2,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/2.jpg'
        }, {
          value: 3,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/3.jpg'
        }, {
          value: 4,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/4.jpg'
        }, {
          value: 5,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/5.jpg'
        }, {
          value: 6,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/6.jpg'
        }, {
          value: 7,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/7.jpg'
        }, {
          value: 8,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/8.jpg'
        }, {
          value: 9,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/9.jpg'
        }, {
          value: 10,
          label: 'http://fj.zzkde.com:9098/face/guideui/3/10.jpg'
        }
      ],
      loadingQuery:false,
      configFormData:{
        enableIrLive:'',
        enableIrLive1:'',
        irLiveLevel:'',
        irLiveLevel1:'',
        recordUrl:'',
        uiType:1,
        uiUrl:''
      },
      btnDisabled:false,
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit("update:dialogVisible",val);
      }
    }
  },
  Mounted() {
    this.queryConfig();
  },
  created() {
    this.uiUrls = this.uiUrl1;
  },
  methods:{
    config(field){
      this.btnDisabled = true;
      var val = this.configFormData[field];
      if((val==='')|| val===undefined){
        this.$message({message: "参数设置不能为空！", type: 'error'});
        return;
      }
      let data = {
        deviceSn: this.deviceSn,
        deviceId: this.deviceId,
        name: field.substring(0, field.length - 1),
        value: val
      }
      this.$axios.post(`/fy/dev/onlineSetDevInfo`, data).then((response)=> {
        if(response.data.result==="success") {
          this.$message({message: "该参数设置成功！", type: 'success'});
        } else {
          this.$message({message: response.data.msg, type: 'error'});
        }
        this.btnDisabled = false;
      }).catch((err) => {
        this.btnDisabled = false;
        console.log(err);
      });
    },
    updateConfigUrl(field){
      var val = this.configFormData[field];
      if((val==='')|| val===undefined){
        this.$message({message: "第三方上报认证记录URL不能为空！", type: 'error'});
        return;
      }
      let data = {
        deviceSn: this.deviceSn,
        name: field,
        value: val
      }
      this.$axios.post(`/fy/config/updateConfigUrl`, data).then((response)=> {
        if(response.data.result==="success") {
          this.$message({message: "上报认证记录URL设置成功！", type: 'success'});
        } else {
          this.$message({message: response.data.msg, type: 'error'});
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    queryConfig(){
      console.log(this.deviceSn)
      this.$axios.post(`/fy/config/info/${this.deviceSn}`).then((response)=>{
        if(response.data.result==="success"&&response.data.info!=null) {
          this.configFormData = response.data.info;
          this.$set(this.configFormData, 'recogIntervalTime1', response.data.info.recogIntervalTime);
          this.loadingQuery = false;
        }
      }).catch(()=>{
        this.loadingQuery = false;
      })
    },
    onlineGetDevInfo(){
      let data = {
        deviceSn: this.deviceSn,
        deviceId: this.deviceId,
      }
      this.$axios.post(`/fy/dev/onlineGetDevInfo`, data).then((response) => {
        if (response.data.result === 'success') {
          this.$message.success('获取参数成功！')
        } else {
          this.$message.error('获取参数失败！')
        }
      });
    },
    onlineSetUi(){
      this.$confirm('确定定制设备UI界面吗','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=> {
        let data = {
          deviceSn: this.deviceSn,
          deviceId: this.deviceId,
        }
        this.$axios.post(`/fy/dev/onlineSetUi`, data).then((response) => {
          if (response.data.result === 'success') {
            this.$message.success('定制设备UI界面成功！')
          } else {
            this.$message.error('定制设备UI界面失败！')
          }
        });
      });
    },
    onlineOpen(){
      this.$confirm('确定远程开闸吗','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=> {
        let data = {
          deviceSn: this.deviceSn,
          deviceId: this.deviceId,
        }
        this.$axios.post(`/fy/dev/onlineOpen`, data).then((response) => {
          if (response.data.result === 'success') {
            this.$message.success('开闸成功！')
          } else {
            this.$message.error('开闸失败！')
          }
        });
      });
    },
    setSysTime(){
      this.$confirm('确定与服务器时间同步','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=> {
        let data = {
          deviceSn: this.deviceSn,
          deviceId: this.deviceId,
        }
        this.$axios.post(`/fy/dev/onlineSetTime`, data).then((response) => {
          if (response.data.result === 'success') {
            this.$message.success('与服务器时间已同步，请确认时间！')
          } else {
            this.$message.error('与服务器时间已同步失败！')
          }
        });
      });
    },
    onlineReboot(){
      this.$confirm('确定要重启设备吗？','提示',{
        cancelButtonText:'取消',
        confirmButtonText:'确定'
      }).then(()=> {
        let data = {
          deviceSn: this.deviceSn,
          deviceId: this.deviceId,
        }
        this.$axios.post(`/fy/dev/onlineReboot`, data).then((response) => {
          if (response.data.result === 'success') {
            this.$message.success('设备已重新启动！')
          } else {
            this.$message.error('设备已重新启动失败！')
          }
        });
      });
    },
    configDialogClosed(){
      this.loadingQuery=false;
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
/* 利用穿透，设置input边框隐藏 */
.inputDeep {
  width: 50px;
}
.inputxx{
  width: 215px;
  margin-left: 1px;
}
.sjjinput{
  width: 90px;
}
.sjjinputxx{
  width: 90px;
  margin-left: 1px;
}
</style>
