<template>
  <div style="margin: 15px 0">
    <el-card class="layui-elevator">
      <div style="display: flex;justify-content: space-between;cursor:pointer">
        <div style="line-height: 32px;color: #ffffff;">
          <span>设备编号：</span>
          <span style="width: 150px;display: inline-block;">{{ deviceId }}</span>
          <span>设备名称：</span>
          <span style="width: 250px;display: inline-block;">{{ deviceName }}</span>
        </div>
        <div >
          <el-button size="small" type="primary" plain @click.stop="oppfDevVideo" v-show="isRemote==1">视频播放</el-button>
        </div>
      </div>
    </el-card>
    <oppf-dev-video-dialog :dialog-visible.sync="oppfDevVideoDialogVisible"></oppf-dev-video-dialog>
  </div>
</template>

<script>
import OppfDevVideoDialog from "@/views/projectDevice/oppfDevVideoDialog";

export default {
  name: "liftingHookDevice",
  props:["deviceId","deviceName","deviceType","onlineState","isRemote"],
  components:{
    OppfDevVideoDialog,
  },
  data(){
    return{
      oppfDevVideoDialogVisible:false
    }
  },
  methods:{
    oppfDevVideo(){
      let path='/oppfDevVideo'
      let routeData=this.$router.resolve({
        path:path,
        query:{
          deviceId:this.deviceId
        }
      })
      window.open(routeData.href,'_blank')
    }
  }
}
</script>
<style scoped>
.flex-layout{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.flex-layout >div{
  min-width: 800px;
  min-height: 200px;
  border: 1px solid #0056A1;
  margin-bottom: 10px;
}
.layui-elevator{
  height: 100%; background: rgba(0, 124, 249, 0.1);
  border: 1px solid #0056A1; border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
</style>
