<!--
大屏通用头部
-->
<template>
    <div class="head_top"><span>{{name}}</span></div>
</template>

<script>
    export default {
        name: "topHeader",
        props:["name"],
    }
</script>

