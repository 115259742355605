import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from "./router"
import * as echarts from 'echarts'
import store from "@/store"
import Axios from 'axios'
import './assets/icon/iconfont.css'
import 'video.js/dist/video-js.css'
//注册全局组件
import "./components/global/index"
import '@/permission' // 权限
import '@/utils/api'
import global from '@/utils/common'
import {hasPermission} from "./utils/hasPermission";
import dev from '../config/dev.env'
import pro from '../config/prod.env'

const node_env = process.env.NODE_ENV;
console.log("node_env:"+node_env);
if (node_env === 'production'){
  Axios.defaults.baseURL = pro.BASE_URL;
} else {
  Axios.defaults.baseURL = dev.BASE_URL;
}

Vue.prototype.$axios = Axios;
Axios.defaults.withCredentials = true;//请求头中加cookie

Vue.prototype.$echarts = echarts;
Vue.prototype.$pageSizes = [10,50,100,200];
Vue.prototype.$pageSize = 12;
Vue.prototype.COMMON = global;
Vue.prototype.hasPerm = hasPermission
//Vue.prototype.$websocketUrl='ws://fj.zzkde.com:9096/websocket'
//Vue.prototype.$websocketUrl='ws://127.0.0.1:9096/websocket'

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
