<template>
  <el-tree :load="loadNode" highlight-current  lazy :props="props" @node-click="handleNodeClick">

  </el-tree>
</template>
<script>
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
import {EventBus} from "@/views/consoleDevice/event-bus";

export default {
  name: "myTree",
  data(){
    return{
      props:{
        id:'id',
        label:'name',
        isLeaf:'leaf'
      },
      url1:'/sys/company/listIdName',
      url2:'/sys/project/listByCompany',
      url3:'/sys/area/listByProject'
    }
  },
  methods:{
    /*异步加载节点*/
    loadNode(node,resolve){
      switch (node.level){
        case 0:
          var userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
          var data= {
            comId: userInfo.comId
          }
          this.$axios.post(this.url1,data).then((response)=>{
            resolve(response.data.length==0?[]:response.data)
          });
          break
        case 1:
          this.resolveChildren(this.url2+'/'+node.data.id,resolve,false)
          break
        case 2:
          this.resolveChildren(this.url3+'/'+node.data.id,resolve,true)
          break
        default:
          break
      }
    },
    /*节点点击事件，只处理最后一层，区域点击事件*/
    handleNodeClick(data,node){
      if (node.level===3){
        //更新store中的数据
        let org={
          companyId:node.parent.parent.data.id,
          projectId:node.parent.data.id,
          areaId:node.data.id,
          companyName:node.parent.parent.data.name,
          projectName:node.parent.data.name,
          areaName:node.data.name
        }
        consoleDeviceStore.setOrg(org)
        //更新表格事件
        EventBus.$emit("loadTable")
      }
    },
    resolveChildren(url,resolve,isLeaf){
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        comId: userInfo.comId
      }
      this.$axios.post(url,data).then((response)=>{
        if (isLeaf){
          response.data.forEach((item)=>{
            item.leaf=true
          })
        }
        resolve(response.data.length==0?[]:response.data)
      })
    }
  },
  destroy(){
    let org={
      companyId:'',
      projectId:'',
      areaId:'',
      companyName:'',
      projectName:'',
      areaName:'',
    }
    consoleDeviceStore.setOrg(org)
  }
}
</script>

<style scoped>

</style>
