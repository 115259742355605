<template>
  <div>
    <!-- 面包屑导航 -->
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['设备属性信息']">
      <template slot="controls">
      </template>
    </crumbs-bar>

    <el-container>
      <!-- <el-header><el-button size="small" type="primary" style="float: left" @click="add">新增设备</el-button></el-header>-->
      <el-container>
        <el-aside class="elaside">
          <my-tree></my-tree>
        </el-aside>
        <el-main style="padding: 0 0 0 5px;">
          <msg-table></msg-table>
        </el-main>
      </el-container>
      <msg-dialog></msg-dialog>
    </el-container>
  </div>
</template>

<script>
import myTree from "@/views/consoleDevice/myTree";
import msgTable from "@/views/consoleDevice/msgTable";
import msgDialog from "@/views/consoleDevice/msgDialog";
import {consoleDeviceStore} from "@/views/consoleDevice/console-device-store";
import {EventBus} from "@/views/consoleDevice/event-bus";
export default {
  name: "attribute",
  components:{
    myTree,msgTable,msgDialog
  },
  data(){
    return{
      consoleDeviceStore
    }
  },
  mounted(){

  },
  methods:{
    handleRefresh(){
      EventBus.$emit('refresh','刷新')
    }
  }
}
</script>

<style scoped>
.elaside{
  padding-left: 5px;
  padding-top: 10px;
  border-right:1px dashed #b6b6b6;
  height: 88vh;
}
</style>
