<template>
  <el-dialog title="身份认证历史" width="60%" :close-on-click-modal="false" :visible.sync="myVisible" center>
    <span>
      数据部分
    </span>
    <span slot="footer">
        <el-button @click="myVisible=false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "identityAuthentication",
  props: {
    dialogVisible: {
      type:Boolean,
      default:false
    },
    deviceId:String,
    deviceType:String,
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit('update:dialogVisible',val)
      }
    }
  }
  }

</script>

<style scoped>

</style>
