<template>
    <span id="rightChart2" class="deviceAlarm">
    </span>
</template>
<script>
export default {
  name:"rightTwoMain",
  data(){
    return{
    }
  },
  mounted() {
    this.devStatus();
  },
  methods:{
    resizeDom(dom) {
      //通过窗体高宽计算容器高宽，渲染echart图表的div的宽高度已达到自适应目的
      dom.style.width = (window.innerWidth-15)+'px';
      dom.style.height = (window.innerHeight-35)+'px';
    },
    devStatus(){
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      let data= {
        companyId: userInfo.comId,
        count: 0 //查当天
      }
      this.$axios.post("/sys/alarm/dayCurve",data).then( (response) =>{
        let colorDataRes = ["#02c3f1", "#ffe000", "#9a7fd1","#006ced",
          '#00ff00', '#ff0103','#93CE07','#FC7D02',
          '#AA069F','#AC3B2A'];
        let centerDevData = response.data;
        let lineDataArr = [],colorData = [],i=0;

        for (var key in centerDevData.records) {
          var res = centerDevData.records[key];
          let lineData=[];
          for(var val in res){
            lineData.push(res[val]);
          }
          let data1={
            name: key,
            smooth: true,
            type: 'line',
            lineStyle: {
              normal: {
                width: 2
              },
            },
            data:lineData
          }
          lineDataArr.push(data1);
          colorData.push(colorDataRes[i]);
          i++;
        }
        // 基于准备好的dom，初始化echarts实例
        var scatterechartDom=document.getElementById('rightChart2');
        let myChart = this.$echarts.init(scatterechartDom)
        myChart.setOption({
          color : colorData,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(255,255,255,0)', // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255,255,255,1)', // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,255,255,0)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            }
          },
          grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: centerDevData.times,
            //  改变x轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize:'14'
              },
            }
          },
          yAxis: {
            type: 'value',
            //  改变y轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize:'14'
              },
            },
            splitLine: {
              lineStyle: {
                color: '#243',
              },
            },
            axisTick: {
              show: false,
            }
          },
          series: lineDataArr
        });
      }).catch((error)=>{
        console.log(error);
      });
    }
  }
}
</script>
<style scoped>
.deviceAlarm{
  width: 100%!important;
  height: calc( 100% - 60px )!important;
  float:left;
}
</style>
