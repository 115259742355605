<template>
  <el-container style="height: 100%">
    <el-header style="height: 7vh; padding-top: 20px; min-height:63px;" :style="back">
      <el-row>
        <el-col :span="10" :offset="2"><span class="myTitle">智慧工地监管平台</span></el-col>
        <el-col class="header-button" :span="12" style="text-align: right">
          <!-- <el-button @click="$router.push('/projectOverview')">项目概况</el-button>
          <el-button @click="$router.push('/dashboard')">控制台</el-button>
          <el-button @click="userInfoVisible=true">用户信息</el-button>-->
          <el-link @click="$router.push('/projectView')" class="fontcolor">项目概况</el-link>
          <el-link @click="$router.push('/dashboard')" class="fontcolor">控制台</el-link>
          <el-link @click="userInfoVisible=true" class="fontcolor">修改密码</el-link>
          <el-link @click="loginOut()" class="fontcolor">退出</el-link>
        </el-col>
      </el-row>
    </el-header>
    <div style="background-color: #ffffff;height: 100%;">
      <router-view></router-view>
    </div>
    <user-info :visible.sync="userInfoVisible"></user-info>
  </el-container>
</template>
<script>
import UserInfo from "@/views/home/userInfo";
import {removeToken} from "@/utils/auth";

export default {
  name: "index",
  components: {UserInfo},
  data(){
    return {
      userInfoVisible:false,
      back: {
        backgroundImage: "url(" + require("../../assets/images/topbj.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      }
    }
  },
  methods:{
    /*loginOut(){
      this.$store.commit("RESET_USER")
      this.$router.push('/')
    }*/
    /**登出 */
    loginOut(){
      this.$confirm('确定登出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeToken();//移除
        let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        let data1= {
          userId: userInfo.id
        }
        //window.console.log(userInfo);
        /**登出 */
        this.$axios.post("/logout",data1).then(() => {
          //console.log(response);
          this.$store.commit("RESET_USER");
          window.localStorage.removeItem('auth');
          window.localStorage.removeItem('userInfo');
          this.$router.replace({
            path:"/login"
          });
        }).catch((err) => {
          window.console.log(err);
        });
      });
    }
  }
}
</script>
<style scoped>

.header-button button{
  width: 80px;
  padding: 0;
}

.myTitle{
  color: white;
  font-size:20px ;
}
.fontcolor{
  color: white;
  margin-right: 10px;
}
</style>

