<!--
右一
-->
<template>
  <div class="visual_box_unloading">
    <div class="visual_title">
      <span>卸料载重</span>
      <img src="../../views/dataV/static/images/ksh33.png">
    </div>
    <div>
      <div class="rightOneWeight1">
        <div class="textAlignCenter"><img src="../../assets/svg/weight.svg" width="128" height="128" class="svg"></div>
        <div class="textAlignCenter">{{this.macInfo.weight}}</div>
        <div class="textAlignCenter">当前重量</div>
      </div>
      <div class="rightOneWeight2">
        <div class="textAlignCenter"><img src="../../assets/svg/weight.svg" width="128" height="128" class="svg"></div>
        <div class="textAlignCenter">{{ this.macInfo.maxWeight }}</div>
        <div class="textAlignCenter">最大允许载重</div>
      </div>
      <div style="clear:both"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rightOneState",
  props: ["macInfo"],
  created() {
  },
  methods: {
  }
}
</script>
<style scoped>
.rightOneWeight1{
  float: left;
  width: 50%;
  height: 90%;
  padding: 30px 0 0 0;
  /*background:rgba(16,54,87,0.5);
  border:1px solid #345f92;*/
}
.rightOneWeight2{
  float: left;
  width: 50%;
  height: 90%;
  padding: 30px 0 0 0;
  /*background:rgba(16,54,87,0.5);
  border:1px solid #345f92;*/
}
.textAlignCenter{
  text-align: center;
  line-height: 28px;
  color: white;
}
</style>
