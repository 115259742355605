<template>
  <div class="app-container">
    <crumbs-bar @refresh="handleRefresh" :crumbsList="['应用密钥']">
      <template slot="controls">
      </template>
    </crumbs-bar>
    <div class="filter-container">
      <el-form :inline="true" :model="listQuery" ref="listQuery" class="demo-form-inline">
        <el-form-item style="margin-left: 30px;" label="公司名称" prop="公司名称">
          <el-input v-model="listQuery.note" placeholder="公司名称" clearable=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="plus" @click="showCreate" v-if="hasPerm('application:add')">添加
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" v-loading.body="listLoading" element-loading-text="拼命加载中" border fit
              highlight-current-row>
      <el-table-column align="center" label="序号" width="80">
        <template slot-scope="scope">
          <span v-text="getIndex(scope.$index)"> </span>
        </template>
      </el-table-column>
      <el-table-column label="AppKey" prop="appKey"></el-table-column>
      <el-table-column label="Secret" prop="secret"></el-table-column>
      <el-table-column label="公司名称" prop="note"></el-table-column>
      <el-table-column label="操作" width="180" v-if="hasPerm('application:update')">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" icon="edit" @click="showUpdate(scope.$index)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.pageNum"
      :page-size="listQuery.pageRow"
      :total="totalCount"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form class="small-space" :model="tempForm" label-position="left" label-width="120px"
               style='width: 500px; margin-left:50px;'>
        <el-form-item label="AppKey" required>
          <el-input type="text" v-model="tempForm.appKey">
          </el-input>
        </el-form-item>
        <el-form-item label="Secret" required>
          <el-input type="text" v-model="tempForm.secret">
          </el-input>
        </el-form-item>
        <el-form-item label="公司名称" >
          <el-input type="text" v-model="tempForm.note">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="dialogStatus=='create'" type="success" @click="createUser">创 建</el-button>
        <el-button type="primary" v-else @click="updateUser">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "application",
  data() {
    return {
      totalCount: 0, //分页组件--数据总条数
      list: [],//表格的数据
      listLoading: false,//数据加载等待动画
      listQuery: {
        note:'',
        pageNum: 1,//页码
        pageRow: 20,//每页条数
      },
      dialogStatus: 'create',
      dialogFormVisible: false,
      textMap: {
        update: '编辑',
        create: '新增'
      },
      tempForm: {
        id:'',
        appKey: '',
        secret: '',
        note: '',
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    queryClick() {
      this.getList();
    },
    handleChange(value) {
      console.log(value);
    },
    getList() {
      let _this = this;
      //查询列表
      this.listLoading = true;
      this.$axios.post("/video/application/list",this.listQuery).then(function (data) {
        _this.listLoading = false;
        _this.list = data.data.info.list;
        _this.totalCount = data.data.info.totalCount;
      })
    },
    handleSizeChange(val) {
      //改变每页数量
      this.listQuery.pageRow = val
      this.handleFilter();
    },
    handleCurrentChange(val) {
      //改变页码
      this.listQuery.pageNum = val
      this.getList();
    },
    handleFilter() {
      //查询事件
      this.listQuery.pageNum = 1
      this.getList()
    },
    getIndex($index) {
      //表格序号
      return (this.listQuery.pageNum - 1) * this.listQuery.pageRow + $index + 1
    },
    showCreate() {
      //显示新增对话框
      this.tempForm.appKey = '';
      this.tempForm.secret = '';
      this.tempForm.note = '';
      this.tempForm.id = '';
      this.dialogStatus = "create"
      this.dialogFormVisible = true
    },
    showUpdate($index) {
      let model = this.list[$index];
      this.tempForm.appKey = model.appKey;
      this.tempForm.secret = model.secret;
      this.tempForm.note = model.note;
      this.tempForm.id = model.id;

      this.dialogStatus = "update"
      this.dialogFormVisible = true
    },
    createUser() {
      //添加新用户
      this.$axios.post("/video/application/save",this.tempForm).then(() => {
        this.getList();
        this.dialogFormVisible = false
      })
    },
    updateUser() {
      //修改用户信息
      let _vue = this;
      this.$axios.post("/video/application/upate",this.tempForm).then(() => {
        let msg = "修改成功";
        this.dialogFormVisible = false;
        this.$message({
          message: msg,
          type: 'success',
          duration: 1 * 1000,
          onClose: () => {
            _vue.getList();
          }
        })
      })
    },
    handleRefresh(){
      this.getList()
    }
  }
}
</script>
<style>
.el-select-dropdown {
  box-shadow: none !important;
  min-width: 0px;
  border: 0 !important;
}

.el-select-dropdown__item span {
  width: 200px;
  text-align: center;
}

.tableHeader {
  background: rgb(64, 158, 255);
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family: HiraginoSansGB-W3;
  font-weight: 600;
  padding: 0 20px;
}

.tableHeader span {
  width: 100px;
  text-align: center;
}
</style>
