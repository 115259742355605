<!--
左一
-->
<template>
  <div class="visual_box">
    <div class="visual_title">
      <span>状态1</span>
      <img src="../../views/dataV/static/images/ksh33.png">
    </div>
    <div class="visual_open"  v-for="(item,i) in this.stateOne" :key="i">
        <h5>{{item.name}}</h5>
        <p>
          <span style="color: #ff0e46" class="txt">{{item.value}}{{item.rule}}</span>
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "leftOneState",
  props: ["stateOne"],
}
</script>

