<!--
升降机动画
-->
<template>
  <div class="visual_conBot">
    <img class="visual_conBot_l" alt="" src="../../views/dataV/static/images/ksh42.png">
    <img class="visual_conBot_2" alt="" src="../../views/dataV/static/images/ksh43.png">
    <img class="visual_conBot_3" alt="" src="../../views/dataV/static/images/ksh44.png">
    <img class="visual_conBot_4"  alt="" src="../../views/dataV/static/images/ksh45.png">

    <div :style="display1">
      <div class="visual_chart_text" style="width: 100%">
        <div style="float: left;"><h1>{{dataTime}}</h1></div>
        <div style="float: right; padding-right: 30px;">
          <div style="float: left; margin-right: 10px;">
            <el-form :model="formData" label-position="left" label-width="120px">
              <el-select v-model="formData.vd" style="width: 193px;" @change="videoDataChange()">
                <el-option v-for="item in videoData" :key="item.id" :label="item.spName" :value="item.id">
                </el-option>
              </el-select>
            </el-form>
          </div>
          <div style="float: left;">
            <img src="../../assets/svg/close.svg" @click="showVideo(1)" height="32" width="32" v-show="isShowPic"/>
          </div>
        </div>
      </div>
      <show-video :video-data="oneVideoData"></show-video>
    </div>

    <div :style="display2">
      <div class="visual_chart_text" style="width: 97%">
        <div style="float: left;"><h1>{{dataTime}}</h1></div>
        <div style="float: right; padding-right: 30px; height: 32px; width: 32px;">
          <img src="../../assets/svg/video.svg" @click="showVideo(2)" height="32" width="32" v-show="isShowPic"/>
        </div>
      </div>
      <div class="mid-tower fl">
        <div class="MTTleft">
          <div id="towerBg">
            <div class="hand-length" id="qianBiChang"></div>
            <div id="rangeWrapper"></div>
            <div id="heightWrapper"></div>
            <div id="weightWrapper"></div>
          </div>
        </div>
      </div>
      <div class="anti-collision">
          <anti-collision
              v-for="(item,index) in antiCollisions"
              :key="index"
              :style="{left: 'calc(50% - '+item.xOffset+'px)',top: 'calc(50% - '+item.yOffset+'px)'}"
              :area-width="item.length*2"
              :area-height="item.length*2"
              :angle="item.angle"
              :amplitude="item.amplitude">
          </anti-collision>
      </div>
      <div class="anti-bottom">
        <div class="anti-bottom_left" id="towerEcharts1"></div>
        <div class="anti-bottom_center" id="towerEcharts2"></div>
        <div class="anti-bottom_right" id="towerEcharts3"></div>
        <div style="clear:both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts"
import $ from "jquery";
import antiCollision from "@/components/TowerState/antiCollision";
import ShowVideo from "../showVideo";

export default {
  name: "cartoon",
  props: ["cartoonState", "dataTime", "macInfo","antiCollisions","cartoonStatePercent"],
  components:{
    ShowVideo,
    antiCollision
  },
  data() {
    return {
      display1:"display:none",
      display2:"",
      isShowPic:false,
      videoData:{},
      oneVideoData:{},
      formData: {
        vd:'',
      },
      cState: [],
      devInfo:{},
      windVal: 0
    }
  },
  mounted() {
    this.cState=this.cartoonStatePercent
    this.changeTowercraneData();
    this.towerEchartsThree();
    this.getVideoList();
  },
  watch: {
    cartoonStatePercent: function (newVal) {
      this.cState = newVal;
      this.changeTowercraneData();
    },
    antiCollisions:function (){
      console.log("观察"+this.antiCollisions)
    },
    macInfo: function (newVal) {
      this.devInfo = newVal;
      this.towerEchartsOne(this.devInfo.heightPercentage);
      this.towerEchartsTwo(this.devInfo.amplitudePercentage);
      this.windVal = this.devInfo.windVelocity;
    }
  },
  methods: {
    /**
     * 根据高度百分比，移动图片，形成动画
     */
    changeTowercraneData() {
      let heightPercent = this.cState[0];
      let amplitudePercent = this.cState[1];
      let Oheight = $("#heightWrapper")
      let Oweight = $("#weightWrapper")
      let Oslider = $("#rangeWrapper")
      //Step1：左右变化 滑块滑动，线和重物也会随着滑动
      let slider_l = 80 + 230 * amplitudePercent
      slider_l=slider_l>=310?310:slider_l
      slider_l=slider_l<80?80:slider_l
      Oslider.css({left: `${slider_l}px`});
      Oweight.css({left: `${slider_l}px`});
      Oheight.css({left: `${(slider_l + 10)}px`});
      //Step2：上下变化 线上下变化，重物块也会随之上下变化 计算图中线的长度
      if (heightPercent<0){
        heightPercent=0
      }else if (heightPercent>1){
        heightPercent=1
      }
      Oheight.css({height: `${(1-heightPercent) * 260}px`});
      Oweight.css({top: `${(1-heightPercent) * 260 + 50}px`})
    },
    towerEchartsOne(val){
      var towerEcharts2=document.getElementById('towerEcharts2');
      let myChart = this.$echarts.init(towerEcharts2)
      var option = {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
          {
            name: '高度百分比',
            radius: '80%',
            type: 'gauge',
            axisLabel:{
              distance:-50,
              textStyle: {
                color: "#fff",
                fontSize: '12',
              }
            },
            axisLine: {
              lineStyle: {
                color: [[1, '#23c023']]
              }
            },
            detail: {
              formatter: '{value}%',
              color: '#fff',
              offsetCenter: [0, '70%'],
              textStyle: {fontSize: 14,fontWeight: 'bold',color: '#fff'},
            },
            data: [{value: 20, name: '高度百分比',}],
            title: {                // 仪表盘标题。
              show: true,             // 是否显示标题,默认 true。
              offsetCenter: [0,"95%"],//相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
              color: "#ff6700",          // 文字的颜色,默认 #333。
              fontSize: 15,           // 文字的字体大小,默认 15。
            }
          }
        ]
      };
      option.series[0].data[0].value = val;
      myChart.setOption(option, true);
      //当窗口发生变化时
      //window.addEventListener("resize", () => {
      //  this.resizeDom(towerEcharts2); //重置div宽高度
      //  myChart.resize();//重绘echart图表
      //});
    },
    towerEchartsTwo(val){
      var towerEcharts3=document.getElementById('towerEcharts3');
      let myChart3 = this.$echarts.init(towerEcharts3)
      let highlight = new echarts.graphic.LinearGradient(0, 0, 1, 0, [{offset: 0,color: '#fe1440' // 0% 处的颜色
        }, {offset: 0.17,color: '#fd9d35' // 100% 处的颜色
        }, {offset: 0.9,color: '#b6fd62' // 100% 处的颜色
        }, {offset: 1,color: '#09fe44' // 100% 处的颜色
        }]);
      var option = {
        series: [
          {
            type: 'gauge',
            // center: item.pos,
            radius: '75%',
            splitNumber: 10,
            min: 0,
            max: 100,
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: true,
              lineStyle: {
                width: 5,
                shadowBlur: 0,
                color: [
                  [1, highlight]
                ]
              }
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: highlight,
                width: 1
              },
              length: -5,
              splitNumber: 10
            },
            splitLine: {
              show: true,
              length: -10,
              lineStyle: {
                color: highlight
              }
            },
            axisLabel: {
              distance: -20,
              textStyle: {
                color: "#fff",
                fontSize: '12',
              }
            },
            pointer: {
              show: 0
            },
            detail: {
              show: 0
            }
          },
          // 内侧指针、数值显示
          {
            name: '幅度百分比',
            type: 'gauge',
            // center: item.pos,
            radius: '70%',
            startAngle: 225,
            endAngle: -45,
            min: 0,
            max: 100,
            axisLine: {
              show: true,
              lineStyle: {
                width: 16,
                color: [
                  [1, 'rgba(0,0,0,0.1)']
                ]
              }
            },
            axisTick: {show: 0},
            splitLine: {show: 0},
            axisLabel: {show: 0},
            pointer: {show: true,length: '105%'},
            detail: {
              show: true,
              offsetCenter: [0, '100%'],
              textStyle: {fontSize: 14,fontWeight: 'bold',color: '#fff'},
              formatter: ['{value}%','{name| 幅度百分比}'].join('\n'),
              rich: {
                name: {fontSize: 14,lineHeight: 30,color: '#fff'}
              }
            },
            itemStyle: { normal: {color: '#FE7C49'}},
            data: [{value: 98}]
          }
        ]
      };
      option.series[1].data[0].value = val;
      myChart3.setOption(option, true);
      //当窗口发生变化时
      //window.addEventListener("resize", () => {
      //  this.resizeDom(towerEcharts3); //重置div宽高度
      //  myChart3.resize();//重绘echart图表
      //});
    },
    towerEchartsThree(){
      let towerEcharts1=document.getElementById('towerEcharts1');
      var myChart1 = this.$echarts.init(towerEcharts1)
      var angle = 0; //角度，用来做简单的动画效果的
      var value = 80;
      var timerId;
      var option = {
        title: {
          text: '{a|' + value + '}{c|}',
          x: 'center',
          y: 'center',
          textStyle: {
            rich: {
              a: {
                fontSize: 24,
                color: '#29EEF3'
              },
              c: {
                fontSize: 14,
                color: '#29EEF3',
                // padding: [5,0]
              }
            }
          }
        },
        series: [
          // 紫色
          {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
              return {
                type: 'arc',
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: Math.min(api.getWidth(), api.getHeight()) / 2* 0.8,
                  startAngle: (0 + angle) * Math.PI / 180,
                  endAngle: (90 + angle) * Math.PI / 180
                },
                style: {
                  stroke: "#8383FA",
                  fill: "transparent",
                  lineWidth: 1.5
                },
                silent: true
              };
            },
            data: [0]
          },
          {
            name: "ring5", //紫点
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.8;
              let point = getCirlPoint(x0, y0, r, (90 + angle))
              return {
                type: 'circle',
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4
                },
                style: {
                  stroke: "#8450F9", //绿
                  fill: "#8450F9"
                },
                silent: true
              };
            },
            data: [0]
          },
          // 蓝色
          {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
              return {
                type: 'arc',
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.8,
                  startAngle: (180 + angle) * Math.PI / 180,
                  endAngle: (270 + angle) * Math.PI / 180
                },
                style: {
                  stroke: "#4386FA",
                  fill: "transparent",
                  lineWidth: 1.5
                },
                silent: true
              };
            },
            data: [0]
          },
          {
            name: "ring5", // 蓝色
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.8;
              let point = getCirlPoint(x0, y0, r, (180 + angle))
              return {
                type: 'circle',
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4
                },
                style: {
                  stroke: "#4386FA", //绿
                  fill: "#4386FA"
                },
                silent: true
              };
            },
            data: [0]
          },
          {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
              return {
                type: 'arc',
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.9,
                  startAngle: (270 + -angle) * Math.PI / 180,
                  endAngle: (40 + -angle) * Math.PI / 180
                },
                style: {
                  stroke: "#0CD3DB",
                  fill: "transparent",
                  lineWidth: 1.5
                },
                silent: true
              };
            },
            data: [0]
          },
          // 橘色
          {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
              return {
                type: 'arc',
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.9,
                  startAngle: (90 + -angle) * Math.PI / 180,
                  endAngle: (220 + -angle) * Math.PI / 180
                },
                style: {
                  stroke: "#FF8E89",
                  fill: "transparent",
                  lineWidth: 1.5
                },
                silent: true
              };
            },
            data: [0]
          }, {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.9;
              let point = getCirlPoint(x0, y0, r, (90 + -angle))
              return {
                type: 'circle',
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4
                },
                style: {
                  stroke: "#FF8E89", //粉
                  fill: "#FF8E89"
                },
                silent: true
              };
            },
            data: [0]
          }, {
            name: "ring5", //绿点
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
              let x0 = api.getWidth() / 2;
              let y0 = api.getHeight() / 2;
              let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.9;
              let point = getCirlPoint(x0, y0, r, (270 + -angle))
              return {
                type: 'circle',
                shape: {
                  cx: point.x,
                  cy: point.y,
                  r: 4
                },
                style: {
                  stroke: "#0CD3DB", //绿
                  fill: "#0CD3DB"
                },
                silent: true
              };
            },
            data: [0]
          }, {
            name: '吃猪肉频率',
            type: 'pie',
            radius: ['65%', '50%'],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              }
            },
            data: [{
              value: value,
              name: "",
              itemStyle: {
                normal: {
                  color: { // 完成的圆环的颜色
                    colorStops: [{
                      offset: 0,
                      color: '#A098FC' // 0% 处的颜色
                    },
                      {
                        offset: 0.3,
                        color: '#4386FA' // 0% 处的颜色
                      },
                      {
                        offset: 0.6,
                        color: '#4FADFD' // 0% 处的颜色
                      },
                      {
                        offset: 0.8,
                        color: '#0CD3DB' // 100% 处的颜色
                      }, {
                        offset: 1,
                        color: '#646CF9' // 100% 处的颜色
                      }
                    ]
                  },
                }
              }
            },
              {
                value: 100 - value,
                name: "",
                label: {
                  normal: {
                    show: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: "#173164"
                  }
                }
              }
            ]
          },
          {
            name: '吃猪肉频率',
            type: 'pie',
            radius: ['42%', '45%'],
            silent: true,
            clockwise: true,
            startAngle: 270,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",

              }
            },
            data: [{
              value: value,
              name: "",
              itemStyle: {
                normal: {
                  color: { // 完成的圆环的颜色
                    colorStops: [{
                      offset: 0,
                      color: '#00EDF3' // 0% 处的颜色
                    }, {
                      offset: 1,
                      color: '#646CF9' // 100% 处的颜色
                    }]
                  },
                }
              }
            },
              {
                value: 100 - value,
                name: "",
                label: {
                  normal: {
                    show: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: "#173164"
                  }
                }
              }
            ]
          },

        ]
      };

      //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
      function getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
        let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
        return {
          x: x1,
          y: y1
        }
      }

      function draw(wval) {
        angle = angle + 3
        option.title.text = '{a|' + wval + '}{c|\nm/s}';
        myChart1.setOption(option, true)
        //window.requestAnimationFrame(draw);
      }
      if (timerId) {
        clearInterval(timerId);
      }
      timerId = setInterval(() =>{
        let _this = this;
        //用setInterval做动画感觉有问题
        draw(_this.windVal);
      }, 100);
    },
    getVideoList: function (){
      let _this = this;
      this.$axios.post("/machine/video/list",{ID:this.macInfo.ID}).then(data => {
        if(data.data.code==="100") {
          var list = data.data.info.list;
          if(list.length>0){
            _this.videoData = list;//下拉框
            _this.oneVideoData = list[0];
            _this.formData.vd = list[0].id;
            _this.isShowPic = true;
          }
        } else {
          _this.isShowPic = false;
        }
      })
    },
    showVideo(val){
      if(val===1){
        this.display1 = "display:none";
        this.display2 = "";
      }
      if(val===2){
        this.display1 = "";
        this.display2 = "display:none";
      }
    },
    videoDataChange(){
      for (var i = 0; i < this.videoData.length; i++) {
        if(this.videoData[i].id===this.formData.vd){
          this.oneVideoData = this.videoData[i];
        }
      }
    }
  }
}
</script>
<style scoped>
.mid-tower {
  width: 540px;
  height: 394px;
  /*border:1px solid #345f92;*/
}

.fl {
  float: left;
}

#towerBg {
  width: 348px;
  height: 370px;
  background-image: url("../../assets/images/towerCrane.png");
  background-repeat: no-repeat;
  margin-top: 60px;
  margin-left: 30px;
  background-size: 100% 100%;
  position: relative;
}

.hand-length {
  font-size: 12px;
  color: #4cd4a2;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* 小车 */
#rangeWrapper {
  background-image: url("../../assets/images/bg_range.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 35px;
  height: 20px;
  background-size: 100% 100%;
  background-position: -1px 0;
  right: 20%; /*62% - 0%*/
  top: 52px;
  transition: 1s;
}

/* 吊绳 */
#heightWrapper {
  background-image: url("../../assets/images/bg_height.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 15px;
  height: 155px;
  background-size: 100% 100%;
  right: 23%; /* - 3%*/
  top: 17%;
  transition: 1s;
}

/* 吊钩和重物 */
#weightWrapper {
  background-image: url("../../assets/images/bg_weight.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 35px;
  height: 60px;
  background-size: 100% 100%;
  background-position: -1px 0;
  right: 20%; /*-0%-*/
  top: 56%;
  transition: 1s;
}

.anti-collision{
  width:calc(55%);
  height:55%;
  /*background:rgba(16,54,87,0.5);
  border:1px solid #345f92;*/
  position:absolute;
  top:50px;
  right:4px;
  z-index:0;
}

.anti-bottom{
  width:calc(99%);
  height:35%;
  /*background:rgba(16,54,87,0.5);
  border:1px solid #345f92;*/
  position:absolute;
  left: 5px;
  bottom:4px;
  right:4px;
  z-index:0;
}

.anti-bottom_left{
  width:calc(33%);
  height:100%;
  /*background:rgba(16,54,87,0.5);*/
  border:1px solid #345f92;
  float: left;
}

.anti-bottom_center{
  width:calc(33%);
  height:100%;
  padding-top: 10px;
  /*background:rgba(16,54,87,0.5);*/
  border:1px solid #345f92;
  float: left;
  margin-left: 4px;
}

.anti-bottom_right{
  width:calc(33%);
  height:100%;
  padding-top: 10px;
  /*background:rgba(16,54,87,0.5);*/
  border:1px solid #345f92;
  float: left;
  margin-left: 4px;
}
</style>
