<!--
卸料载重百分比
-->
<template>
  <div class="visual_box_unloading">
    <div class="visual_title">
      <span>卸料载重百分比</span>
      <img src="../../views/dataV/static/images/ksh33.png">
    </div>
    <div id="unloadingPlatEcharts2" class="anti-bottom_center2">
    </div>
  </div>
</template>

<script>
export default {
  name: "rightTwoState",
  props: ["macInfo"],
  data(){
    return{
      devInfo:{},
    }
  },
  watch: {
    macInfo: function (newVal) {
      this.devInfo = newVal;
      this.towerEchartsOne(this.devInfo.weightPercent);
    }
  },
  methods: {
    towerEchartsOne(val) {
      var unloadingPlatEcharts2 = document.getElementById('unloadingPlatEcharts2');
      let myChart = this.$echarts.init(unloadingPlatEcharts2)
      var option = {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
          {
            name: '卸料载重百分比',
            radius: '70%',
            type: 'gauge',
            axisLabel: {
              distance: -50,
              textStyle: {
                color: "#fff",
                fontSize: '12',
              }
            },
            axisLine: {
              lineStyle: {
                color: [[1, '#23c023']]
              }
            },
            detail: {
              formatter: '{value}%',
              color: '#fff',
              offsetCenter: [0, '70%'],
              textStyle: {fontSize: 14, fontWeight: 'bold', color: '#fff'},
            },
            data: [{value: 20, name: '卸料载重百分比',}],
            title: {                // 仪表盘标题。
              show: true,             // 是否显示标题,默认 true。
              offsetCenter: [0, "95%"],//相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
              color: "#ff6700",          // 文字的颜色,默认 #333。
              fontSize: 15,           // 文字的字体大小,默认 15。
            }
          }
        ]
      };
      option.series[0].data[0].value = val;
      myChart.setOption(option, true);
    }
  }
}
</script>
<style>
.anti-bottom_center2{
  width:100%;
  height:90%;
  padding-top: 2px;
  /*background:rgba(16,54,87,0.5);
  border:1px solid #345f92;*/
  float: left;
}
</style>
