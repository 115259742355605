<template>
  <el-dialog
      title="司机认证记录" :show-close="false"
      :close-on-click-modal="false"
      width="60%"
      :visible.sync="myVisible"
      @open="loadDataRecord" class="status_change2"
      left>
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="认证通过记录" name="first">
        <span>
          <el-form :inline="true" :model="pageQuery" ref="pageQuery" class="demo-form-inline">
            <el-form-item>
              <span class="demonstration2">请选择时间段</span>
              <el-date-picker
                  style="margin-right: 30px;"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  label="时间段"
                  v-model="pageQuery.datetime"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button v-loading.body="recordListLoading" type="primary" @click="queryClickRecord($event)">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-loading.body="listLoading" type="primary" @click="excleExport($event,1)">
                <span v-show="export_percentage===-1">导出Excel</span>
                <span v-show="export_percentage!==-1">导出中:{{ export_percentage }}%</span>
              </el-button>
            </el-form-item>
          </el-form>
        </span>
        <span>
          <el-table height="400" :data="page.records" border :row-style="{height:'44px'}" :cell-style="{padding:'4px'}">
            <el-table-column type="index" :index="indexMethod" label="序号" width="60" fixed="left"></el-table-column>
            <el-table-column label="时间" prop="create_time" width="180" fixed="left"></el-table-column>
            <el-table-column label="注册头像" width="110">
               <template slot-scope="scope">
                <img :src="picInitUrl+scope.row.pic_url0" width="60" height="60"/>
              </template>
            </el-table-column>
            <el-table-column label="现场头像" width="110">
               <template slot-scope="scope">
                <img :src="picInitUrl+scope.row.pic_url1" width="60" height="60"/>
              </template>
            </el-table-column>
            <el-table-column label="姓名" prop="driver_name" width="120"></el-table-column>
            <el-table-column label="卡号" prop="card" width="100"></el-table-column>
            <el-table-column label="身份证号" prop="user_no"></el-table-column>
            <el-table-column label="认证模式" width="120">
               <template slot-scope="scope">
                 <span v-if="scope.row.mode===1">人脸识别</span>
                 <span v-if="scope.row.mode===2">卡或人脸</span>
                 <span v-if="scope.row.mode===3">卡+人脸</span>
              </template>
            </el-table-column>
            <el-table-column label="比对分数" prop="score" width="120"></el-table-column>
          </el-table>
          <el-pagination
            :page-size="page.size"
            :page-sizes="this.$pageSizes"
            :current-page="page.current"
            :total="page.total"
            layout="total,sizes,prev,pager,next,jumper"
            @size-change="handleSizeChangeRecord"
            @current-change="handleCurrentChangeRecord"
          ></el-pagination>
        </span>
      </el-tab-pane>
      <el-tab-pane label="陌生人记录" name="second">
        <span>
          <el-form :inline="true" :model="pageQuerySnap" ref="pageQuerySnap" class="demo-form-inline">
            <el-form-item>
              <span class="demonstration2">请选择时间段</span>
              <el-date-picker
                  style="margin-right: 30px;"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  label="时间段"
                  v-model="pageQuerySnap.datetime"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button v-loading.body="recordListLoading" type="primary" @click="queryClickSnap($event)">查询</el-button>
            </el-form-item>
            <!-- <el-form-item>
              <el-button v-loading.body="listLoading" type="primary" @click="excleExport($event,0)">
                <span v-show="export_percentage===-1">导出Excel</span>
                <span v-show="export_percentage!==-1">导出中:{{ export_percentage }}%</span>
              </el-button>
            </el-form-item>-->
          </el-form>
        </span>
        <span>
          <el-table height="400" :data="pageSnap.records" border :row-style="{height:'44px'}" :cell-style="{padding:'4px'}">
            <el-table-column type="index" :index="indexMethod" label="序号" width="100" fixed="left"></el-table-column>
            <el-table-column label="时间" prop="create_time" width="200" fixed="left"></el-table-column>
            <el-table-column label="头像" width="400">
               <template slot-scope="scope">
                <img :src="picInitUrl+scope.row.pic_url1" width="60" height="60"/>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="200">
              已处理
            </el-table-column>
             <el-table-column label="备注" width="211">
               抓拍
            </el-table-column>
          </el-table>
          <el-pagination
              :page-size="pageSnap.size"
              :page-sizes="this.$pageSizes"
              :current-page="pageSnap.current"
              :total="pageSnap.total"
              layout="total,sizes,prev,pager,next,jumper"
              @size-change="handleSizeChangeSnap"
              @current-change="handleCurrentChangeSnap"
          ></el-pagination>
        </span>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button @click="myVisible=false" size="small">关闭</el-button>
      </span>
  </el-dialog>
</template>

<script>
import * as XLSX from 'xlsx/xlsx.mjs'
import FileSaver from "file-saver";

export default {
  name: "manFaceRecord",
  props:{
    dialogVisible:{
      type:Boolean,
      default:false
    },
    deviceSn:String,
    deviceTitle:String
  },
  data(){
    return{
      export_percentage: -1,
      page:{
        records:[],
        total:0,
        size:0,
        current:0,
        hitCount:false,
        searchCount:true,
        pages:0
      },
      pageQuery:{
        datetime: [],
        size:this.$pageSize,
        current: 1
      },
      pageSnap:{
        records:[],
        total:0,
        size:0,
        current:0,
        hitCount:false,
        searchCount:true,
        pages:0
      },
      pageQuerySnap:{
        datetime: [],
        size:this.$pageSize,
        current: 1
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近15分钟',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 0.25);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近30分钟',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 0.5);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近1小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近3小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 3);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近6小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 6);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近12小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 12);
              picker.$emit('pick', [start, end]);
            }
          },{
          text: '最近1天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近1周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近1个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近3个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },//日期控件
      recordListLoading: false,
      picInitUrl:'http://'+this.COMMON.initUrl+':9098/face/',
      activeName: 'first',
      totalCount: 0,
      listLoading:false,
      cols: ["姓名","卡号","身份证号","认证模式","比对分数"],
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.dialogVisible
      },
      set:function (val){
        this.$emit("update:dialogVisible",val);
      }
    }
  },
  methods:{
    loadDataRecord(){
      this.pageQuery.status = 1
      this.$axios.post(`/dev/record/list/${this.deviceSn}`,this.pageQuery).then((response)=>{
        this.page=response.data;
        this.totalCount = this.page.total;
      }).catch(err=>{
        console.log(err)
      })
    },
    loadDataSnap(){
      this.pageQuerySnap.status = 0
      this.$axios.post(`/dev/record/list/${this.deviceSn}`,this.pageQuerySnap).then((response)=>{
        this.pageSnap=response.data
      }).catch(err=>{
        console.log(err)
      })
    },
    handleSizeChangeRecord(value){
      this.pageQuery.size=value;
      this.loadDataRecord()
    },
    handleCurrentChangeRecord(value){
      this.pageQuery.current=value
      this.loadDataRecord()
    },
    handleSizeChangeSnap(value){
      this.pageQuerySnap.size=value;
      this.loadDataSnap()
    },
    handleCurrentChangeSnap(value){
      this.pageQuerySnap.current=value
      this.loadDataSnap()
    },
    queryClickRecord(){
      this.loadDataRecord();
    },
    queryClickSnap(){
      this.loadDataSnap();
    },
    indexMethod(index) {
      index = (index + 1) + (this.page.current - 1) * this.page.size
      return index
    },
    indexMethodSnap(index) {
      index = (index + 1) + (this.pageSnap.current - 1) * this.pageSnap.size
      return index
    },
    handleTabClick(){
      if(this.activeName==="second") {
        this.loadDataSnap();
      }
    },
    excleExport(evt,stat) {
      let target = evt.target
      target.blur()
      this.$refs['pageQuery'].validate((valid) => {
        if (valid) {
          let _this = this;
          const row = 1000
          let request_times = Math.ceil(this.totalCount / row); //计算分几次请求，1001总数的话会请求11次
          if (request_times === 0) {
            this.$message.error("没有数据，导出取消！");
            return
          }
          if(this.totalCount>100000){
            this.$message.error("请导出小于10万的数据！");
            return
          }
          let funcs = []; //Promise.all要用到的参数, 存放每次请求的Promise对象
          let complete_count = 0; //成功请求次数
          this.export_percentage = 0; //设置当前进度百分比为0
          for (let i = 1; i <= request_times; i++) { // 循环请求次数，构造请求的Promise对象并插入funcs数组
            let func = new Promise(function (resolve, reject) { //定义Promise并处理请求逻辑
              let data = [];
              let param = {
                datetime: _this.pageQuery.datetime,
                size: row,
                current: i,
                status: stat
              };
              if (_this.deviceSn === undefined) {
                reject();
              }
              _this.$axios.post(`/dev/record/list/${_this.deviceSn}`,param).then(function (response) {
                for (let i = 0; i < response.data.records.length; i++) { //将后台返回中需要的字段取出
                  let item = [];
                  let src = response.data.records[i]
                  item.push(src.create_time);
                  item.push(src.driver_name);
                  item.push(src.card);
                  item.push(src.user_no);
                  if(src.mode===1){
                    item.push("人脸识别");
                  } else if(src.mode===2){
                    item.push("卡或人脸");
                  } else if(src.mode===3){
                    item.push("卡+人脸");
                  } else {
                    item.push("");
                  }
                  item.push(src.score);
                  data.push(item);
                }
                complete_count++; //成功请求次数+1
                _this.export_percentage = Math.floor((row * complete_count) / (request_times * 10)); //设置当前进度百分比

                resolve(data);

              }).catch(function (error) {
                console.log(error)
                reject();
              });
            });
            funcs.push(func);
          }

          Promise.all(funcs).then(function (values) { //使用Promise.all调用funcs里面的函数，并合并数据，最后给js-xlsx生成Excel
            let header = [['时间']]
            for (const cos in _this.cols) {
              header[0].push(_this.cols[cos])
            }
            let aoa = [];
            //将数据合并
            for (let i = 0; i < values.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                aoa.push(values[i][j]);
              }
            }
            const c = 1000000
            let sheet = Math.ceil(aoa.length / c)
            let wb = XLSX.utils.book_new();
            for (let i = 1; i <= sheet; i++) {
              wb.SheetNames.push('sheet' + i);
              wb.Sheets['sheet' + i] = XLSX.utils.aoa_to_sheet(header.concat(aoa.slice(0, c)));
              aoa.splice(0, c)
            }
            let wopts = {bookType: 'xlsx', bookSST: false, type: 'array'};
            let wbout = XLSX.write(wb, wopts);
            FileSaver.saveAs(new Blob([wbout], {type: "application/octet-stream"}), "data.xlsx");
            _this.export_percentage = -1;
          });
        } else {
          return false
        }
      })
    },
  }

}
</script>

<style>
.status_change2 .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.status_change2 .el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
.status_change2 .el-dialog__body {
  padding: 5px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

.demonstration2 {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
