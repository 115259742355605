<template>
  <div>
  <el-table :data="page.records" >
    <el-table-column type="index" label="序号" width="80px"></el-table-column>
    <el-table-column prop="name" label="区域名称"></el-table-column>
    <el-table-column label="操作" width="150px">
      <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="edit(scope.row)" v-if="hasPerm('area:update')">修改</el-button>
        <el-button type="danger" size="mini" @click="del(scope.row.id)" v-if="hasPerm('area:delete')">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination style="text-align: left;margin-top: 10px"
      :current-page="page.current"
      :page-size="page.size"
      :page-sizes="this.$pageSizes"
      layout="total,sizes,prev,pager,next,jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
  </el-pagination>
  </div>
</template>

<script>
import {store} from "@/views/consoleArea/store";
import {EventBus} from '@/views/consoleArea/event-bus'
export default {
  name: "myTable",
  data(){
    return{
      store,
      page:{
      records:[],
      size: 0,
      current: 0,
      hitCount:false,
      searchCount:true,
      total:0
    },
    pageQuery:{
      size:this.$pageSize,
      current:1,
      projectId:0
    }
    }
  },
  created() {
    EventBus.$on("nodeClick",()=>{
      this.pageQuery.projectId=store.state.selectedIds[1]
      this.loadTableData();
    })
    EventBus.$on('refreshTable',()=>{
      this.loadTableData();
    })
    this.loadTableData();
  },
  methods:{
    handleSizeChange(size){
      this.pageQuery.size=size
      this.loadTableData()
    },
    handleCurrentChange(current){
      this.pageQuery.current=current;
      this.loadTableData()
    },
    edit(data){
      EventBus.$emit("editData",data)
    },
    del(id){
        this.$confirm('确定删除改区域么','提示',{
          confirmButtonText:'确定',
          cancelButtonText:'取消'
        }).then(()=>{
          this.$axios.post(`/sys/area/del/${id}`).then((response=>{
            if (response.data.result==='success'){
              this.loadTableData();
              this.$message({
                message:'删除成功',
                type:'success'
              })
            }else{
              this.$message({
                message:'删除失败',
                type:'error'
              })
            }

          }))
        }).catch(()=>{
          this.$message({
            message:'取消删除',
            type:'info'
          })
        })
    },
    loadTableData(){
      //console.log('触发load')
      this.$axios.post(`/sys/area/list/byProject`,this.pageQuery).then(response=>{
        this.page=response.data.list
      })
    }
  }
}
</script>

<style scoped>

</style>
