<template>
  <el-dialog
      title="修改密码"
      width="500px" :show-close="false" class="status_change"
      :visible.sync="myVisible"
      left>
      <!-- <span>
        {{this.$store.state.user.nickname}}
      </span>-->
    <el-form ref="formData" :model="formData" label-width="80px" label-suffix=":" :rules="formRule">
      <el-form-item label="登录名" prop="username">
        <el-input v-model="formData.username" disabled></el-input>
      </el-form-item>
      <el-form-item label="旧密码" prop="password">
        <el-input type="password" v-model="formData.password" placeholder="不填则表示不修改"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="rePassword">
        <el-input type="password" v-model="formData.rePassword" placeholder="不填则表示不修改"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="formData.nickname"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="myVisible=false" size="small">关闭</el-button>
      <el-button type="primary" @click="save()" size="small">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {mustFill} from "@/validation/valid";

export default {
  name: "userInfo",
  props:{
    visible:Boolean
  },
  data(){
    return{
      formData:{
        username:this.$store.state.user.username,
        nickname:this.$store.state.user.nickname,
        password:'',
        rePassword:''
      },
      formRule:{
        password:[mustFill],
        rePassword:[mustFill],
        nickname:[mustFill],
      }
    }
  },
  computed:{
    myVisible:{
      get:function (){
        return this.visible
      },
      set:function (value){
        this.$emit("update:visible",value)
      }
    }
  },
  methods:{
    save(){
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          this.formData.id=this.$store.state.user.id;
          this.$axios.post('/sys/user/updataPassword', this.formData).then((response) => {
            if (response.data.result === 'success') {
              this.$message({message: '密码修改成功', type: 'success'})
              this.$emit('saveSuccess')
              this.myVisible = false;
            } else {
              this.$message({message: response.data.msg, type: 'error'})
            }
          });
        }else {
          this.$message({
            message: "请补全用户信息",
            type: "warning",
          });
        }
      });
    }
  }
}
</script>

<style scoped>
.status_change .el-dialog__header{
  border-bottom: 1px solid #ebebeb;
}
.el-dialog__footer {
  border-top: 1px solid #e8eaec;
}
</style>
